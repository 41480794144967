import { createTheme } from '@mui/material';

export const TableDashboardTheme = createTheme({
  components: {
    MuiTypography: {
      variants: [
        {
          props: { variant: 'h2' },
          style: {
            fontFamily: 'Inter',
            color: 'var(--primary-text-color)',
            fontSize: '24px',
            fontWeight: 500
          }
        },
        {
          props: { variant: 'h6' },
          style: {
            fontFamily: 'var(--font-family-regular)',
            color: 'var(--sub-header-text-color)',
            fontSize: '15px',
            fontWeight: 300,
            letterSpacing: '0.2px',
            paddingTop: '4px',
            lineHeight: '1'
          }
        },
        {
          props: { variant: 'h5' },
          style: {
            fontFamily: 'var(--font-family)',
            color: 'var(--mobile-view-header-text-color)',
            fontSize: '15px',
            fontWeight: 600,
            letterSpacing: '0.2px',
            lineHeight: '14px'
          }
        },
        {
          props: { variant: 'h4' },
          style: {
            fontFamily: 'var(--font-family)',
            color: 'var(--mobile-view-header-text-color)',
            fontSize: '14px',
            fontWeight: 400,
            letterSpacing: '0.2px',
            lineHeight: '14px',
            marginRight: '5px'
          }
        }
      ]
    },
    MuiButton: {
      styleOverrides: {
        root: {
          border: '0px',
          color: 'var(--sub-header-text-color)',
          fontFamily: 'var(--font-family-regular)',
          borderRadius: '0px',
          textTransform: 'inherit',
          fontSize: '15px',
          fontWeight: 300,
          letterSpacing: '0.2px',
          padding: '5px 10px',
          '&:hover': {
            border: '0px',
            backgroundColor: '#F0F0F0'
          }
        }
      }
    },
    MuiIconButton: {
      styleOverrides: {
        root: {
          padding: '0px',
          borderRadius: '0px'
        }
      }
    }
  }
});

export const checkBoxTheme = createTheme({
  components: {
    MuiCheckbox: {
      styleOverrides: {
        root: {
          padding: '0px'
        }
      }
    }
  }
})

export const TableTheme = createTheme({
  components: {
    MuiDataGrid: {
      styleOverrides: {
        root: {
          fontFamily: 'var(--font-family)',
          border: '0px',
          '& .MuiDataGrid-overlayWrapper': {
            height: 'auto !important',
            minHeight: '40px !important'

          },
          '& .MuiDataGrid-row--lastVisible .MuiDataGrid-cell': {
            borderBottomColor: 'var(--grey-color) !important'
          },

          '& .MuiDataGrid-overlayWrapperInner': {
            height: '100% !important'
          },

          '& .MuiDataGrid-row.Mui-selected': {
            backgroundColor: 'rgba(0, 0, 0, 0.02)'
          },
          '& .MuiDataGrid-row.Mui-selected .MuiDataGrid-cell:first-of-type': {
            position: 'relative'
          },
          '& .MuiDataGrid-row.Mui-selected .MuiDataGrid-cell:first-of-type:before':
          {
            position: 'absolute',
            content: '""',
            height: 'calc(100% - 0px)',
            width: '4px',
            textAlign: 'center',
            left: 0,
            top: '0px',
            bottom: '1px',
            backgroundColor: 'var(--secondary-blue-color)'
          },
          '& .MuiDataGrid-row.Mui-selected:hover': {
            backgroundColor: 'rgba(0, 0, 0, 0.02)'
          },
          '& .MuiDataGrid-row.Mui-hovered:hover': {
            backgroundColor: 'rgba(0, 0, 0, 0.02)'
          },
          '& .MuiDataGrid-cell:focus': {
            outline: 'none'
          },
          '& .MuiDataGrid-columnHeader:focus': {
            outline: 'none'
          },

          '& .MuiDataGrid-columnHeader:focus-within': {
            outline: 'none'
          },
          '& .MuiDataGrid-cell:focus-within': {
            outline: 'none'
          },

          '& .MuiDataGrid-columnSeparator': {
            visibility: 'hidden !important'
          },
          '& .MuiTablePagination-selectLabel': {
            // display: 'none !important'
            color: 'var(--primary-text-color)',
            fontSize: '14px',
            fontFamily: 'var(--font-family-regular)',
            letterSpacing: '0.2px',
            fontWeight: 400
          },
          '& .MuiTablePagination-input': {
            // display: 'none !important'
          },
          '& .MuiTablePagination-select.MuiInputBase-input  ': {
            color: 'var(--primary-text-color)',
            fontSize: '14px',
            fontFamily: 'var(--font-family-regular)',
            letterSpacing: '0.2px',
            fontWeight: 400
          },
          '& .MuiTablePagination-displayedRows  ': {
            color: 'var(--primary-text-color)',
            fontSize: '14px',
            fontFamily: 'var(--font-family-regular)',
            letterSpacing: '0.2px',
            fontWeight: 400
          },
          '& .MuiDataGrid-virtualScroller': {
            marginBottom: '0px',
            overflow: 'scroll'
          },
          '& .MuiDataGrid-virtualScroller::-webkit-scrollbar': {
            background: '#fff',
            borderRadius: '6px',
            width: '5px',
            height: '0px'
          },
          '& .MuiDataGrid-virtualScroller::-webkit-scrollbar-thumb': {
            background: '#aaaaaa',
            borderRadius: '6px',
          },

          '& .MuiDataGrid-virtualScroller::-webkit-scrollbar-thumb:hover ': {
            background: '#aaaaaa',


          },
          '& .MuiDataGrid-virtualScroller::-webkit-scrollbar-track ': {
            background: '#fff',
            borderRadius: '6px'
          },

          '& .MuiDataGrid-autoHeight  .MuiDataGrid-cell:last-child': {
            borderBottomColor: 'var(--grey-color)'
          }
        },
        virtualScroller: {
          display: 'flex'
        },
        virtualScrollerContent: {
          display: 'flex'
        },
        virtualScrollerRenderZone: {
          display: 'flex',
          width: '100%'
        },
        cell: {
          color: '#000000',//'var(--primary-text-color)',
          fontSize: '12px',
          fontFamily: 'var(--font-family-regular)',
          letterSpacing: '-0.01px',
          fontWeight: 400,
        },
        columnHeaders: {
          minHeight: '45px !important',
          maxHeight: '45px !important',
          lineHeight: '45px !important',
          height: '45px !important'
        },
        columnHeader: {
          minHeight: '45px',
          maxHeight: '45px',
          lineHeight: '45px',
          height: '45px !important'
        },
        columnHeaderTitle: {
          color: 'var(--table-header-color)',
          fontSize: '12px',
          // fontFamily: 'var(--font-family-bold)',
          fontWeight: 400,
          letterSpacing: '-0.01px',
          // whiteSpace: 'pre',
          wordWrap: 'break-word',
          whiteSpace: 'normal',
          lineHeight: 'normal'
        },
        withBorderColor: {
          borderColor: 'var(--grey-color)'
        },
        selectedRowCount: {
          display: 'none'
        },
      }
    },
    MuiTypography: {
      variants: [
        {
          props: { variant: 'h2' },
          style: {
            fontFamily: 'Inter',
            color: 'var(--primary-text-color)',
            fontSize: '24px',
            fontWeight: 500
          }
        },
        {
          props: { variant: 'h6' },
          style: {
            fontFamily: 'var(--font-family-regular)',
            color: 'var(--sub-header-text-color)',
            fontSize: '15px',
            fontWeight: 300,
            letterSpacing: '0.2px',
            paddingTop: '4px',
            lineHeight: '1'
          }
        },
        {
          props: { variant: 'h5' },
          style: {
            fontFamily: 'var(--font-family)',
            color: 'var(--mobile-view-header-text-color)',
            fontSize: '15px',
            fontWeight: 600,
            letterSpacing: '0.2px',
            lineHeight: '14px'
          }
        },
        {
          props: { variant: 'h4' },
          style: {
            fontFamily: 'var(--font-family)',
            color: 'var(--mobile-view-header-text-color)',
            fontSize: '14px',
            fontWeight: 400,
            letterSpacing: '0.2px',
            lineHeight: '14px',
            marginRight: '5px'
          }
        }
      ]
    },
    MuiButton: {
      styleOverrides: {
        root: {
          border: '0px',
          color: 'var(--sub-header-text-color)',
          fontFamily: 'var(--font-family-regular)',
          borderRadius: '0px',
          textTransform: 'inherit',
          fontSize: '15px',
          fontWeight: 300,
          letterSpacing: '0.2px',
          padding: '5px 10px',
          '&:hover': {
            border: '0px',
            backgroundColor: '#F0F0F0'
          }
        }
      }
    },
    MuiIconButton: {
      styleOverrides: {
        root: {
          padding: '0px',
          borderRadius: '0px'
        }
      }
    }
  }
});

export const TooltipTheme = createTheme({
  components: {
    MuiIconButton: {
      styleOverrides: {
        root: {
          padding: '0px'
        }
      }
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          '& .MuiTooltip-arrow::before': {
            backgroundColor: 'var(--primary-white-color) !important',
            border: '1px solid var(--grid-layout-border)',
          },
          backgroundColor: 'var(--primary-white-color)',
          color: 'var(--new-tooltip-text-color)',
          border: '1px solid var(--grid-layout-border)',
          fontSize: '12px',
          borderRadius: '4px',
          padding: '8px',
          fontFamily: 'var(--font-family-regular)',
          letterSpacing: '0.2px',
          boxShadow: '0px 3px 6px #00000029',
          marginTop: '4px !important',
          width: 'auto !important',
          lineHeight: 1.6
        },
        arrow: {
          backgroundColor: 'transparent !important',
        }
      }
    }
  }
});



export const DashboardTheme = createTheme({
  components: {
    MuiTypography: {
      variants: [
        {
          props: { variant: 'h6' },
          style: {
            fontFamily: 'Inter',
            color: 'var(--primary-text-color)',
            fontSize: '14px',
            fontWeight: 400
          }
        },
        {
          props: { variant: 'h5' },
          style: {
            fontFamily: 'Inter',
            color: '#8C8C8C',
            fontSize: '14px',
            fontWeight: 500,
            marginLeft: '8px',
            paddingTop: '2px'
          }
        },
        {
          props: { variant: 'h1' },
          style: {
            fontFamily: 'Inter',
            color: 'var(--primary-text-color)',
            fontSize: '40px',
            fontWeight: 500,
            marginTop: 16
          }
        }
      ]
    },
    MuiDataGrid: {
      styleOverrides: {
        root: {
          fontFamily: 'var(--font-family)',
          border: '0px',
          '& .MuiDataGrid-row.Mui-selected': {
            backgroundColor: 'rgba(0, 0, 0, 0.02)'
          },
          '& .MuiDataGrid-cell:first-of-type': {
            paddingLeft: '0px'
          },

          '& .MuiDataGrid-row.Mui-selected:hover': {
            backgroundColor: 'rgba(0, 0, 0, 0.02)'
          },
          '& .MuiDataGrid-row.Mui-hovered:hover': {
            backgroundColor: 'rgba(0, 0, 0, 0.02)'
          },
          '& .MuiDataGrid-cell:focus': {
            outline: 'none'
          },
          '.MuiDataGrid-columnHeader:focus': {
            outline: 'none'
          },

          '.MuiDataGrid-columnHeader:focus-within': {
            outline: 'none'
          },

          '& .MuiDataGrid-columnSeparator': {
            visibility: 'hidden !important'
          },

          '& .MuiDataGrid-virtualScroller  ': {
            marginBottom: '-15px'
          }
        },
        virtualScroller: {
          display: 'flex'
        },
        virtualScrollerContent: {
          display: 'flex'
        },
        virtualScrollerRenderZone: {
          display: 'flex',
          width: '100%'
        },
        cell: {

          color: 'var(--alarm-summary-header-color)',
          fontSize: '12px',
          fontFamily: 'var(--font-family-semi-medium)',
          letterSpacing: '0.2px'

        },
        columnHeaders: {
          minHeight: '45px !important',
          maxHeight: '45px !important',
          lineHeight: '45px !important',
          height: '45px !important'
        },
        columnHeader: {
          minHeight: '45px',
          maxHeight: '45px',
          lineHeight: '45px',
          height: '45px !important'
        },
        columnHeaderTitle: {
          color: 'var(--alarm-summary-header-color)',
          fontSize: '14px',
          fontFamily: 'var(--font-family-semi-bold)',
          letterSpacing: '0.2px'
          // fontWeight: 700,

        },
        withBorderColor: {
          borderColor: 'var(--grey-color)'
        }
      }
    }
  }
});

export const AddFilterBtnTheme = createTheme({
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          border: '0px',
          color: 'var(--sub-header-text-color)',
          fontFamily: 'var(--font-family-regular)',
          borderRadius: '0px',
          textTransform: 'inherit',
          fontSize: '15px',
          fontWeight: 300,
          letterSpacing: '0.2px',
          padding: '5px 16px',
          whiteSpace: 'pre',
          '@media (max-width: 600px)': {
            backgroundColor: 'transparent  !important',
            border: '1px solid var(--mobile-view-border-color)',
            padding: '4px 12px',
            fontSize: '12px !important',
            borderRadius: '4px',
            '&:hover': {
              borderRadius: '4px',
              padding: '4px 12px',
              fontSize: '12px !important',
              border: '1px solid var(--mobile-view-border-color) !important',
              backgroundColor: 'transparent !important'
            }
          },
          '&:hover': {
            border: '0px',
            backgroundColor: '#F0F0F0'
          }
        },
        startIcon: {
          marginLeft: '0px',
          display: 'flex'
        }
      }
    }
  }
});

export const OutlinedBtnTheme = createTheme({
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          border: '0px',
          color: 'var(--sub-header-text-color)',
          fontFamily: 'var(--font-family-regular)',
          // borderRadius: '4px',
          textTransform: 'inherit',
          fontSize: '15px',
          fontWeight: 300,
          letterSpacing: '0.2px',
          padding: '5px 16px',
          whiteSpace: 'pre',
          '@media (max-width: 600px)': {
            backgroundColor: 'transparent  !important',
            border: '1px solid var(--mobile-view-border-color)',
            padding: '4px 12px',
            fontSize: '12px !important',
            borderRadius: '4px',
            '&:hover': {
              borderRadius: '4px',
              padding: '4px 12px',
              fontSize: '12px !important',
              border: '1px solid var(--mobile-view-border-color) !important',
              backgroundColor: 'transparent !important'
            }
          },
          '&:hover': {
            border: '0px',
            backgroundColor: '#F0F0F0'
          }
        },
        startIcon: {
          marginLeft: '0px',
          display: 'flex'
        }
      }
    }
  }
});

export const FilledBtnTheme = createTheme({
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          border: '0px',
          color: 'var(--white-color)',
          fontFamily: 'var(--font-family-regular)',
          borderRadius: '4px',
          textTransform: 'inherit',
          fontSize: '15px',
          fontWeight: 300,
          letterSpacing: '0.2px',
          padding: '5px 16px',
          whiteSpace: 'pre',
          backgroundColor: 'var(--secondary-blue-color)',
          '@media (max-width: 600px)': {
            borderRadius: '4px',
            backgroundColor: 'var(--secondary-blue-color)  !important',
            border: '0px solid var(--mobile-view-border-color)',
            padding: '4px 12px',
            fontSize: '12px !important',
            '&:hover': {
              borderRadius: '4px',
              backgroundColor: 'var(--secondary-blue-color)',
              padding: '4px 12px',
              fontSize: '12px'
            }
          },
          '&:hover': {
            backgroundColor: 'var(--secondary-blue-color)',
            border: '0px solid var(--mobile-view-border-color)'
          }
        },
        startIcon: {
          marginLeft: '0px',
          display: 'flex'
        }
      }
    }
  }
});

export const AddNewBtnTheme = createTheme({
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          border: '0px',
          color: 'var(--sub-header-text-color)',
          fontFamily: 'var(--font-family-regular)',
          borderRadius: '0px',
          textTransform: 'inherit',
          fontSize: '15px',
          fontWeight: 300,
          letterSpacing: '0.2px',
          padding: '5px 16px',
          whiteSpace: 'pre',
          backgroundColor: 'var(--grey-color)',
          '@media (max-width: 600px)': {
            borderRadius: '200px',
            backgroundColor: 'transparent',
            border: '0px solid var(--mobile-view-border-color)',
            '&:hover': {
              border: '0px solid var(--mobile-view-border-color) !important',
              backgroundColor: 'transparent !important'
            }
          },
          '&:hover': {
            border: '0px',
            backgroundColor: '#F0F0F0'
          }
        },
        startIcon: {
          marginLeft: '0px',
          display: 'flex'
        }
      }
    }
  }
});

export const PaginationTheme = createTheme({
  components: {
    MuiTablePagination: {
      styleOverrides: {
        root: {
          fontFamily: 'var(--font-family)',
          color: 'var(--navbar-color)',
          '& .MuiButtonBase-root.MuiPaginationItem-root.Mui-selected': {
            backgroundColor: 'var(--secondary-blue-color)'
          },
          '& .MuiTablePagination-displayedRows ': {
            // display: 'none'
          },
          '& .MuiTablePagination-Toolbar': {
            paddingLeft: 0
          }
        },
        selectLabel: {
          display: 'none'
        },
        menuItem: {
          fontFamily: 'var(--font-family)',
          color: 'var(--navbar-color)'
        },
        displayedRows: {
          // display: 'none !important'
        }
      }
    },
    MuiPagination: {
      styleOverrides: {
        root: {
          '& .MuiButtonBase-root.MuiPaginationItem-root.Mui-selected': {
            backgroundColor: 'var(--secondary-blue-color)',
            border: '0px',
            color: 'var(--primary-white-color)'
          }
        }
      }
    },
    MuiPaginationItem: {
      styleOverrides: {
        root: {
          fontFamily: 'var(--font-family)',
          color: 'var(--navbar-color)',
          fontSize: '14px',
          borderRadius: '2px',
          border: '1px solid var(--grey-color)',
          '&.Mui-selected': {
            'border': '0px',
            'backgroundColor': 'var(--secondary-blue-color)'
          }

        },

        ellipsis: {
          border: '0px',
        }
      }
    },
    MuiButtonBase: {
      styleOverrides: {
        root: {
          fontFamily: 'var(--font-family)',
          color: 'var(--navbar-color)'
        }
      }
    }
  }
});

export const GreyBtnWithIconTheme = createTheme({
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          border: '0px',
          color: 'var(--sub-header-text-color)',
          fontFamily: 'var(--font-family-regular)',
          borderRadius: '0px',
          textTransform: 'inherit',
          fontSize: '14px',
          fontWeight: 400,
          letterSpacing: '0.2px',
          padding: '8px 16px',
          whiteSpace: 'pre',

          '@media (max-width: 600px)': {
            borderRadius: '200px',
            backgroundColor: 'transparent',
            border: '0px solid var(--mobile-view-border-color)',
            '&:hover': {
              border: '0px solid var(--mobile-view-border-color) !important',
              backgroundColor: 'var(--grey-color)',
              fontWeight: 600
            }
          },
          '&:hover': {
            border: '0px',
            backgroundColor: 'var(--grey-color)',
            fontWeight: 600
          }
        },
        startIcon: {
          marginLeft: '0px',
          display: 'flex'
        }
      }
    }
  }
});

export const ButtonTheme = createTheme({
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          color: 'var(--primary-white-color)',
          backgroundColor: 'var(--secondary-blue-color)',
          padding: '',
          borderRadius: 4,
          fontSize: '16px',
          fontFamily: 'var(--font-family), Regular',
          fontWeight: 300,
          width: '100%',
          boxShadow: 'none',
          textTransform: 'none',
          '&:hover': {
            backgroundColor: 'var(--secondary-blue-color)',
            color: 'var(--primary-white-color)',
            boxShadow: 'none'
          }
        }
      }
    }
  }
});

export const SearchBoxTheme = createTheme({
  components: {
    MuiFormControl: {
      styleOverrides: {
        root: {
          '@media (max-width: 600px)': {
            width: '100%',
            minWidth: '100%'
          }
        }
      }
    },
    MuiInputAdornment: {
      styleOverrides: {
        root: {
          marginRight: '0px',
          '&.custom__search__box__cross__icon': {
            paddingRight: '5px',
            cursor: 'pointer'
          }
        }
      }
    },

    MuiTextField: {
      styleOverrides: {
        root: {
          '@media (max-width: 600px)': {
            width: '100%',
            minWidth: '100%'
          }
        }
      }
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          paddingLeft: '7px !important',
          fontFamily: 'Inter',
          color: 'var(--primary-text-color)',
          fontWeight: '300',
          fontSize: '14px',
          width: '200px',
          border: '1px solid var(--grey-color)',
          borderRadius: '4px',

          '&:focus': {
            outline: 'none !important'
          },
          '&:hover': {
            outline: 'none'
          },
          '@media (max-width: 600px)': {
            width: '100%',
            minWidth: '100%',
            borderRadius: '8px'
          }
        },
        input: {
          padding: '7px !important'
        }
      }
    }
  }
});

export const NavbarTheme = createTheme({
  components: {
    MuiToolbar: {
      styleOverrides: {
        dense: {
          height: 48,
          minHeight: 48,
          justifyContent: 'space-between'
        }
      }
    },
    MuiAppBar: {
      styleOverrides: {
        root: {
          background: 'var(--navbar-color)',
          height: 48
        }
      }
    },
    MuiIconButton: {
      styleOverrides: {
        root: {
          padding: 0
        }
      }
    },
    MuiTypography: {
      styleOverrides: {
        root: {
          fontSize: [13, '!important']
        }
      }
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          minHeight: '35px',
          // Due to a bug in mui we need to explicitly override media with the default of 48px
          '@media (min-width: 600px)': {
            minHeight: '35px'
          }
        }
      }
    },
    MuiMenuList: {
      styleOverrides: {
        padding: 0
      }
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          width: '190px'
        }
      }
    }
  }
});

export const SidebarTheme = createTheme({
  components: {
    MuiDrawer: {
      styleOverrides: {
        paperAnchorLeft: {
          background: 'var(--sidebar-bg-color)',
          top: '48px',
          height: 'calc(100% - 48px)'
        }
      }
    },

    MuiListItemText: {
      styleOverrides: {
        root: {
          color: 'var(--primary-text-color)',
          fontSize: '14px',
          MuiTypography: {
            color: 'var(--primary-text-color)',
            fontSize: '14px'
          }
        }
      }
    },
    MuiListItemIcon: {
      styleOverrides: {
        root: {
          minWidth: '40px'
        }
      }
    }
  }
});

export const StepperTheme = createTheme({
  components: {
    MuiStepper: {
      styleOverrides: {
        root: {
          justifyContent: 'center'
        }
      }
    },
    MuiStep: {
      styleOverrides: {
        root: {
        }
      }
    },
    MuiStepLabel: {
      styleOverrides: {
        root: {
        },
        label: {
          fontFamily: 'var(--font-family-regular)',
          fontSize: '1rem',
          '&.Mui-active': {
            fontWeight: 600
          }
        }
      }
    },
    MuiStepIcon: {
      styleOverrides: {
        root: {
          '&.Mui-completed': {
            color: 'var(--secondary-blue-color)'
          },
          '&.Mui-active': {
            color: 'var(--secondary-blue-color)'
          },
        }
      }
    },
    MuiStepConnector: {
      styleOverrides: {
        root: {
          flex: '0.2 1 auto'
        }
      }
    }
  }
})
