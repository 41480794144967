import {
  AxisLabelsFormatterContextObject,
  Options, OptionsChartZoomingTypeValue
} from 'highcharts';
import { cloneDeep } from 'lodash';
import moment from 'moment';
import React,{ FC, useEffect, useMemo, useState } from 'react';
import { CustomHighCharts, useAppSelector } from '../../../../globalUtils/globalExports';
import { getGlobalReducer } from '../../../../rmsReduxStore/reduxExports';
import { generateDataPoints } from '../../../../globalUtils/globalHooks';

interface IChargerStatusGraph {
  timeSeriesConnectorData: [number, number][][];
  historyGraphLegends: string[] | null
  graphColorsList:string[]
  unit: string,
  startDateTime: string,
  endDateTime: string
}

const ChargerStatusGraph: FC<IChargerStatusGraph> = ({ timeSeriesConnectorData,historyGraphLegends,graphColorsList, unit, startDateTime, endDateTime }) => {
  const [graphTickPositions, setGraphTickPositions] = useState({ min: 0, mid: 0, max: 0 });
  const [categories, setCategories] = useState<string[]>([]);
  const { globalFiltersWithOnlyIds } = useAppSelector(getGlobalReducer);
  // const connectorColorsList = ['Idle','bluecolor', 'Charging','fourth']
  // const graphColorsList =['var(--charger-status-idle-dark-color)','var(--charger-status-blue-dark-color)', 'var(--charger-status-charging-dark-color)','var(--charger-status-fourth-dark-color)']

  // TODO: Calculate tick positions for graph
  useEffect(() => {
    let maxValue = -Infinity;
    let minValue = Infinity;
    // const categories: string[] = []
    if (timeSeriesConnectorData && timeSeriesConnectorData?.length > 0) {
      timeSeriesConnectorData?.forEach(arrayData => {
        if (arrayData && arrayData?.length > 0) {
          arrayData?.forEach(dataPoint => {
            const value = dataPoint[1];
            // const category = dataPoint[0]
            if (value !== null) {
              if (value > maxValue) {
                maxValue = value;
              }
              if (value < minValue) {
                minValue = value;
              }
            }
            // categories?.push(category)
          })
        }
      })
    }
    setGraphTickPositions({ min: minValue === 0 ? Math.round(minValue) : Math.round(minValue) - 1, mid: isNaN(minValue) && isNaN(maxValue) ? 0 : Math.round((minValue + maxValue) / 2), max: Math.round(maxValue) + 1 })

  }, [timeSeriesConnectorData,])

  useEffect(() => {
    const filterData: IScreenFilter[] = cloneDeep(globalFiltersWithOnlyIds);
    const selectedScreenFilter = filterData?.find(
      (filter) => filter?.screen === 'chargerPanel'
    );

    const historyIntervalFilter = selectedScreenFilter?.filterPreference?.find(
      (filter) => filter?.key === 'historyInterval'
    );
    if (historyIntervalFilter && historyIntervalFilter?.values?.[0]?.length > 0) {
      const datapoints = generateDataPoints(startDateTime, endDateTime, historyIntervalFilter?.values?.[0]);
      if (datapoints && datapoints?.length > 0) {
        setCategories(datapoints)
      }
    }

  }, [globalFiltersWithOnlyIds, startDateTime, endDateTime])
  
  const chartOptions: Options = useMemo(() => {
    return {
      time: {
        useUTC: false,
        timezone: 'Asia/Calcutta'
      },
      chart: {
        type: 'line',
        height: 300,
        // zoomType:'x',
        zooming: {
          key: 'ctrl',
          type: 'xy' as OptionsChartZoomingTypeValue
        },
        resetZoomButton: {
          position: {
            verticalAlign: 'bottom',
            y: 50
          },
        },
      },
      title: {
        text: '',
      },
      legend: {
        enabled: true,
        symbolRadius: 0,
        symbolWidth: 0,
        useHTML: true,
        labelFormatter: function (): string {
          
          const name = this.name;
          const index = this.index;
          const color = graphColorsList[index]?.toLocaleLowerCase()
          return `<span style="display:inline-flex;align-items:center; justify-content:center"><span class="connector__type connector__type-legend" style="background-color:${color}"></span> <span>${name}</span></span>`;
        }
      },
      tooltip: {
        enabled: true,
        useHTML: true,
        outside: true,
        style: {
          zIndex: 10
        },
        formatter: function (): string {

          const date = this.key ? moment(new Date(this.key)).format('llll') : '-';
          return (
            '<div style=\'font-size:12px;\' class="graph__tooltip__header">' +
            this.series.name + `
            </div>
            <div>
              <div style='font-size:10px;' class="graph__tooltip__total___content">` +
            date + `
              </div>
              <div style='font-size:10px;' class="graph__tooltip__total___content">` +
            this.y?.toFixed(unit ? 2 : 0) + (unit ? unit : '') + `
              </div>
            </div>`
          );
        }
      },
      credits: {
        enabled: false,
      },
      xAxis: {
        type: 'datetime',
        title: {
          text: '',
        },
        startOnTick: true,
        endOnTick: true,
        // categories: categories,
        // tickInterval: categories?.length,
        labels: {
          style: {
            fontSize: '10px',
            fontFamily: 'var(--font-family-regular)',
            color: 'var(--primary-text-color)'

          },
          useHTML: true,
        
          autoRotation:[90],
          autoRotationLimit: 20,
          formatter: function (this: AxisLabelsFormatterContextObject): string {
            const timestamp = this.value as number;
            const date = new Date(timestamp);
            const formattedDate = `${moment(date).format('MMM Do')}`;
            const formattedTime = ` ${moment(date).format('LT')}`;
           
            return formattedDate +'<br/>'+ formattedTime;
          },
        },
      },
      yAxis: {
        title: {
          text: '',

        },
        allowDecimals: true,
        labels: {
          style: {
            fontSize: '10px',
            fontFamily: 'var(--font-family-regular)',
            color: 'var(--primary-text-color)'

          },
        },
        tickPositions: [graphTickPositions?.min , graphTickPositions?.mid, graphTickPositions?.max ],
      },
      plotOptions: {

        line: {
          marker: {
            enabled: true,
            symbol: 'circle',
            radius: 2,
          },
          lineWidth: 1,
        }
      },
      series: timeSeriesConnectorData?.map((item, index) => {

        
        return {
          name: historyGraphLegends?.[index], //'Connector '+(index +1),
          turboThreshold: 6000,
          data: item,
          type: 'line',
          color: graphColorsList[index]?.toLocaleLowerCase(),

        }
      })
      
    };
  }, [timeSeriesConnectorData, startDateTime, graphTickPositions, categories, unit]);

  return (
    <div className='charger__status__history__graph__wrap'>
      <CustomHighCharts container='charger__status__history__graph__wrap' highChartOptions={chartOptions} />
    </div>
  );
};

export default ChargerStatusGraph;
