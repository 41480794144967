import { globalTypes } from '../reduxExports';

export const globalFilterInitialState: IGlobalFilter[] = [
  {
    key: 'statInterval',
    values: []

  },
  {
    key: 'historyType',
    values: []
  },
  {
    key: 'statType',
    values: []
  },
  {
    key: 'historyInterval',
    values: []
  }
]

export const globalFiltersWithOnlyIdsInitialState: IScreenFilter[] = [
  {
    screen: 'chargerPanel',
    filterPreference: globalFilterInitialState
  },
]

export const chargerPanelInitialFilterStateValues: FilterState[] = [
  { key: 'historyType', value: [] },
  {
    key: 'statType',
    value: []
  },
  {
    key: 'historyInterval',
    value: []

  },
  {
    key: 'statInterval',
    value: []
  },
]

export const globalFiltersWithObjectsInitialState: IFilterStateData[] = [
  {
    screen: 'chargerPanel',
    filterStateValues: chargerPanelInitialFilterStateValues
  },
]

const globalDataInitialState: GlobalDataState = {
  globalFiltersWithOnlyIds: globalFiltersWithOnlyIdsInitialState,
  globalFiltersWithObjects: globalFiltersWithObjectsInitialState,
  toggleExpandedIds: []
};

const globalReducer = (state: GlobalDataState = globalDataInitialState, action: GlobalDataAction): GlobalDataState => {
  switch (action.type) {
    case globalTypes.SET_GLOBAL_FILTER_DATA:
      return {
        ...state,
        globalFiltersWithOnlyIds: action.globalFiltersWithOnlyIds,
      };

    case globalTypes.SET_GLOBAL_FILTER_STATE_DATA:
      return {
        ...state,
        globalFiltersWithObjects: action.globalFiltersWithObjects,
      };
    case globalTypes.SET_TOGGLE_IDS:
      return {
        ...state,
        toggleExpandedIds: action.toggleExpandedIds,
      };
    default:
      return state;
  }
};


export default globalReducer;
