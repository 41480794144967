/* eslint-disable @typescript-eslint/strict-boolean-expressions */
import { GridValidRowModel, type GridRenderCellParams } from '@mui/x-data-grid-pro'
import { type FC, useCallback, useMemo, useState, ReactNode } from 'react'
import { useNavigate } from 'react-router-dom'
import {
  TableDashboard,
  renderHeaderActionButtons,
  useAppDispatch,
  useAppSelector,
} from '../../globalUtils/globalExports'
import {
  exportToCsvChargingStationData,
  fetchChargingStationData,
  getChargingStationReducer
} from '../../rmsReduxStore/reduxExports'
import './chargingStation.css'
import React  from 'react';
import { ActionIcons } from '../../globalUtils/globalIcons'

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface ChargingStationDashboardProps {}

const ChargingStationDashboard: FC<
ChargingStationDashboardProps
> = () => {
  const { chargingStations } = useAppSelector(getChargingStationReducer)
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const layouts = ['map', 'grid', 'list']
  const [layoutView, setLayoutView] = useState<string>('list')

  const onChangeOfSearchText = useCallback((val) => {
    dispatch(fetchChargingStationData({ searchText: val, view: layoutView }))
  }, [layoutView])

  const onEditIconClick = useCallback(
    () => {
      navigate('/add-station')
    }, [navigate])

  const onMapIconClick = useCallback(() => {
    setLayoutView('map')
  }, [])

  const onExportBtnClick = useCallback(() => {
    dispatch(exportToCsvChargingStationData({ view: layoutView }))
  }, [layoutView])

  const actionIconsList = useMemo(() => {
    return [
      { icon: 'pencilIcon', action: onEditIconClick },
      { icon: 'sheildIcon', action: onMapIconClick }
    ]
  }, [onEditIconClick, onMapIconClick])

  const columns = useMemo(() => {
    return [
      {
        field: 'id',
        flex: 1,
        headerName: 'Station ID',
        minWidth: 150
      },
      {
        field: 'stationName',
        headerName: 'Station Name',
        minWidth: 180,
        editable: false,
        flex: 1
      },
      {
        field: 'customerName',
        headerName: 'Customer',
        minWidth: 180,
        flex: 1,
        editable: false
      },
      {
        field: 'location',
        headerName: 'Location',
        minWidth: 110,
        flex: 1
      },
      {
        field: 'totalChargers',
        headerName: 'Total Chargers',
        sortable: true,
        minWidth: 120,
        flex: 0.9,
        type: 'number'
      },
      {
        field: 'faultyChargers',
        headerName: 'Faulty Chargers',
        flex: 0.9,
        sortable: true,
        type: 'number',
        minWidth: 100
      },
      {
        field: 'actions',
        headerName: 'Actions',
        flex: 1,
        headerAlign: 'center',
        minWidth: 150,
        sortable: false,
   
        renderCell: (params: GridRenderCellParams<GridValidRowModel>): ReactNode => {
          const rowData = params?.row
          return (
            <div className="charging__station__action__icons__wrap">
              <ActionIcons actionIconsList={actionIconsList} data={rowData} />
            </div>
          )
        }
      }
    ]
  }, [actionIconsList])

  const mobileViewConfiguration = useMemo(() => {
    return {
      headerDataConfig: {
        headerLeftDataConfig: ['id', 'stationName'],
        headerRightDataConfig: {
          actionIconsComponent: true,
          statusIconComponent: false
        }
      },
      actionIconsList,
      contentDataConfig: [
        [
          {
            key: 'location',
            iconClassName: 'chargers__icon'
          },

          {
            key: 'customerName',
            iconClassName: 'customer__management__icon'
          }
        ],

        [
          {
            key: 'faultyChargers',
            iconClassName: 'alarm__management__icon  '
          },
          {
            key: 'totalChargers',
            iconClassName: 'navbar__logout__icon  '
          }
        ]
      ]
    }
  }, [actionIconsList])

  const searchBoxConfiguration = useMemo(() => {
    return {
      searchFieldId: 'charging-station-search-box',
      searchFieldName: 'charging-station-search-box',
      handleSearch: onChangeOfSearchText
    }
  }, [onChangeOfSearchText])

  const gridLayoutConfiguration = {
    tableExcludeFields: ['customerName'],
    headerFields: [
      {
        title: 'Total Charging Stations',
        key: 'totalChargingStations',
        icon: 'mobile__view__charging__station__icon'
      },
      {
        title: 'Total Chargers',
        key: 'totalChargers',
        icon: 'mobile__view__charger__icon'
      }
    ],
    sortFields: ['customerId'],
    groupField: 'customerId',
    colors: ['var(--bubble-green-bg-color)', 'var(--bubble-yellow-bg-color)']
  }

  const getTableRows = useCallback((): IChargingStation[] => {
    const updatedChargingStationData =
      (Boolean((chargingStations?.result))) &&
      chargingStations?.result?.length > 0 &&
      chargingStations?.result.map((station) => {
        const { stationId, ...rest } = station
        return { id: stationId, ...rest }
      })
    return updatedChargingStationData
  }, [chargingStations])

  const getGridRows = useCallback(() => {
    const updatedChargingStationData = {}
    chargingStations?.gridView &&
      chargingStations?.gridView?.length > 0 &&
      chargingStations?.gridView.forEach((customer) => {
        const {
          customerId,
          customerName,
          totalChargers,
          totalChargingStations,
          stations
        } = customer

        const headerData = {
          'Total Charging Stations': totalChargingStations.toString(),
          'Total Chargers': totalChargers.toString(),
          customerId
        }

        const tableData = stations.map((station) => {
          const { stationId, ...rest } = station
          return { id: stationId, ...rest }
        })

        updatedChargingStationData[customerName] = { headerData, tableData }
      })

    return updatedChargingStationData
  }, [chargingStations?.gridView])

  const layoutCallback = useCallback(
    (view: unknown, field: unknown, order: unknown, id: unknown) => {
      const sortField = field === 'id' ? 'stationId' : field
      dispatch(
        fetchChargingStationData({
          view: view || layoutView,
          sortBy: sortField,
          order,
          customerId: id?.toString()
        })
      )
    }, [dispatch, layoutView])




  const headerActionBtns = renderHeaderActionButtons({
    actionBtns:
      [
        {
          id: 'add_new_btn',
          headerActionBtnClick: (): void => {
            navigate('/add-station')
          }
        },
        {
          id: 'add_filter_btn',
          headerActionBtnClick: (): void => {
      
          }
        },
        {
          
          id: 'export_csv_btn',
          headerActionBtnClick: (): void => {
            onExportBtnClick?.()
          }
        }
      ]
  })

  return (
    <TableDashboard
      tableId="charging-station-table"
      tableClassName="charging__station__table"
      header="Charging Station"
      searchBoxIncluded={true}
      layoutView={layoutView}
      setLayoutView={setLayoutView}
      searchBoxConfiguration={searchBoxConfiguration}
      gridColumns={columns}
      tableRows={getTableRows()}
      gridRows={getGridRows() || {}}
      mobileViewConfiguration={mobileViewConfiguration}
      layoutsEnabled={true}
      layouts={layouts}
      gridLayoutConfiguration={gridLayoutConfiguration}
      gridLayoutCallBack={layoutCallback}
      mapLayoutCallBack={layoutCallback}
      listLayoutCallBack={layoutCallback}
      headerActionBtns={headerActionBtns}
      totalCount={100}
    />
  )
}

export default ChargingStationDashboard
