/* eslint-disable @typescript-eslint/ban-types */
import React, { FC, useCallback, useState } from 'react'
import { cloneDeep } from 'lodash';
import { Collapse } from '@mui/material';
import { Icon, IconWithTooltip } from '../globalIcons';
import { ChargerStatusParametersSkeleton } from '../SkeletonDesign/SkeletonDesign';
import {
  CustomDatePicker, ToggleSwitch, CustomInput, SelectDropDown, useAppSelector,
  useAppDispatch
} from '../globalExports';
import {getAllKeyValues } from '../globalHooks';
import {
  getGlobalReducer,
  setExpandedToggleIds
} from '../../rmsReduxStore/reduxExports';
import { parseDropDownValues, parseToggleLabels, removeParameterValueUnit, renderDropDownSelectedValue } from '../../components/chargers/ChargerPanel/ChargerPanelSettingsDashboard';
import moment from 'moment';


interface IParametersTable {
  data: ChargerMasterInfo[] | null,
  containerClassName: string,
  errorMessage?: string ,
  handleChange?: Function,
  handleCancel?: Function
  keyPressValidation?: Function
  handleSubmit?:Function
}

const ParametersTable: FC<IParametersTable> = (props) => {
  const { data, containerClassName, handleChange, handleCancel, errorMessage, keyPressValidation,handleSubmit } = props;
  const { toggleExpandedIds } = useAppSelector(getGlobalReducer);
  const dispatch = useAppDispatch()
  const [editableParamKey, setEditableParamKey] = useState(null);

  const iconMapper = {
    'acInputParams': 'ac__input__parameters__icon',
    'cabinetStatus': 'cabinet__status__parameters__icon',
    'systemAlarms': 'system__alarms__icon',
    'commParams': 'communication__parameters__icon',
    'connectorData': 'connector__data__icon',
    'ocpp': 'ocpp__parameters__icon',
    'network': 'network__parameters__icon',
    'systemConfiguration': 'system__configuration__icon',
    'systemInfo': 'system__information__icon',
    'systemLevelRating': 'system__level__rating__parameters__icon',
    'systemSettings': 'system__configuration__icon',
    'cabinetSettings': 'cabinet__status__parameters__icon',
    'acSettings': 'ac__input__parameters__icon',
    'communicationSettings': 'communication__parameters__icon',
    'connectorSettings': 'connector__data__icon',
    'acGunSettings': 'connector__data__icon'
  }


  const handleToggle = useCallback((event, val, systemInfoOption) => {  
    let array = cloneDeep(toggleExpandedIds)
    if (systemInfoOption && systemInfoOption?.subParameters?.length > 0) {
      const allParametersEmpty = systemInfoOption?.subParameters?.every(subParameter => subParameter?.subParameters?.length === 0)
      if (array && array?.length > 0 && array?.includes(val)) {
        array = array.filter(item => item !== val);
      } else {
        array?.push(val)
      }
      if (!allParametersEmpty) {
        const updatedKeys = getAllKeyValues(systemInfoOption?.subParameters, 'key');
        if (updatedKeys && updatedKeys?.length > 0 && array && array?.length > 0) {
          array = [...array, ...updatedKeys]
        }
      }
    }

    dispatch(setExpandedToggleIds(array))
  }, [toggleExpandedIds])


  // TODO: Render Parameter Components
  const renderParameters = (subParameter, index): JSX.Element => {
    return (
      <>
        <div key={'row' + index + subParameter?.id} className='charger__status__toggle__wrap__content-row'>
          {subParameter?.subParameters?.length > 0 ?
            renderData(subParameter, index) :
            // <ClickAwayListener disableReactTree={true} onClickAway={(e) => {
            //   e.stopPropagation();
            //   setEditableParamKey(null)
            // }}>
            <div className='charger__status__toggle__wrap__content-row__wrap' onClick={(e) => e.stopPropagation()}>
              <div className='charger__status__toggle__wrap__content-row-parameter-wrap'>
                {subParameter?.visibleName}
                <div className='charger__status__toggle__wrap__content-row-parameter-wrap-edit-icon'>
                  {subParameter?.access && subParameter?.access.length > 0 && subParameter?.access?.includes('WRITE') && subParameter?.fieldType && subParameter?.key !== editableParamKey && <Icon iconClassName='rms__pencil__icon' onIconClick={(e) => {
                    e?.preventDefault();
                    setEditableParamKey(subParameter?.key)
                    handleCancel?.()
                  }} />}
                </div>
              </div>
              {subParameter?.key === editableParamKey ?
                renderEditableParameters(subParameter, index) :
                <div className='charger__status__toggle__wrap__content-row-value-wrap'>
                  {subParameter?.values != null && subParameter?.values != undefined && subParameter?.values !== '' ? (subParameter?.dataType === 'Date' ? moment(subParameter?.values).format('LLL') : subParameter?.values ) : '-'}
                </div>
              }
            </div>
            // </ClickAwayListener>
          }
        </div>
      </>
    )
  }

  // TODO: Render Data Components
  const renderData = (systemInfoOption, index): JSX.Element => {
    return (
      <div key={index + systemInfoOption?.id} className='charger__status__parameters__container'>
        <div className='charger__status__parameters__wrap' onClick={(e) => { e?.stopPropagation(); handleToggle(e, systemInfoOption?.key, systemInfoOption);setEditableParamKey(null) }}>
          <div
            className={toggleExpandedIds?.includes(systemInfoOption?.key) ? 'expanded charger__status__toggle__wrap' : 'charger__status__toggle__wrap'}>
            {iconMapper[systemInfoOption?.key] &&
              <div className='charger__status__toggle__wrap-icon'>
                <Icon iconClassName={iconMapper[systemInfoOption?.key]} />
              </div>}
            <div className='charger__status__toggle__wrap-text'>
              {systemInfoOption?.visibleName} {systemInfoOption?.values && `- ${systemInfoOption?.values}`}
            </div>

            <div className='charger__status__toggle__wrap-arrow'>
              <Icon
                iconClassName={toggleExpandedIds?.length > 0 && toggleExpandedIds?.includes(systemInfoOption?.key) ? 'grid__layout__expandable__up-icon' : 'grid__layout__expandable__down-icon'} />
            </div>
          </div>
        </div>
        <Collapse sx={{ border: '1px solid var(--system-info-border-color);', borderBottom: '0px' }} timeout="auto" unmountOnExit
          in={toggleExpandedIds?.includes(systemInfoOption?.key)}>

          {systemInfoOption?.subParameters && systemInfoOption?.subParameters?.length > 0 ?
            (<div className={(systemInfoOption?.subParameters &&
              systemInfoOption?.subParameters?.length > 0 &&
              systemInfoOption?.subParameters?.every(subParameter => subParameter?.subParameters?.length === 0)) ?
              'charger__status__toggle__wrap__content without__parameters ' : 'charger__status__toggle__wrap__content with__nested__parameters'}>

              <div className='single__row__render'>
                {systemInfoOption?.subParameters?.filter(subParameter => subParameter?.subParameters?.length === 0)?.map((subParameter, index) => {
                  return (renderParameters(subParameter, index))
                })}
              </div>

              <div className={systemInfoOption?.subParameters?.length > 0 &&
                systemInfoOption?.subParameters?.every(subParameter => subParameter?.subParameters?.length === 0) ? 'single__row__render' : 'nested__row__render'}>
                {systemInfoOption?.subParameters?.filter(subParameter => subParameter?.subParameters?.length !== 0)?.map((subParameter, index) => {
                  return (renderParameters(subParameter, index))
                })}
              </div>
            </div>) :
            (<div className='charger__status__toggle__wrap__content no__data'>
              No Data
            </div>)
          }
        </Collapse>
      </div>
    )
  }

  // TODO: Render Editable Components
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const renderEditableParameters = (subParameter, index): JSX.Element => {
    return (
      <>
        <div key={'rowEditable' + index + subParameter?.id} className='charger__status__toggle__wrap__content-row-value-wrap'>
          {editableParamKey === subParameter?.key && subParameter?.fieldType === 'Input' &&
            <CustomInput
              handleInputChange={(e) => {
                e?.stopPropagation()
                handleChange?.(e?.target?.id, e?.target?.value, subParameter)
              }}
              handleKeyPress={(e) => {
                e?.stopPropagation()
                keyPressValidation?.(e, subParameter?.dataType)
              }}
              inputValue={removeParameterValueUnit(subParameter)}
              inputFieldId={subParameter?.key ? subParameter?.key : subParameter?.id}
              isRequired={false}
              errorMessage={errorMessage}
            />}
          {editableParamKey === subParameter?.key && subParameter?.fieldType === 'Toggle' &&
            <ToggleSwitch
              toggleClassName={subParameter?.key + '-' + subParameter?.visibleName}
              toggleId={subParameter?.key}
              checked={subParameter?.values === parseToggleLabels(subParameter?.possibleValues, 'Enable')}
              toggleLabelName={parseToggleLabels(subParameter?.possibleValues, 'Enable')}
              disableToggleLabelName={parseToggleLabels(subParameter?.possibleValues, 'Disable')}
              handleClick={(checked, e, id) => {
                e?.stopPropagation()
                const value = checked ? parseToggleLabels(subParameter?.possibleValues, 'Enable') : parseToggleLabels(subParameter?.possibleValues, 'Disable')
                handleChange?.(id, value, subParameter)
              }}
            />}
          {editableParamKey === subParameter?.key && subParameter?.fieldType === 'Date' &&
            <CustomDatePicker
              datePickerId={subParameter?.key}
              selectsRange={false}
              showTimePicker={true}
              startDate={ subParameter?.values ? new Date(subParameter?.values) : null}
              endDate={null}
              onChange={(selectedDate) => {
                handleChange?.(subParameter?.key,  selectedDate && new Date(selectedDate)?.toISOString(), subParameter)
              }}
            />}
          {editableParamKey === subParameter?.key && (subParameter?.fieldType === 'DropDownSingle' || subParameter?.fieldType === 'DropDownMultiple') &&
            <SelectDropDown
              dropDownClassName='settings_editable_parameters-dropdown'
              selectDropDownId={subParameter?.key}
              selectValue={renderDropDownSelectedValue(subParameter)}
              selectOptions={parseDropDownValues(subParameter?.possibleValues)}
              isMultiSelect={subParameter?.fieldType === 'DropDownMultiple'}
              handleSelectOptionChange={(selectedValues) => {
                if (subParameter?.fieldType === 'DropDownMultiple') {
                  const selected = selectedValues?.map((item) => item.value)
                  handleChange?.(subParameter?.key, selected, subParameter)
                } else {handleChange?.(subParameter?.key, selectedValues.value, subParameter)}
              }}
            />}
          {editableParamKey === subParameter?.key && subParameter?.fieldType === 'Input' && subParameter?.unit && subParameter?.unit !== 'NA' &&
            <div className='charger__status__toggle__wrap__content-row-value-wrap-unit'>
              {subParameter?.unit}
            </div>
          }
          <div className='charger__status__toggle__wrap__content-row-value-wrap-submit'>
            <div className='charger__status__toggle__wrap__content-row-value-wrap-submit-icon'>
              <IconWithTooltip iconClassName='parameter_cancel_icon' toolTipText={'Cancel'}
                onIconClick={() => {
                  setEditableParamKey(null);
                  handleCancel?.()
                }} />
            </div>
            <div className='charger__status__toggle__wrap__content-row-value-wrap-submit-icon'>
              <IconWithTooltip iconClassName='parameter_confirm_icon' toolTipText={'Confirm'}
                onIconClick={() => {
                  if (!(errorMessage && errorMessage?.length > 0)) {
                  setEditableParamKey(null);
                  handleSubmit?.(subParameter)
                  }
                }} />
            </div>
          </div>
        </div>
      </>
    )
  }

  return (
    <div className={'charger__status__parameters__container ' + containerClassName}>
      {!data ? <ChargerStatusParametersSkeleton /> : data && Array.isArray(data) && data?.map((systemInfoOption, index) => renderData(systemInfoOption, index))}
    </div>
  )
}

export default ParametersTable