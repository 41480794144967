import { customerTypes } from '../reduxExports'

const customersInitialState: CustomerState = {
  customers: null,
  loader: false,
  customerCount: 0,
  historyRecords: null,
  historyRecordsCount: 0,
  chargers: null,
  chargerCount: 0,
  selectedCharger: null,
  customerChargerListTableProps: {
    view: 'list',
    pageSize: 15,
    pageNumber: 1,
    order: 'ascending',
    sortBy: 'alarmStatus',
    searchTerm: ''
  },
  customerListTableProps: {
    view: 'list',
    sortBy: '',
    order: 'ascending',
    pageSize: 15,
    pageNumber: 1,
    searchTerm: ''
  },
  historyRecordTableProps: {
    view: 'list',
    sortBy: '',
    order: 'ascending',
    pageSize: 15,
    pageNumber: 1,
    searchTerm: ''
  },
  individualCustomerDetail: null,
  individualCustomerDetailLoader: false
}

const customersReducer = (state: CustomerState = customersInitialState, action: CustomerAction): CustomerState => {
  switch (action.type) {
    case customerTypes.FETCH_CUSTOMERS_DATA:
      return {
        ...state,
        customers: action.customers,
        customerCount: action.customerCount
      }
    case customerTypes.SET_CUSTOMER_LIST_TABLE_PROPS:
      return {
        ...state,
        customerListTableProps: action.customerListTableProps
      }
    case customerTypes.SET_CUSTOMER_CHARGER_LIST_TABLE_PROPS:
      return {
        ...state,
        customerChargerListTableProps: action.customerChargerListTableProps
      }  
    case customerTypes.SET_CUSTOMER_UPDATE_HISTORY_LIST_TABLE_PROPS:
      return {
        ...state,
        historyRecordTableProps: action.historyRecordTableProps
      }    
    case customerTypes.CLEAR_HISTORY_LIST_DATA:
      return {
        ...state,
        historyRecords: null,
        historyRecordsCount: 0,
        historyRecordTableProps: {
          view: 'list',
          sortBy: '',
          order: 'ascending',
          pageSize: state.historyRecordTableProps?.pageSize || 15,
          pageNumber: state.historyRecordTableProps?.pageNumber || 1,
          searchTerm: ''
        }
      }
    case customerTypes.CLEAR_CUSTOMER_LIST_DATA:
      return {
        ...state,
        customers: null,
        customerCount: 0,
        customerListTableProps: {
          view: 'list',
          sortBy: '',
          order: 'ascending',
          pageSize: state.customerListTableProps?.pageSize || 15,
          pageNumber: state.customerListTableProps?.pageNumber || 1,
          searchTerm: ''
        },
      }
    case customerTypes.CLEAR_CUSTOMER_CHARGER_LIST_DATA:
      return {
        ...state,
        chargers: null,
        chargerCount: 0,
        customerChargerListTableProps: {
          view: 'list',
          pageSize: 15,
          pageNumber: 1,
          order: 'ascending',
          sortBy: 'alarmStatus',
          searchTerm: ''
        },
      }
    case customerTypes.FETCH_CUSTOMER_DETAIL_DATA:
      return {
        ...state,
        individualCustomerDetail: action.individualCustomerDetail,
      }
    case customerTypes.CLEAR_CUSTOMER_DETAIL_DATA:
      return {
        ...state,
        individualCustomerDetail: null,
      }
    case customerTypes.FETCH_CUSTOMER_CHARGERS_DATA:
      return {
        ...state,
        chargers: action.chargers,
        chargerCount: action.chargerCount
      }
    case customerTypes.FETCH_HISTORY_DATA:
      return {
        ...state,
        historyRecords: action.historyRecords,
        historyRecordsCount: action.historyRecordCount
      }  
        
    default:
      return state
  }
}

export default customersReducer
