/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { type FC } from 'react';
import React, { memo, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';
import {
    AutoRefresh,
    DashboardHeader,
    DashboardLoader,
    FilledChargerCards,
    FilledBroadcastMessageCards,
    HorizontalNavbar,
    LinkButton,
    SubHeaderChargerDetailCards,
    useAppDispatch,
    useAppSelector,
    SubHeaderChargerDetailCardsWithoutHeaderText,
    TableLayout,
    SearchBox,
    useEffectOnce
} from '../../../globalUtils/globalExports';
import {
    chargerTypes,
    fetchChargersData,
    getChargerReducer,
    getGlobalReducer,
    getReferenceDataReducer,
    setGlobalFilterData,
} from '../../../rmsReduxStore/reduxExports';
import Typography from '@mui/material/Typography';

import { ChargerDetailSubHeaderSkeleton, ChargerPanelHeaderCardSkeleton, ChargerPanelHeaderSkeleton, SubHeaderSkeleton } from '../../../globalUtils/SkeletonDesign/SkeletonDesign';

interface HeaderArray {
    title:string,
    value:string
}

const BroadcastMessageDetail = memo(function BroadcastMessageDashboard() {
    const dispatch = useAppDispatch();
    const location = useLocation();
    const { chargerBroadcastMessage, broadcastMessagesCount, broadcastMessageTableLoader, chargerBroadcastMessageTableProps, chargerFiltersAppliedFromRedirection } = useAppSelector(getChargerReducer);
    const [broadcastMessageDetail, setBroadcastMessageDetail] = useState<HeaderArray[] | null>(null);
    const chargerPanelHeaderCards = useRef<HTMLDivElement>(null);

    useEffectOnce(() => {
        const headerArray: HeaderArray[] = [
                {
                    title: 'Recipients',
                    value: 'All'
                },
                {
                    title: 'Created by',
                    value: 'me'
                },
                {
                    title: 'Created on',
                    value: '2024-02-07T06:51:17.248Z'
                },
                {
                    title: 'Status',
                    value: 'COMPLETED'
                }
            ]
        setBroadcastMessageDetail(headerArray);
    });
    // useEffect(() => {
    //     if (chargerBroadcastMessage) {
    //             const data =  [
    //                     {
    //                         title: 'Recipients',
    //                         value: chargerBroadcastMessage.recipient
    //                     },
    //                     {
    //                         title: 'Created by',
    //                         value: chargerBroadcastMessage.createdBy
    //                     },
    //                     {
    //                         title: 'Created on',
    //                         value: chargerBroadcastMessage.createdAt
    //                     },
    //                     {
    //                         title: 'Status',
    //                         value: chargerBroadcastMessage.status
    //                     }
    //                 ];
    //         setBroadcastMessageDetail(data);
    //     }
    // }, [chargerBroadcastMessage]);

    const chargerHeaderCards = useMemo(() => {
        // const lastPingTime = individualChargerDetail && new Date(individualChargerDetail?.lastPingTime);
        // const statusBackgroundColor = individualChargerDetail?.status ? individualChargerDetail?.status === 'Online' ? 'var(--card-green-color)' : individualChargerDetail?.status === 'Offline' ? 'var(--card-grey-color)' : 'var(--card-grey-color)' : 'var(--card-grey-color)';
        // const alarmBackgroundColor = (!individualChargerDetail?.alarmStatus || individualChargerDetail?.alarmStatus === 'No alarm') ? 'var(--green-color)' : individualChargerDetail?.alarmStatus === 'Critical' ? 'var(--critical-alarm-color)' : individualChargerDetail?.alarmStatus === 'Major' ? 'var(--major-alarm-color)' : 'var(--minor-alarm-color)';
        // const LastPingBackgroundColor = lastPingTime ? Math.floor(((Math.abs(new Date(lastPingTime).valueOf() - new Date().valueOf())) / 1000) / 60) > 10 ? 'var(--card-grey-color)' : 'var(--charger-card-purple-color)' : 'var(--charger-card-purple-color)';

        return [
            {
                cardId: 'total-list',
                headerText: 'totalList',
                footerText: '10',
                bgColor: 'var(--navbar-color)'
            },
            {
                cardId: 'success',
                headerText: 'success',
                footerText: '07',
                bgColor: 'var(--card-green-color)'
            },
            {
                cardId: 'failed',
                headerText: 'failed',
                footerText: '01',
                bgColor: 'var(--critical-alarm-color)'
            },
            {
                cardId: 'offline',
                headerText: 'offline',
                footerText: '02',
                bgColor: 'var(--card-grey-color)'
            }
        ]
    }, []);

    const [drawerOpen, setDrawerOpen] = useState(false);

    const handleToggleDrawer = () => {
        setDrawerOpen(!drawerOpen);
    };

    const messageSubTableColumns = useMemo(() => {
        return [
          {
            field: 'chargerName',
            headerName: 'Charger Name',
            minWidth: 150,
            editable: false,
            flex: 1,
          },
          {
            field: 'chargerId',
            headerName: 'Charger Id',
            minWidth: 150,
            editable: false,
            flex: 1,
          },
          {
            field: 'status',
            headerName: 'Status',
            minWidth: 90,
            flex: 0.8,
            editable: false,
            // renderCell: (params: GridRenderCellParams<GridValidRowModel, Date>): JSX.Element => {
            //   return <MessageStatusComponent messageStatus={params?.row?.status} />;
            },
        ];
      }, []);

    return (
        <div className='charger__broadcast__message__panel'>
            <div className='charger__panel-header-wrap'>
                <div className='back__btn-container'>
                    <LinkButton btnText='Back' linkBtnState={location?.state} linkBtnId='individual-charger-back-btn' link='/broadcast-message' showArrow={true} 
                    handleOnClick={() => {}} />
                </div>
                {
                    <div className='charger__panel-header'>
                        <div className='charger__panel-header-content-wrap'>
                            <DashboardHeader header={'Details'} />
                        </div>
                    </div>
                }
            </div>
            {broadcastMessageDetail ?
                <SubHeaderChargerDetailCardsWithoutHeaderText headerArray={broadcastMessageDetail} containerClassName='' subHeaderCardId='charger-summary-key-header' /> : <SubHeaderSkeleton />}

            <div className='sub__header__grey__card__content'>
                <div className='sub__header__grey__card__content-title'>

                </div>
                Message
                <div className='sub__header__grey__card__content-value'>
                    check
                </div>
            </div>
            <div ref={chargerPanelHeaderCards} className='charger__panel-cards-wrap'>
                {chargerHeaderCards ?
                    <div className='charger__panel-alarm-cards'>
                        {chargerHeaderCards && chargerHeaderCards?.length > 0 && chargerHeaderCards?.map((chargerHeaderCard, index) => <FilledBroadcastMessageCards key={'charger-card-' + index} cardId={chargerHeaderCard?.cardId} headerText={chargerHeaderCard?.headerText} footerText={chargerHeaderCard?.footerText} bgColor={chargerHeaderCard?.bgColor} />)}
                    </div> :
                    <ChargerPanelHeaderCardSkeleton />
                }
            </div>
            <div>
                <Typography variant="h6" onClick={handleToggleDrawer} style={{ cursor: 'pointer', display: 'flex', alignItems: 'center' }}>
                    {/* {drawerOpen ? <KeyboardArrowDownIcon /> : <KeyboardArrowRightIcon />} */}
                    Show List
                </Typography>
                {drawerOpen && <SearchBox searchFieldId=''/>}
                {drawerOpen && <TableLayout
                        key={'table'}
                        gridColumns={messageSubTableColumns}
                        // tableRows={getAlarmHistoryRows()} //TODO
                        tableId={'alarm-history-table'}
                        tableClassName={'alarm__history__table'}
                        // layoutView={view}
                        pageNumber={1}
                        // mobileViewConfiguration={mobileViewConfiguration}
                        // listLayoutCallBack={alarmLayoutCallback}
                        renderCustomRowComponent={true}
                        // customRowComponent={AlarmRowCard}
                        disableHeader={true}
                        totalCount={100}
                        totalCountText={'Alarms'}
                        showSkeleton={true}
                        // tablePageSize={tablePageSize}
                    />}
            </div>
        </div >
    )
});

export default BroadcastMessageDetail
