import PropTypes from 'prop-types'
import { type FC } from 'react'
import './AlarmCards.css'
import React from 'react';
import { formatNumberWithCommas } from '../globalHooks';

export const CardType = {
  alarmContainerClassName: PropTypes.string,
  cardId: PropTypes.string.isRequired,
  alarmCount: PropTypes.number,
  footerText: PropTypes.string,
  footerSubText: PropTypes.string,
  headerText: PropTypes.string.isRequired,
  selectedCard: PropTypes.string,
  onCardClick: PropTypes.func,
  bgColor: PropTypes.string,
  icon: PropTypes.string
}

export const SubHeaderCardType = {
  containerClassName: PropTypes.string,
  subHeaderCardId: PropTypes.string.isRequired,
  headerArray: PropTypes.arrayOf(
    PropTypes.shape({
      headerText: PropTypes.string,
      headerArray: PropTypes.arrayOf(
        PropTypes.shape({
          title: PropTypes.string,
          value: PropTypes.string,
        })
      )
    })
  )

}

export const SubHeaderSingleCardType = {
  containerClassName: PropTypes.string,
  subHeaderCardId: PropTypes.string.isRequired,
  headerArray: PropTypes.arrayOf(PropTypes.shape({
    title: PropTypes.string,
    value: PropTypes.string,
  })
  )
}

export type SubHeaderCardPropTypes = PropTypes.InferProps<typeof SubHeaderCardType>
export type SubHeaderSingleCardPropTypes = PropTypes.InferProps<typeof SubHeaderSingleCardType>
export type CardPropTypes = PropTypes.InferProps<typeof CardType>

export const alarmCardMapper = {
  TOTAL: {
    title: 'Total',
    color: 'var(--total-alarm-color)',
    alarmSummaryIcon: 'rms__icon total__alarm__icon'
  },
  CRITICAL: {
    title: 'Critical',
    color: 'var(--critical-alarm-color)',
    alarmSummaryIcon: 'rms__icon critical__alarm__icon'
  },
  MAJOR: {
    title: 'Major',
    color: 'var(--major-alarm-color)',
    alarmSummaryIcon: 'rms__icon major__alarm__icon'
  },
  MINOR: {
    title: 'Minor',
    color: 'var(--minor-alarm-color)',
    alarmSummaryIcon: 'rms__icon minor__alarm__icon'
  }
}

export const chargerCardMapper = {
  status: {
    title: 'Status',
    color: 'var(--card-green-color)',
    icon: 'rms__icon charger__card__online__status__icon'
  },
  chargerModel: {
    title: 'Model',
    color: 'var(--charger-card-pink-color)',
    icon: 'rms__icon charger__card__charger__modal__icon'
  },
  lastPing: {
    title: 'Last Ping',
    color: 'var(--charger-card-purple-color)',
    icon: 'rms__icon charger__card__last__ping__icon'
  },
  alarmStatus: {
    title: 'Alarm Status',
    color: 'var(--card-red-color)',
    icon: 'rms__icon charger__card__alarm__status__icon'
  },
  softwareVersion: {
    title: 'Software Version',
    color: 'var(--card-blue-color)',
    icon: 'rms__icon charger__card__software__version__icon'
  }
}

export const BroadcastMessageDetailCardMapper = {
  totalList: {
    title: 'Total List',
    color: 'var(--card-green-color)',
    icon: 'rms__icon charger__card__online__status__icon'
  },
  success: {
    title: 'success',
    color: 'var(--charger-card-pink-color)',
    icon: 'rms__icon charger__card__charger__modal__icon'
  },
  failed: {
    title: 'Failed',
    color: 'var(--charger-card-purple-color)',
    icon: 'rms__icon charger__card__last__ping__icon'
  },
  offline: {
    title: 'Offline',
    color: 'var(--card-red-color)',
    icon: 'rms__icon charger__card__alarm__status__icon'
  }
}

export const ViewParameterDetailCardMapper = {
  totalList: {
    title: 'Total List',
    color: 'var(--card-green-color)',
    icon: 'rms__icon parameter__card__total__icon'
  },
  success: {
    title: 'Success',
    color: 'var(--charger-card-pink-color)',
    icon: 'rms__icon parameter__card__success__icon'
  },
  failed: {
    title: 'Failed',
    color: 'var(--charger-card-purple-color)',
    icon: 'rms__icon parameter__card__faied__icon'
  },
  inProgress:{
    title: 'InProgress',
    color: 'var(--charger-card-purple-color)',
    icon: 'rms__icon parameter__card__in_progress__icon'
  }


}

export const AlarmCards: FC<CardPropTypes> = (props) => {
  const { alarmContainerClassName, cardId, alarmCount, headerText, onCardClick, selectedCard } = props

  return (
    <div key={cardId + alarmCount} id={cardId} className={'alarm__summary-card ' + alarmContainerClassName} style={{ borderTop: `4px solid ${alarmCardMapper[headerText]?.color}`, borderColor: (selectedCard === headerText) ? alarmCardMapper[headerText]?.color : '' }} onClick={(e) => { e?.stopPropagation(); onCardClick?.(props) }}>

      <div className='alarm__summary-card-border' style={{
        backgroundColor: alarmCardMapper[headerText]?.color
      }}></div>
      <div className='alarm__summary-card-header' style={{ color: alarmCardMapper[headerText]?.color }}>
        {alarmCardMapper[headerText]?.title}
      </div>
      <div className='alarm__summary-card-count'>{formatNumberWithCommas(alarmCount)}</div>
    </div>
  )
}

export const FilledChargerCards: FC<CardPropTypes> = (props) => {
  const {
    alarmContainerClassName,
    cardId,
    footerText,
    footerSubText,
    headerText,
    bgColor,
  } = props;

  const icon = (cardId === 'status' && footerText === 'Offline') ? 'rms__icon charger__card__offline__status__icon' : chargerCardMapper[headerText]?.icon;

  return (
    <div key={cardId} id={cardId} className={'alarm__summary-card-filled ' + alarmContainerClassName}
      style={{ backgroundColor: bgColor !== null && bgColor !== undefined ? bgColor : chargerCardMapper[headerText] ? chargerCardMapper[headerText]?.color : '' }}>
      <div className='alarm__summary-card-left '>
        <div className='alarm__summary-card-header-text'>
          {chargerCardMapper[headerText]?.title}
        </div>

        <div className='alarm__summary-card-footer'>
          <div className='alarm__summary-card-footer-text'>{footerText}</div>
          {(footerSubText != null) && (
            <div className='alarm__summary-card-footer-sub-text'>
              {footerSubText}
            </div>
          )}
        </div>
      </div>

      <div className='alarm__summary-card-right'>
        {/* <div className={chargerCardMapper[headerText]?.icon}></div> */}
        {icon && <div className={icon}></div>}
      </div>
    </div>
  )
}

export const FilledBroadcastMessageCards: FC<CardPropTypes> = (props) => {
  const {
    alarmContainerClassName,
    cardId,
    footerText,
    footerSubText,
    headerText,
    bgColor,
  } = props;

  const icon = BroadcastMessageDetailCardMapper[headerText]?.icon;

  return (
    <div key={cardId} id={cardId} className={'alarm__summary-card-filled ' + alarmContainerClassName}
      style={{ backgroundColor: bgColor !== null && bgColor !== undefined ? bgColor : chargerCardMapper[headerText] ? chargerCardMapper[headerText]?.color : '' }}>
      <div className='alarm__summary-card-left '>
        <div className='alarm__summary-card-header-text'>
          {BroadcastMessageDetailCardMapper[headerText]?.title}
        </div>

        <div className='alarm__summary-card-footer'>
          <div className='alarm__summary-card-footer-text'>{footerText}</div>
          {(footerSubText != null) && (
            <div className='alarm__summary-card-footer-sub-text'>
              {footerSubText}
            </div>
          )}
        </div>
      </div>

      <div className='alarm__summary-card-right'>
        {/* <div className={chargerCardMapper[headerText]?.icon}></div> */}
        {icon && <div className={icon}></div>}
      </div>
    </div>
  )
}

export const FilledViewParametersCards: FC<CardPropTypes> = (props) => {
  const {
    alarmContainerClassName,
    cardId,
    footerText,
    footerSubText,
    headerText,
    bgColor,
  } = props;

  const icon = ViewParameterDetailCardMapper[headerText]?.icon;

  return (
    <div key={cardId} id={cardId} className={'alarm__summary-card-filled ' + alarmContainerClassName}
      style={{ backgroundColor: bgColor !== null && bgColor !== undefined ? bgColor : chargerCardMapper[headerText] ? chargerCardMapper[headerText]?.color : '' }}>
      <div className='alarm__summary-card-left '>
        <div className='alarm__summary-card-header-text'>
          {ViewParameterDetailCardMapper[headerText]?.title}
        </div>

        <div className='alarm__summary-card-footer'>
          <div className='alarm__summary-card-footer-text'>{footerText}</div>
          {(footerSubText != null) && (
            <div className='alarm__summary-card-footer-sub-text'>
              {footerSubText}
            </div>
          )}
        </div>
      </div>

      <div className='alarm__summary-card-right'>
        {icon && <div className={icon}></div>}
      </div>
    </div>
  )
}

export const SubHeaderChargerDetailCards: FC<SubHeaderCardPropTypes> = (
  props
) => {
  const { containerClassName, subHeaderCardId, headerArray } = props

  return (
    <div id={subHeaderCardId} className={'sub__header__grey__card__wrapper ' + containerClassName}>
      {headerArray != null &&
        headerArray?.length > 0 &&
        headerArray?.map((data, index) => {
          return (
            <div
              key={'charger-sub-header' + index}
              className='sub__header__grey__card'>
              <div className='sub__header__grey__card__header'>
                {data?.headerText}
              </div>
              <div className='sub__header__grey__card__content__wrap'>
                {((data?.headerArray) != null) &&
                  data?.headerArray?.length > 0 &&
                  data?.headerArray?.map((arrayData, index) => {
                    return (
                      <div key={index} className='sub__header__grey__card__content'>
                        <div className='sub__header__grey__card__content-title'>
                          {arrayData?.title}
                        </div>

                        <div className='sub__header__grey__card__content-value'>
                          {arrayData?.value}
                        </div>
                      </div>
                    )
                  })}
              </div>
            </div>
          )
        })}
    </div>
  )
}

export const SubHeaderChargerDetailCardsWithoutHeaderText: FC<SubHeaderSingleCardPropTypes> = (
  props
) => {
  const { containerClassName, subHeaderCardId, headerArray } = props

  return (
    <div id={subHeaderCardId} className={'sub__header__grey__card__wrapper ' + containerClassName}>
      <div
        key={'charger-sub-header'}
        className='sub__header__grey__card'>
        <div className='sub__header__grey__card__content__wrap'>
          {headerArray?.map((arrayData, index) => {
            return (
              <div key={index} className='sub__header__grey__card__content'>
                <div className='sub__header__grey__card__content-title'>
                  {arrayData?.title}
                </div>
                <div className='sub__header__grey__card__content-value'>
                  {arrayData?.value}
                </div>
              </div>
            )
          })}
        </div>  
      </div>
    </div>
  )
}
