/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/ban-types */
/* eslint-disable react/prop-types */
import './chargingStation.css'
import { useState, useEffect, type FC, useMemo, useCallback } from 'react'
import {
  useAppDispatch, useAppSelector, CustomButtonGroup, CustomBreadCrumbs, DashboardLoader
} from '../../globalUtils/globalExports'
import DashboardHeader from '../../globalUtils/DashboardHeader/DashboardHeader'
import { getCustomer, getChargingStationReducer, getCountryState, getReferenceData } from '../../rmsReduxStore/reduxExports'
import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom'
import ImageDialog from '../../globalUtils/ImageDialog/ImageDialog'
import CustomForm, { ICustomFormProps, IFormContentProp } from '../../globalUtils/CustomForm/CustomForm'
import { checkCharactersPaste, checkPINCodePaste, createBreadCrumbProps, keyPressValidationForCharacters, keyPressValidationForPINCode } from '../../globalUtils/globalHooks'
import { AxiosResponse } from 'axios'
import { Id, toast } from 'react-toastify'
import { createChargingStation } from '../../rmsReduxStore/customersRedux/customerCreators'

interface IStationState {
  customer: { name: string | null; id: string | null };
  stationName: string | null;
  stationImage: string | null; 
  description: string | null;
  addressLine1: string | null;
  addressLine2: string | null;
  state: string | null;
  country: Record<string, unknown>; 
  pinCode: string | null;
  latitude: string | null; 
  longitude: string | null; 
  locationType: Record<string, unknown>; 
  city: string | null;
  customerOptions: unknown[]; 
  locationTypeOptions: unknown[]; 
  imageUrl: string | null; 
}

const AddChargingStation: FC = () => {
  const dispatch = useAppDispatch()
  const navigate = useNavigate();
  const [showDialog, setShowDialog] = useState(false);
  const [selectedImage, setSelectedImage] = useState<string | null>(null);
  const { customers, locationTypeOptions } = useAppSelector(getChargingStationReducer)
  const [showLoader, setShowLoader] = useState(false);
  const location = useLocation();

  const somethingWentWrongToast = (): Id => toast.warn('Something went wrong.');
  const StationAddedToast = (): Id => toast.success('Charging station added.');


  const [stationState, setStationState] = useState<IStationState>({
    customer: {name:location?.state?.name, id:location?.state?.id,},
    stationName: null,
    stationImage: null,
    description: null,
    addressLine1: null,
    addressLine2: null,
    state: null,
    country: {},
    pinCode: null,
    latitude: null,
    longitude: null,
    locationType: {},
    city: null,
    customerOptions: [],
    locationTypeOptions: [],
    imageUrl: null
  })

  const disableAddButton = useCallback(() => {
    return (!(
      (stationState?.customer?.id!=null && stationState?.customer?.id?.length > 0) &&
      (stationState?.stationName!=null && stationState?.stationName?.length > 0) &&
      (stationState?.latitude!=null && stationState?.latitude!=undefined && stationState?.latitude.length>0) &&
      (stationState?.longitude!=null && stationState?.longitude!=undefined && stationState?.longitude.length>0)
    ))
  }, [stationState])

  const btnList = [
    {
      buttonText: 'Add',
      buttonId: 'add',
      btnClassName: disableAddButton() ? 'primary__btn disabled' : 'primary__btn',
      buttonVariant: 'filled',
      handleClick: async (): Promise<void> => {
        // Validate latitude here
        const latitudeValue = parseFloat(stationState?.latitude ?? '');
        if (latitudeValue && (isNaN(latitudeValue) || latitudeValue < -90 || latitudeValue > 90)) {
          toast.error('Latitude should be in range of [-90,90]');
          return;
        }
        // Validate longitude here
        const longitudeValue = parseFloat(stationState?.longitude ?? '');
        if (longitudeValue && (isNaN(longitudeValue) || longitudeValue < -180 || longitudeValue > 180)) {
          toast.error('Longitude should be in range of [-180,180]');
          return;
        }
        setShowLoader(true)
        const addCharingStationRequest:ICustomerChargingStation={
          name:stationState.stationName,
          customerId:stationState.customer.id,
          description: stationState.description,
          addressLine1: stationState.addressLine1,
          addressLine2: stationState.addressLine2,
          city: stationState?.city,
          state: stationState?.state,
          pinCode: stationState?.pinCode,
          latitude: stationState?.latitude?.toString(),
          longitude: stationState?.longitude?.toString(),
          imageUrl: stationState?.imageUrl
        }
        const response: AxiosResponse = await dispatch(createChargingStation(addCharingStationRequest));
        if (response?.status === 200 || response?.status === 202) {
          setShowLoader(false)
          StationAddedToast();
          setTimeout(() => {
            navigate('/customer', { state: location?.state })
          }, 2000)
        }
        else {
          setShowLoader(false)
          somethingWentWrongToast();
        }
      },
      isDisabled: disableAddButton(),
    },
    {
      buttonText: 'Cancel',
      buttonId: 'cancel',
      btnClassName: 'secondary__btn',
      handleClick: (): void => {
        navigate('/customer', { state: location?.state })
      },
      isDisabled: false,
      buttonVariant: 'outlined',
    },
  ];

  const setStationDetailData = (updatedState) => {
    setStationState(prevState => (
      {
        ...prevState,
        ...updatedState
      }
    ))
  }

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type, react/prop-types
  const CustomerOption = ({ option }) => {
    return (
      <div className="charging__station__customer__option">
        <div>{option?.customerName}</div>
        <div>{option?.city}</div>
      </div>
    )
  }

  useEffect(() => {
    dispatch(getCustomer?.())
    dispatch(getCountryState?.())
    dispatch(getReferenceData?.())
  }, [dispatch])

  useEffect(() => {
    const options = customers?.map(item => {
      return ({ id: item?.id, value: item?.id, label: <CustomerOption option={item} /> })
    })
    setStationDetailData({ customerOptions: options })
  }, [customers])


  useEffect(() => {
    const options = locationTypeOptions?.locationType?.map(item => {
      return ({ id: item, value: item, label: item })
    })
    setStationDetailData({ locationTypeOptions: options })
  }, [locationTypeOptions])

  const keyPressValidation = (event): void => {
    const { key, target } = event;
    // Allow dot (.) for decimal values
    if (key === '.' && target?.value?.includes('.')) {
      event.preventDefault();
    }
    if (key === '-' && target?.selectionStart === 0 && target?.selectionEnd === 0 && !target?.value?.includes('-')) {
      // Allow minus sign (-) only if it's the first character and the input field is empty
      // This condition checks if the cursor is at the beginning of the input field
      // and if the input field does not already contain a minus sign
      return;
    }
    // Check for numeric keys, backspace, and allow CMD+V (for Mac) or CTRL+V (for Windows/Linux)
    if (key === ' ' || (isNaN(Number(key)) && key !== '.' && key !== 'Backspace' && !(event.metaKey || event.ctrlKey && key.toLowerCase() === 'v'))) {
      event.preventDefault();
    }
  };

  function check(value: string): string {
    // Regular expression to match a decimal number
    const decimalPattern = /^[-+]?\d*\.?\d+$/;
    if (decimalPattern.test(value + '0')) {
      return value;//value;//(value.at(-1)==='.')?parseFloat(value).toString():value;
    } else {
      return '';
    }
  }

  const handleCustomerSelectChange = (val): void => {
    setStationDetailData({ customer: val.name })
  }

  const handleStationNameChange = (val): void => {
    setStationDetailData({ stationName: val?.target?.value })
  }

  const handleCustomerStateChange = (val): void => {
    setStationDetailData({ state: val })
  }

  const handleCityChange = (val): void => {
    setStationDetailData({ city: val?.target?.value })
  }

  const handleDescriptionChange = (val): void => {
    setStationDetailData({ description: val?.target?.value })
  }

  const handleLongitudeChange = (val): void => {
    // 68.7 - 97.25
    // const numericValue = parseFloat(val);
    // if(numericValue>=68.7 && numericValue<=97.25) 
    setStationDetailData({ longitude: (val) })
  }
  const handleLatitudeChange = (val): void => {
    // [8.4 - 37.6]
    // const numericValue = parseFloat(val);
    // if(numericValue>=8.4 && numericValue<=37.6) 
    setStationDetailData({ latitude: (val) })
  }

  const handleAddressLine1Change = (val): void => {
    setStationDetailData({ addressLine1: val?.target?.value })
  }

  const handleAddressLine2Change = (val): void => {
    setStationDetailData({ addressLine2: val?.target?.value })
  }

  const handlePinCodeChange = (val): void => {
    setStationDetailData({ pinCode: val })
  }

  const handleImageChange = (file): void => {
    if (file) {
      const reader = new FileReader();
      reader.onload = () => {
        if (typeof reader.result === 'string') {
          setSelectedImage(reader.result);
        }
      };
      reader.readAsDataURL(file?.[0]);
    }
    setStationDetailData({ stationImage: file?.[0]?.name })
  }

  const triggerDialog = (): void => {
    setShowDialog(true)
  }

  const profileInfoFormArray: IFormContentProp[] = [
    {
      rowCountClassName: 'grid-3',
      formObject: [
        {
          formLabel: 'Customer',
          isRequired: true,
          objectType: 'input',
          selectDropdownProps: null,
          inputProps: {
            inputPlaceHolder: 'Enter Username',
            handleInputChange: (event): void => {
              handleCustomerSelectChange(event?.target?.value);
            },
            inputValue: location?.state?.name,
            inputType: 'string',
            inputFieldId: 'add_user_name',
            isRequired: false,
            isDisabled: true
          },
          textAreaProps: null,
        },
        {
          formLabel: 'Station Name',
          isRequired: true,
          objectType: 'input',
          selectDropdownProps: null,
          inputProps: {
            inputPlaceHolder: 'Type Here',
            handleInputChange: handleStationNameChange,
            inputValue: stationState?.stationName ?? '',
            inputType: 'string',
            inputFieldId: 'add_user_name',
            isRequired: false,
            isDisabled: false
          },
          textAreaProps: null,
        },
        {
          formLabel: 'Station Image',
          isRequired: false,
          objectType: 'upload',
          selectDropdownProps: null,
          inputProps: null,
          uploadProps: {
            fileName: stationState?.stationImage,
            inputFieldId: '',
            isRequired: false,
            handleInputChange: handleImageChange,
            showDialog: triggerDialog
          },
          textAreaProps: null,
        },
      ],
    },
    {
      rowCountClassName: 'grid-1',
      formObject: [
        {
          formLabel: 'Description',
          isRequired: false,
          objectType: 'text-area',
          selectDropdownProps: null,
          inputProps: null,
          textAreaProps: {
            inputPlaceHolder: 'Type here',
            handleInputChange: handleDescriptionChange,
            isRequired: false,
            inputValue: stationState?.description ?? '',
            inputFieldId: 'adding_station',
          },
        },
      ],
    },
    {
      rowCountClassName: 'grid',
      formObject: [
        {
          formLabel: 'Address',
          isRequired: false,
          objectType: 'input',
          selectDropdownProps: null,
          inputProps: {
            inputPlaceHolder: 'Enter Address Line 1',
            handleInputChange: handleAddressLine1Change,
            inputValue: stationState?.addressLine1 ?? '',
            inputType: 'string',
            inputFieldId: 'user__form-addressLine1',
            isRequired: false,
          },
          textAreaProps: null,
        }
      ]
    },
    {
      rowCountClassName: 'grid',
      formObject: [
        {
          formLabel: '',
          isRequired: false,
          objectType: 'input',
          selectDropdownProps: null,
          inputProps: {
            inputPlaceHolder: 'Enter Address Line 2',
            handleInputChange: handleAddressLine2Change,
            inputValue: stationState?.addressLine2 ?? '',
            inputType: 'string',
            inputFieldId: 'user__form-addressLine2',
            isRequired: false,
          },
          textAreaProps: null,
        }
      ]
    },
    {
      rowCountClassName: 'grid-4',
      formObject: [
        {
          formLabel: 'City',
          isRequired: false,
          objectType: 'input',
          selectDropdownProps: null,
          inputProps: {
            inputPlaceHolder: 'Enter City here',
            handleInputChange: handleCityChange,
            inputValue: stationState?.city ?? '',
            inputType: 'string',
            inputFieldId: 'user__form-city',
            isRequired: false,
          },
          textAreaProps: null,
        },
        {
          formLabel: 'State',
          isRequired: false,
          objectType: 'input',
          selectDropdownProps: null,
          inputProps: {
            inputPlaceHolder: 'Enter State here',
            handleInputChange: (event): void => {
              handleCustomerStateChange(checkCharactersPaste(event?.target?.value));
            },
            handleKeyPress: keyPressValidationForCharacters,
            inputValue: stationState?.state ?? '',
            inputType: 'string',
            inputFieldId: 'user__form-state',
            isRequired: false,
          },
          textAreaProps: null,
        },
        {
          formLabel: 'Pin Code',
          isRequired: false,
          objectType: 'input',
          selectDropdownProps: null,
          inputProps: {
            inputPlaceHolder: 'Type here',
            handleInputChange: (event): void => {
              handlePinCodeChange(checkPINCodePaste(event));
            },
            inputValue: stationState?.pinCode ?? '',
            inputType: 'string',
            className: 'form-column-66',
            inputFieldId: 'pin_code',
            isRequired: false,
            handleKeyPress: keyPressValidationForPINCode
          },
          textAreaProps: null,
        },
      ]
    },
    {
      rowCountClassName: 'grid-6',
      formObject: [
        {
          formLabel: 'Latitude',
          isRequired: true,
          objectType: 'input',
          selectDropdownProps: null,
          inputProps: {
            inputPlaceHolder: 'Type here',
            handleInputChange: (event): void => {
              handleLatitudeChange(check(event?.target?.value));
            },
            inputValue: stationState?.latitude ?? '',
            inputType: 'string',
            inputFieldId: 'user__form-latitude',
            isRequired: true,
            handleKeyPress: keyPressValidation,
          },
          textAreaProps: null,
        },
        {
          formLabel: 'Longitude',
          isRequired: true,
          objectType: 'input',
          selectDropdownProps: null,
          inputProps: {
            inputPlaceHolder: 'Type here',
            handleInputChange: (event): void => {
              handleLongitudeChange(check(event?.target?.value));
            },
            inputValue: stationState?.longitude ?? '',
            inputType: 'string',
            inputFieldId: 'user__form-longitude',
            isRequired: true,
            handleKeyPress: keyPressValidation
          },
          textAreaProps: null,
        },
      ]
    },
  ]

  const formProps: ICustomFormProps = useMemo(() => {
    return {
      formContainerClassName: '',
      formArray: profileInfoFormArray
    };
  }, [stationState])

  // TODO: BreadCrumb navigation Props
  const breadCrumbs = createBreadCrumbProps({
    breadCrumbProps:
      [
        {
          objectType: 'link',
          id: 'customers',
        },
        {
          objectType: 'link',
          id: 'editCustomer',
          linkBtnState: location?.state,
        },
        {
          objectType: 'text',
          id: 'text',
          text: 'Add Charging Station'
        }
      ]
  })

  return (
    <>
      <DashboardLoader showLoader={showLoader} />
      <div className="add__station__form__wrap">
        <CustomBreadCrumbs breadCrumbs={breadCrumbs} />
        <div className="add__station__header">
          <DashboardHeader header="Add Charging Station" />
        </div>
        <CustomForm {...formProps} />

        <div className='add__station--footer'>
          <CustomButtonGroup
            buttonsList={btnList}
            buttonGroupClassName={'button__group__footer'}
          />
        </div>
      </div>
      <ImageDialog
        dialogConfig={{
          imageUrl: selectedImage,
          dialogOkTitle: 'Close',
        }}
        show={showDialog}
        handleSubmit={() => setShowDialog(false)}
      />
    </>
  )
}

export default AddChargingStation
