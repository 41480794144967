/* eslint-disable @typescript-eslint/no-unused-vars */

import React,{
    type ChangeEvent,
    type MouseEvent,
    memo,
    useCallback,
    useMemo,
    useRef,
    useState
  } from 'react'
  
import { alarmCoreNavList, remoteCommandRadioButtons } from '../../alarmConstants'
import AlarmPanelSubNavListHeader from '../AlarmPanelSubNavListHeader'
import AlarmPanelTabs from '../AlarmPanelTabs'

  interface AlarmPanelCore {
    contentHeight: number
  }
  
  const AlarmPanelCoreDashboard = memo(
    function AlarmPanelCoreDashboard ({
      contentHeight
    }: AlarmPanelCore) {
      // const dashboardContentRef = useRef<HTMLDivElement>(null)
      const dashboardNavRef = useRef<HTMLDivElement>(null)
  
      const [urlValue, setUrlValue] = useState('')
      const [selectedNav, setSelectedNav] = useState(alarmCoreNavList[0].buttonId ? alarmCoreNavList[0].buttonId : 'change-availability')
      const handleNavBtnClick = useCallback(
        (event: MouseEvent<HTMLButtonElement>, val: string) => {
          setSelectedNav(val)
        },
        []
      )
  
      const onPerformBtnClick = useCallback(() => {}, [])
  
      const onCancelBtnClick = useCallback(() => {}, [])
      
      const handleUrlChange = useCallback((event) => {
        setUrlValue(event?.target?.value)
      }, [])

      const changeAvailabilityFormFields = [
        {
          label: 'Reset',
          placeholder: 'Enter value for Field 1',
          handleChange: handleUrlChange,
          value: '',
        }
      ]

      const dataTransferFormFields = [
        {
          label: 'Vendor ID (String)',
          placeholder: 'Enter value for Field 1',
          handleChange: handleUrlChange,
          value: '',
        },
        {
          label: 'Message ID (String)',
          placeholder: 'Enter value for Field 1',
          handleChange: handleUrlChange,
          value: '',
        },
        {
          label: 'Data Tag',
          placeholder: 'Enter value for Field 1',
          handleChange: handleUrlChange,
          value: '',
        }
      ]
  
      const btnsList = useMemo(() => {
        return [
          {
            buttonText: 'Perform',
            buttonId: 'perform',
            btnClassName: 'primary__btn',
            handleClick: onPerformBtnClick,
            isDisabled: false,
            buttonVariant: 'filled'
          },
          {
            buttonText: 'Cancel',
            buttonId: 'cancel',
            btnClassName: 'secondary__btn',
            handleClick: onCancelBtnClick,
            isDisabled: false,
            buttonVariant: 'outlined'
          }
        ]
      }, [onCancelBtnClick, onPerformBtnClick])
  
      return (
        <div
          className="alarm__panel__core__dashboard">
          <AlarmPanelSubNavListHeader
            handleNavBtnClick={handleNavBtnClick}
            selectedNav={selectedNav}
            dashboardNavRef={dashboardNavRef}
            navList={alarmCoreNavList}
          />
          {selectedNav === 'change-availability' &&
          (<AlarmPanelTabs 
            // contentHeight={280} 
            description='This command is used to reboot the Charger (also refers as Charge Point). It has two options, Soft and Hard reset.' 
            radioButtonsList={remoteCommandRadioButtons}
            formFields={changeAvailabilityFormFields}
            onCancelBtnClick={useCallback(() => {}, [])}
            onPerformBtnClick={useCallback(() => {}, [])}
            isTagTypeEnabled={false}
          />)}
          {selectedNav === 'manage-configuration' && (<AlarmPanelTabs 
            // contentHeight={280} 
            description='This command is used to reboot the Charger (also refers as Charge Point). It has two options, Soft and Hard reset.' 
            radioButtonsList={remoteCommandRadioButtons}
            formFields={[]}
            onCancelBtnClick={useCallback(() => {}, [])}
            onPerformBtnClick={useCallback(() => {}, [])}
            isTagTypeEnabled={false}
          />)}
          {selectedNav === 'clear-cache' && (<AlarmPanelTabs 
            // contentHeight={280} 
            description='This command is used to clear Authorization Cache. Authorization Cache autonomously maintains a record of previously presented identifiers that have been successfully authorized by the Central System.' 
            radioButtonsList={remoteCommandRadioButtons}
            formFields={[]}
            onCancelBtnClick={useCallback(() => {}, [])}
            onPerformBtnClick={useCallback(() => {}, [])}
            isTagTypeEnabled={false}
          />)}
          {selectedNav === 'reset' && (<AlarmPanelTabs 
            // contentHeight={280} 
            description='This command is used to reboot the Charger (also refers as Charge Point). It has two options, Soft and Hard reset.' 
            radioButtonsList={remoteCommandRadioButtons}
            formFields={changeAvailabilityFormFields}
            onCancelBtnClick={useCallback(() => {}, [])}
            onPerformBtnClick={useCallback(() => {}, [])}
            isTagTypeEnabled={false}
          />)}
          {selectedNav === 'data-transfer' && (<AlarmPanelTabs 
            // contentHeight={280} 
            description='Allows custom messages to be sent. Vendor specific commands are usually implemented using this message. It&apos;s the only two-way message in the protocol, which means it can be initiated by both sides.' 
            radioButtonsList={remoteCommandRadioButtons}
            formFields={dataTransferFormFields}
            onCancelBtnClick={useCallback(() => {}, [])}
            onPerformBtnClick={useCallback(() => {}, [])}
            isTagTypeEnabled={false}
          />)}
        </div>
      )
    }
  )
  
  export default AlarmPanelCoreDashboard
  