import React from 'react';
import { Stack, Switch, Typography, styled } from '@mui/material';
import PropTypes from 'prop-types';


const AntSwitch = styled(Switch)(({ theme }) => ({
  width: 28,
  height: 16,
  padding: 0,
  display: 'flex',
  '&:active': {
    '& .MuiSwitch-thumb': {
      width: 15,
    },
    '& .MuiSwitch-switchBase.Mui-checked': {
      transform: 'translateX(9px)',
    },
  },
  '& .MuiSwitch-switchBase': {
    padding: 2,
    '&.Mui-checked': {
      transform: 'translateX(12px)',
      color: '#fff',
      '& + .MuiSwitch-track': {
        opacity: 1,
        backgroundColor: 'var(--secondary-blue-color)',
      },
    },
  },
  '& .MuiSwitch-thumb': {
    boxShadow: '0 2px 4px 0 rgb(0 35 11 / 20%)',
    width: 12,
    height: 12,
    borderRadius: 6,
    transition: theme.transitions.create(['width'], {
      duration: 200,
    }),
  },
  '& .MuiSwitch-track': {
    borderRadius: 16 / 2,
    opacity: 1,
    backgroundColor: 'rgba(0,0,0,.25)',
    boxSizing: 'border-box',
  },
}));

export const CustomSwitchType = {
  switchId: PropTypes.string.isRequired,
  switchClassName: PropTypes.string,
  switchLabelName: PropTypes.string,
  disableSwitchLabelName: PropTypes.string,
  handleClick: PropTypes.func,
  isDisabled: PropTypes.bool,
  checked: PropTypes.bool.isRequired
};

type CustomSwitchTypePropTypes = PropTypes.InferProps<
  typeof CustomSwitchType
>;

const CustomSwitch: React.FC<CustomSwitchTypePropTypes> = (props) => {
  const { switchId, switchClassName, handleClick, isDisabled, switchLabelName, disableSwitchLabelName, checked } = props;


  return (
    <Stack className={'custom__switch ' + switchClassName} direction='row' spacing={1} alignItems='center'>
      <Typography style={{
        fontFamily: 'var(--font-family-regular)',
        fontSize: '15px',
        fontWeight: 500,
        letterSpacing: '0.1px',
        lineHeight: '1'
      }}>{disableSwitchLabelName}</Typography>
      <AntSwitch
        id={switchId}
        checked={checked ?? false}
        onChange={(e) => handleClick?.(e)}
        disabled={isDisabled ?? false}
      />
      <Typography style={{
        fontFamily: 'var(--font-family-regular)',
        fontSize: '15px',
        fontWeight: checked ? 600 : 300,
        letterSpacing: '0.1px',
        lineHeight: '1'
      }}>{switchLabelName}</Typography>
    </Stack>
  );
};

export default CustomSwitch;
