import { userTypes } from '../reduxExports'


const usersInitialState: UserState = {
  users: null,
  loader: false,
  userCount: 0,
  roles: null,
  rolesCount: 0,
  userListTableProps: {
    view: 'list',
    sortBy: '',
    order: 'ascending',
    pageSize: 15,
    pageNumber: 1,
    searchTerm: ''
  },
  roleListTableProps: {
    view: 'list',
    sortBy: '',
    order: 'ascending',
    pageSize: 15,
    pageNumber: 1,
    searchTerm: ''
  },
  individualUserDetail: null,
  chargerListByCustomer: null,
  orgs: null,
  orgsCount: 0,
  orgListTableProps: {
    view: 'list',
    sortBy: '',
    order: 'ascending',
    pageSize: 15,
    pageNumber: 1,
    searchTerm: ''
  },
  chargerListByCustomerByOrg: null,
  individualOrgDetail: null,
  individualRoleDetail: null,
  userFiltersAppliedFromRedirection: false
}

const usersReducer = (state: UserState = usersInitialState, action: UserAction): UserState => {
  switch (action.type) {
    case userTypes.FETCH_USERS_DATA:
      return {
        ...state,
        users: action.users,
        userCount: action.userCount
      }
    case userTypes.SET_LOADER:
      return {
        ...state,
        loader: action.loader
      }
    case userTypes.FETCH_ROLES_DATA:
      return {
        ...state,
        roles: action.roles,
        rolesCount: action.rolesCount
      }
    case userTypes.FETCH_USER_DETAIL_DATA:
      return {
        ...state,
        individualUserDetail: action.individualUserDetail
      }
    case userTypes.FETCH_ROLE_DETAIL_DATA:
      return {
        ...state,
        individualRoleDetail: action.individualRoleDetail
      }
    case userTypes.SET_ROLE_LIST_TABLE_PROPS:
      return {
        ...state,
        roleListTableProps: action.roleListTableProps
      }
    case userTypes.SET_USER_LIST_TABLE_PROPS:
      return {
        ...state,
        userListTableProps: action.userListTableProps
      }
    case userTypes.CLEAR_ROLE_LIST_DATA:
      return {
        ...state,
        roles: null,
        rolesCount: 0,
        roleListTableProps: {
          view: 'list',
          sortBy: '',
          order: 'ascending',
          pageSize: state.roleListTableProps?.pageSize || 15,
          pageNumber: state.roleListTableProps?.pageNumber || 1,
          searchTerm: ''
        }
      }
    case userTypes.CLEAR_ROLE_DETAIL_DATA:
      return {
        ...state,
        individualRoleDetail: null,
      }
    case userTypes.CLEAR_USER_LIST_DATA:
      return {
        ...state,
        users: null,
        userCount: 0,
        userListTableProps: {
          view: 'list',
          pageSize: state.userListTableProps?.pageSize || 15,
          pageNumber: state.userListTableProps?.pageNumber || 1,
          order: 'ascending',
          sortBy: '',
          searchTerm: ''
        },
      }
    case userTypes.CLEAR_USER_DETAIL_DATA:
      return {
        ...state,
        individualUserDetail: null,
        chargerListByCustomerByOrg: null
      }
    case userTypes.FETCH_CHARGERS_BY_CUSTOMER:
      return {
        ...state,
        chargerListByCustomer: action.chargerListByCustomer
      }
    case userTypes.FETCH_ORGS_DATA:
      return {
        ...state,
        orgs: action.orgs,
        orgsCount: action.orgsCount
      }
    case userTypes.SET_ORGS_LIST_TABLE_PROPS:
      return {
        ...state,
        orgListTableProps: action.orgListTableProps
      }
    case userTypes.CLEAR_ORG_LIST_DATA:
      return {
        ...state,
        orgs: null,
        orgsCount: 0,
        orgListTableProps: {
          view: 'list',
          pageSize: state.orgListTableProps?.pageSize || 15,
          pageNumber: state.orgListTableProps?.pageNumber || 1,
          order: 'ascending',
          sortBy: '',
          searchTerm: ''
        },
      }
    case userTypes.FETCH_CHARGERS_BY_CUSTOMER_BY_ORG:
      return {
        ...state,
        chargerListByCustomerByOrg: action.chargerListByCustomerByOrg
      }
    case userTypes.FETCH_ORG_DETAIL_DATA:
      return {
        ...state,
        individualOrgDetail: action.individualOrgDetail
      }
    case userTypes.CLEAR_ORG_DETAIL_DATA:
      return {
        ...state,
        individualOrgDetail: null
      }
    case userTypes.SET_USERS_FILTERS_APPLIED_FROM_REDIRECTION:
      return {
        ...state,
        userFiltersAppliedFromRedirection: action.userFiltersAppliedFromRedirection
      }
    default:
      return state
  }
}

export default usersReducer
