import { Options, Point, TooltipFormatterContextObject, } from 'highcharts';
import React, { ReactNode, useEffect, useMemo, useState, type FC } from 'react';
import { renderToString } from 'react-dom/server';
import { useNavigate } from 'react-router-dom';
import { RectangularSkeleton } from '../../globalUtils/SkeletonDesign/SkeletonDesign';
import { CustomHighCharts, useAppDispatch, useAppSelector, } from '../../globalUtils/globalExports';
import { Icon } from '../../globalUtils/globalIcons';
import { setRedirectedStateForChargerFilters } from '../../rmsReduxStore/chargersRedux/chargerCreators';
import { getDashboardReducer, setRedirectedStateForAlarmFilters } from '../../rmsReduxStore/reduxExports';
import './dashboard.css';
import { formatNumberWithCommas, useIsomorphicLayoutEffect } from '../../globalUtils/globalHooks';

interface IChargerStatusYAxisType {
  y: number;
  metaData: ICustomerWiseChargerHealthData;
}
interface TooltipInterface {
  data: TooltipFormatterContextObject;
  pointData: CustomPoint;
}
interface CustomPoint extends Point {
  metaData: ICustomerWiseChargerHealthData;
}
interface ChargerStatusSeries {
  totalChargers: IChargerStatusYAxisType[];
  healthyChargers: IChargerStatusYAxisType[];
  offlineChargers: IChargerStatusYAxisType[];
  faultyChargers: IChargerStatusYAxisType[];
  cmsConnectivityFailChargers: IChargerStatusYAxisType[];
}

const CustomerWiseChargerWorkingStatus: FC = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch()
  const { customerWiseChargerHealthData } = useAppSelector(getDashboardReducer);

  const [visibleMin, setVisibleMin] = useState(0);
  const [visibleMax, setVisibleMax] = useState(7);
  const [
    customerWiseChargerGraphCategories,
    setCustomerWiseChargerGraphCategories,
  ] = useState<string[]>([]);
  const [customerWiseChargerGraphSeries, setCustomerWiseChargerGraphSeries] =
    useState<ChargerStatusSeries>();

  useEffect(() => {

    const categories: string[] = [];

    const seriesData: ChargerStatusSeries = {
      totalChargers: [],
      offlineChargers: [],
      healthyChargers: [],
      faultyChargers: [],
      cmsConnectivityFailChargers: []
    };

    if (
      customerWiseChargerHealthData &&
      customerWiseChargerHealthData?.length > 0
    ) {
      customerWiseChargerHealthData?.map((customerData) => {
        categories?.push(customerData?.name);
        seriesData?.totalChargers?.push({
          y: customerData?.chargerStatus?.total,
          metaData: customerData,
        });
        seriesData?.offlineChargers?.push({
          y: customerData?.chargerStatus?.offline,
          metaData: customerData,
        });
        seriesData?.healthyChargers?.push({
          y: customerData?.chargerStatus?.healthy,
          metaData: customerData,
        });
        seriesData?.faultyChargers?.push({
          y: customerData?.chargerStatus?.faulty,
          metaData: customerData,
        });
        seriesData?.cmsConnectivityFailChargers?.push({
          y: customerData?.chargerStatus?.cmsConnectivityFail,
          metaData: customerData,
        });
      });
    }
    setVisibleMin(0);
    setVisibleMax(7);
    setCustomerWiseChargerGraphSeries(seriesData);
    setCustomerWiseChargerGraphCategories(categories);
  }, [customerWiseChargerHealthData]);

  const customerChartOptions: Options = useMemo(() => {
    return {
      chart: {
        type: 'column',
        animation: false,
        spacingLeft: 20,
        spacingRight: 20,
        events: {
          render: function (this: Highcharts.Chart): void {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            const chart = this as any;
            const existingArrows = chart.container.getElementsByClassName('custom-arrow');
            while (existingArrows.length > 0) {
              existingArrows[0].remove();
            }

            if (visibleMin > 0) {
              chart.renderer
                .image(
                  process.env.CUSTOMER_WISE_CHARGER_STATUS_LEFT_CHEVRON_ICON || 'https://exicomspinrmsstorage.blob.core.windows.net/exicom-spin-rms/LeftChevron.svg',  // Replace with the path to your left arrow SVG
                  chart.plotLeft - 10,
                  chart.plotHeight - (chart.plotHeight / 2) - 10,
                  20,
                  20
                )
                .addClass('custom-arrow left')
                .on('click', function (): void {
                  handleNavigation('left');
                })
                .add();
            }

            if (visibleMax < customerWiseChargerGraphCategories.length - 1) {
              chart.renderer
                .image(
                  process.env.CUSTOMER_WISE_CHARGER_STATUS_RIGHT_CHEVRON_ICON || 'https://exicomspinrmsstorage.blob.core.windows.net/exicom-spin-rms/RightChevron.svg',
                  chart.plotLeft + chart.plotWidth - 20,
                  chart.plotHeight - (chart.plotHeight / 2) - 10,
                  20,
                  20
                )
                .addClass('custom-arrow right')
                .on('click', function (): void {
                  handleNavigation('right');
                })
                .add();
            }
          },
          load: function (): void {
            this.reflow();
          }
        },
      },
      accessibility: {
        enabled: false
      },
      title: {
        text: undefined,
        align: 'left',
        verticalAlign: 'top',
        margin: 20,
      },
      credits: {
        enabled: false,
      },
      xAxis: {
        title: {
          text: '',
          style: {
            fontWeight: '600',
            color: 'black',

          }
        },
        categories: customerWiseChargerGraphCategories,
        crosshair: true,
        accessibility: {
          description: 'Charging Stations',
        },
        labels: {
          align: 'center',
          autoRotationLimit: 10,
        },

        min: visibleMin,
        max: customerWiseChargerGraphCategories?.length <= visibleMax ? null : visibleMax,
      },
      yAxis: {
        min: 0,
        tickInterval: 1,
        title: {
          text: 'Chargers',
          marginTop: 20,
          style: {
            fontWeight: '600',
            color: 'black'
          }
        },
        labels: {
          align: 'center',
          offset: 200,
        },
      },
      tooltip: {
        enabled: true,
        useHTML: true,
        // stickOnContact: true,
        backgroundColor: 'transparent',
        shadow: false,
        outside: true,
        formatter: function (this: TooltipFormatterContextObject): string {
          const pointData = this.point as CustomPoint;
          return renderToString(
            <GraphToolTip data={this} pointData={pointData} />
          );
        },
      },
      legend: {
        enabled: true,
        symbolHeight: 12,
        symbolWidth: 12,
        symbolRadius: 0,
        verticalAlign: 'bottom',
        padding: 20
      },

      series: customerWiseChargerGraphSeries && [
        {
          name: 'Healthy Chargers',
          data: customerWiseChargerGraphSeries?.healthyChargers,
          type: 'column',
          color: 'var( --green-color)',
          cursor: 'pointer',
          point: {
            events: {
              click: function (): void {
                const pointData = this as CustomPoint;
                const { id } = pointData.metaData;
                setRedirectedStateForChargerFilters(true, 'Healthy', id)(dispatch)
                  .then((res) => {
                    if (res === 'Action dispatched successfully') {

                      navigate('/manage-chargers', {
                        state: { chargerStatusId: 'Healthy' }
                      });
                    }
                  })
                  .catch((error) => {
                    console.error('Error dispatching action:', error); // Handle error
                  });

              },
            },
          }
        },
        {
          name: 'Offline Chargers',
          data: customerWiseChargerGraphSeries?.offlineChargers,
          type: 'column',
          color: 'var(--grey-color)',
          cursor: 'pointer',
          point: {
            events: {
              click: function (): void {
                const pointData = this as CustomPoint;
                const { id } = pointData.metaData;
                setRedirectedStateForChargerFilters(true, 'Offline', id)(dispatch)
                  .then((res) => {
                    if (res === 'Action dispatched successfully') {
                      navigate('/manage-chargers', {
                        state: { chargerStatusId: 'Offline' }
                      });
                    }
                  })
                  .catch((error) => {
                    console.error('Error dispatching action:', error); // Handle error
                  });


              },
            },
          }
        },
        {
          name: 'Faulty Chargers',
          data: customerWiseChargerGraphSeries?.faultyChargers,
          type: 'column',
          color: 'var(--critical-alarm-color)',
          cursor: 'pointer',
          point: {
            events: {
              click: function (): void {

                const pointData = this as CustomPoint;
                const { id } = pointData.metaData;
                setRedirectedStateForChargerFilters(true, 'Faulty', id)(dispatch)
                  .then((res) => {
                    if (res === 'Action dispatched successfully') {
                      navigate('/manage-chargers', {
                        state: { chargerStatusId: 'Faulty' }
                      });
                    }
                  })
                  .catch((error) => {
                    console.error('Error dispatching action:', error); // Handle error
                  });

              },
            },
          }

        },
        {
          name: 'CMS Offline Chargers',
          data: customerWiseChargerGraphSeries?.cmsConnectivityFailChargers,
          type: 'column',
          color: 'var(--cms-connectivity-fail-color)',
          cursor: 'pointer',
          point: {
            events: {
              click: function (): void {
                const pointData = this as CustomPoint;
                const { id } = pointData.metaData;
                setRedirectedStateForAlarmFilters(true, null, 'CMS Comm Fail', id)(dispatch)
                  .then((res) => {
                    if (res === 'Action dispatched successfully') {

                      navigate('/alarms', {
                        state: { alarmType: 'CMS Comm Fail' }
                      });
                    }
                  })
                  .catch((error) => {
                    console.error('Error dispatching action:', error); // Handle error
                  });

              },
            },
          }
        },
      ],
    };
  }, [customerWiseChargerGraphCategories, customerWiseChargerGraphSeries, visibleMax, visibleMin]);

  const handleNavigation = (direction: 'left' | 'right'): void => {
    const step = 7;
    if (direction === 'left') {
      setVisibleMin(Math.max(visibleMin - step, 0));
      setVisibleMax(Math.max(visibleMax - step, 7));
    } else if (direction === 'right') {
      setVisibleMin(Math.min(visibleMin + step, customerWiseChargerGraphCategories.length - 8));
      setVisibleMax(Math.min(visibleMax + step, customerWiseChargerGraphCategories.length - 1));
    }
  };

  return (
    <div className="charger__working__status__container">
      <div className="charger__working__status__wrap">
        <div className="charger__working__status-header-wrap">
          <div className="charger__working__status-header">
            Customer-Wise Charger Working Status
          </div>
        </div>

        <div className="charger__working__status-graph-wrap">
          {!customerWiseChargerHealthData ? (
            <RectangularSkeleton skeletonContainerClassName="charger__working__status-graph-skeleton" />
          ) : (
            <CustomHighCharts highChartOptions={customerChartOptions} />
          )}
        </div>
      </div>
    </div>
  );
};

const GraphToolTip = ({ pointData }: TooltipInterface): ReactNode => {
  const metaData = pointData?.metaData;
  const headerText = metaData?.name;
  const totalChargers = metaData?.chargerStatus?.total;
  const chargerData = metaData?.chargerStatus;

  const tooltipChargerIconMapper = {
    healthy: 'graph__tooltip__online__icon',
    offline: 'graph__tooltip__offline__icon',
    faulty: 'graph__tooltip__faulty__icon',
    cmsConnectivityFail: 'graph__tooltip__cms__connectivity__fail__icon'
  };

  // TODO: Fix for -> because of renderToStatic Markup
  // useLayoutEffect does nothing on the server, because its effect cannot be encoded into the server renderer's output format. This will lead to a mismatch between the initial, non-hydrated UI and the intended UI. To avoid this, useLayoutEffect should only be used in components that render exclusively on the client
  useEffect(() => {
    if (typeof document === 'undefined') {
      React.useLayoutEffect = React.useEffect;
    }
  }, [])
  useIsomorphicLayoutEffect(() => { })

  return (
    <div className="graph__tooltip__container customer__wise__charger__graph__tooltip">
      <div className="graph__tooltip__header">{headerText}</div>
      <div className="graph__tooltip__total__count">
        Chargers: {formatNumberWithCommas(totalChargers) || 0}
      </div>
      <div className="graph__tooltip__total___content">
        {chargerData &&
          Object.keys(chargerData)?.length > 0 &&
          Object.keys(chargerData)?.map((charger, index) => {
            if (charger !== 'total')
              return (
                <div
                  key={index}
                  className="graph__tooltip__total___content-row"
                >
                  <div className="graph__tooltip__total___content-left">
                    <div className="graph__tooltip__total___content-icon-wrapper">
                      <Icon
                        containerClassName="graph__tooltip__total___content-icon"
                        iconClassName={tooltipChargerIconMapper[charger]}
                      />
                    </div>
                    <div className="graph__tooltip__total___content-charger__text">
                      {charger === 'cmsConnectivityFail' ? 'CMS Offline' : charger}
                    </div>
                  </div>

                  <div className="graph__tooltip__total___content-customer__text">
                    {formatNumberWithCommas(chargerData[charger])}
                  </div>
                </div>
              );
          })}
      </div>
    </div>
  );
};

export default CustomerWiseChargerWorkingStatus;
