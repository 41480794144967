import access from '../../auth/service/AccessControl'

export const navList = [
  {
    id: 'info_graphics',
    text: 'Infographics',
    isAccessible: access?.chargers?.view()
  },
  {
    id: 'status',
    text: 'Status',
    isAccessible: access?.chargers?.status?.view()
  },
  {
    id: 'settings',
    text: 'Settings',
    isAccessible: access?.chargers?.settings?.view()
  },
  {
    id: 'remote_commands',
    text: 'Remote Commands',
    isAccessible: access?.chargers?.remoteCommands?.view()
  },
  {
    id: 'system_info',
    text: 'System Info',
    isAccessible: access?.chargers?.systemInfo?.view()
  },
  {
    id: 'alarm_history',
    text: 'Alarms',
    isAccessible: access?.alarms?.view()
  },
  {
    id: 'admin_actions',
    text: 'Admin Actions',
    isAccessible: access?.chargers?.admin?.view()
  }
]

// export const remoteCommandRadioButtons = [
//   {
//     radioButtonId: 'connector1',
//     radioButtonLabel: 'Connector 1'
//   },
//   {
//     radioButtonId: 'connector2',
//     radioButtonLabel: 'Connector 2'
//   },
//   {
//     radioButtonId: 'All Connector',
//     radioButtonLabel: 'All Connector'
//   }
// ]

export const settingsNavList = [
  {
    buttonText: 'All',
    buttonIconClassName: 'charger__panel__settings__all__icon',
    buttonId: 'all'
  },

  {
    buttonText: 'Charger System',
    buttonIconClassName: 'charger__panel__charger__system__icon',
    buttonId: 'charger-system'
  },

  {
    buttonText: 'CCU-Group',
    buttonIconClassName: 'charger__panel__ccu__group__icon',
    buttonId: 'ccu-group'
  },

  {
    buttonText: 'Charger/CCU Specific Settings',
    buttonIconClassName: 'charger__panel_settings__icon',
    buttonId: 'specific-settings'
  },
  {
    buttonText: 'Network Config',
    buttonIconClassName: 'charger__panel__network__config__icon',
    buttonId: 'network-config'
  },

  {
    buttonText: 'Backend Config',
    buttonIconClassName: 'charger__panel__backend__config__icon',
    buttonId: 'backend-config'
  },

  {
    buttonText: 'Authentication Config',
    buttonIconClassName: 'charger__panel__authentication__config__icon',
    buttonId: 'authentication-config'
  },

  {
    buttonText: 'OCPP1.6-Configs',
    buttonIconClassName: 'charger__panel__ocpp__config__icon',
    buttonId: 'ocpp-configs'
  },

  {
    buttonText: 'HMI/Web Login Management',
    buttonIconClassName: 'charger__panel__web__login__mgt__icon',
    buttonId: 'hmi-web-login-management'
  }

]

export const broadcastMessageDevice = [
  {
    radioButtonId: 'all',
    radioButtonLabel: 'All'
  },
  {
    radioButtonId: 'device',
    radioButtonLabel: 'Device'
  }
]

