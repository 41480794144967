import React, { FC, useCallback, useEffect, useMemo, useState } from 'react'
import {
  CustomDatePicker,
  SelectDropDown,
  useAppDispatch,
  useAppSelector,
} from '../../../../globalUtils/globalExports'
import ChargerStatusGraph from './ChargerStatusGraph';
import ChargerStatusLogsGraph from './ChargerStatusLogsGraph';
import {
  chargerTypes,
  fetchChargerConnectorStatusLogs,
  fetchChargerGraphDetail,
  getChargerReducer,
  getGlobalReducer,
  getReferenceDataReducer,
  setGlobalFilterData,
  setGlobalFilterStateData
} from '../../../../rmsReduxStore/reduxExports';
import { cloneDeep } from 'lodash';
import {
  ChargerHistoryGraphSkeleton,
  ChargerHistoryLogsSkeleton
} from '../../../../globalUtils/SkeletonDesign/SkeletonDesign';
import { useLocation } from 'react-router-dom';

interface IntervalData {
  y: number;
  name: string;
  x: number;
  x2: number;
  color: string;
}


// TODO: Main file for History
export default function ChargerStatusHistory(): JSX.Element {
  const { chargerHistoryGraphData, chargerConnectorStatusLogs } = useAppSelector(getChargerReducer);
  const [timeSeriesConnectorData, setTimeSeriesConnectorData] = useState<[number, number][][] | null>(null)
  const [connectorStatusLogsData, setConnectorStatusLogsData] = useState<IntervalData[][] | null>(null)
  const [categories, setCategories] = useState<string[] | null>(null)
  const [historyGraphLegends,setHistoryGraphLegends] = useState<string[] | null>(null)
  const [connectorColors,setConnectorColors] = useState<string[]>(['var(--charger-status-idle-dark-color)','var(--charger-status-blue-dark-color)', 'var(--charger-status-charging-dark-color)','var(--charger-status-fourth-dark-color)'])
  const connectorStatusLogsColorMapper = {
    'Charging': 'var(--session-log-charging-color)',
    'PluggedOut': 'var(--session-log-pluggedOut-color)',
    'PluggedIn': 'var(--session-log-pluggedIn-color)'
  }
  // TODO: Plot data points for series graph
  const calculateDataPointsForConnector = useCallback(() => {
    const graphDetails: [number, number][][] = []
    const legends: string[] = []
    const connectorColors:string[] = []
    if (chargerHistoryGraphData && chargerHistoryGraphData?.data?.length > 0) {
      chargerHistoryGraphData?.data && chargerHistoryGraphData?.data?.forEach(data => {
        legends?.push(data?.connectorName)
        connectorColors?.push(data?.connectorColor)
        if (data && data?.history?.length > 0) {
          const newData: [number, number][] = data?.history?.map(detail => {
            return [new Date(detail?.dateTime)?.getTime(), detail?.value]
          })
          graphDetails?.push(newData);
        }
      })
    }
    setTimeSeriesConnectorData(graphDetails)
    setHistoryGraphLegends(legends)
    setConnectorColors(connectorColors)
  }, [chargerHistoryGraphData])




  // TODO: Plot data points for xrange graph
  const adjustIntervals = useCallback((dataintervals: IntervalData[][], rangeStartTime: string, rangeEndTime: string): IntervalData[][] => {
    const adjustedIntervalsData: IntervalData[][] = [];
    dataintervals?.forEach(intervals => {
      const adjustedIntervals: IntervalData[] = [];
      if (intervals[0]?.x != Date.parse(rangeStartTime)) {
        adjustedIntervals.push({
          y: intervals[0]?.y,
          name: intervals[0]?.name,
          x: Date.parse(rangeStartTime),
          x2: intervals[0]?.x,
          color: 'var(--charger-status-idle-light-color)',
        })
      }
      for (let i = 0; i < intervals.length - 1; i++) {
        adjustedIntervals.push(intervals[i]);
        const currentX2 = intervals[i]?.x2;
        const nextX = intervals[i + 1]?.x;

        if (currentX2 !== nextX) {
          const insertIndex = adjustedIntervals.length;
          adjustedIntervals.splice(insertIndex, 0, {
            y: intervals[i]?.y,
            name: intervals[i]?.name,
            x: currentX2,
            x2: nextX,
            color: 'var(--charger-status-idle-light-color)',
          });
        }
      }
      adjustedIntervals.push(intervals[intervals.length - 1]);
      if (intervals[intervals.length - 1]?.x2 != Date.parse(rangeEndTime)) {
        adjustedIntervals.push({
          y: intervals[intervals.length - 1]?.y,
          name: intervals[intervals.length - 1]?.name,
          x: intervals[intervals.length - 1]?.x2,
          x2: Date.parse(rangeEndTime),
          color: 'var(--charger-status-idle-light-color)',
        })
      }
      adjustedIntervalsData.push(adjustedIntervals);
    });
    return adjustedIntervalsData;
  }, []);

  const calculateDataPointsForStatusLogs = useCallback(() => {
    const data: IntervalData[][] = [];
    const categories: string[] = [];
    if (chargerConnectorStatusLogs && chargerConnectorStatusLogs?.connectorData?.length > 0) {
      const statusLog = chargerConnectorStatusLogs?.connectorData?.map((element, index) => {
        if (element?.sessionLogs?.length === 0) {
          categories?.push('C' + (index + 1))
          return [{
            y: chargerConnectorStatusLogs?.connectorData?.length - index - 1,
            name: element?.connectorName,
            x: Date.parse(chargerConnectorStatusLogs?.startDateTime),
            x2: Date.parse(chargerConnectorStatusLogs?.endDateTime),
            color:'var(--charger-status-idle-light-color)'
          }]
        }
        const log = element?.sessionLogs?.map((sessionLog) => {
          return {
            y: chargerConnectorStatusLogs?.connectorData?.length - index - 1,
            name: element?.connectorName,
            x: Date.parse(sessionLog?.startTime),
            x2: Date.parse(sessionLog?.endTime),
            color: connectorStatusLogsColorMapper[sessionLog?.type] ?? 'var(--charger-status-idle-light-color)'
          }
        })
        categories?.push('C' + (index + 1))
        return log
      });
      const modifiedData = adjustIntervals(statusLog, chargerConnectorStatusLogs?.startDateTime, chargerConnectorStatusLogs?.endDateTime)
      data?.push(...modifiedData)
    }
    setCategories(categories?.reverse())
    setConnectorStatusLogsData(data)
  }, [chargerConnectorStatusLogs])


  useEffect(() => {
    calculateDataPointsForStatusLogs()
  }, [chargerConnectorStatusLogs])

  useEffect(() => {
    calculateDataPointsForConnector();
  }, [chargerHistoryGraphData])

  return (
    <div className='charger__status__history__dashboard'>
      {chargerHistoryGraphData && timeSeriesConnectorData ? <><ChargerStatusHistoryHeader /><ChargerStatusGraph
        timeSeriesConnectorData={timeSeriesConnectorData} historyGraphLegends={historyGraphLegends} graphColorsList={connectorColors} startDateTime={chargerHistoryGraphData?.startDateTime} endDateTime={chargerHistoryGraphData?.endDateTime} unit={chargerHistoryGraphData?.unit} /> </> :
        <ChargerHistoryGraphSkeleton />}
      {chargerConnectorStatusLogs && connectorStatusLogsData && categories ?
        <ChargerStatusLogsGraph connectorStatusLogsData={connectorStatusLogsData} categories={categories} /> : <ChargerHistoryLogsSkeleton />}
    </div>
  )
}

// TODO: Header with dropdowns 
const ChargerStatusHistoryHeader: FC = () => {
  const location = useLocation();
  const dispatch = useAppDispatch();
  const { chargerHistoryTypeReferenceData } = useAppSelector(getReferenceDataReducer);
  const { globalFiltersWithObjects, globalFiltersWithOnlyIds } = useAppSelector(getGlobalReducer);
  const { chargerHistoryStartDate, chargerHistoryEndDate } = useAppSelector(getChargerReducer);


  const onDateChange = useCallback(async (dates): Promise<void> => {
    const [start, end] = dates;
    //: Partial<ChargerAction>
    const action = {
      type: chargerTypes.SET_CHARGER_HISTORY_DATES,
      chargerHistoryStartDate: start,
      chargerHistoryEndDate: end
    }
    await dispatch(action);

    if (start && end) {
      await handleGraphDropdownChange({
        id: 'custom',
        label: 'Custom',
        value: 'Custom',
        name: 'Custom'
      }, 'historyInterval', start, end)
    }

  }, [])

  // TODO: Handle filter Value
  const handleGraphDropdownChange = useCallback(async (val, filterKey, start?: Date, end?: Date) => {

    const globalFilterState: IFilterStateData[] = cloneDeep(globalFiltersWithObjects);
    const filterData: IScreenFilter[] = cloneDeep(globalFiltersWithOnlyIds);
    const globalStateScreenFilter = globalFilterState?.find(
      (filter) => filter?.screen === 'chargerPanel'
    );
    const selectedScreenFilter = filterData?.find(
      (filter) => filter?.screen === 'chargerPanel'
    );
    const selectedFilter = selectedScreenFilter?.filterPreference?.find(
      (filter) => filter?.key === filterKey
    );
    const selectedGlobalStateScreenFilter = globalStateScreenFilter?.filterStateValues?.find(
      (filter) => filter?.key === filterKey
    );

    const values = val && val?.id
    if (selectedFilter) {
      if (filterKey === 'historyInterval' && values === 'custom' && start && end) {
        selectedFilter.values = [values, start?.toISOString(), end?.toISOString()] ?? []
      } else {
        selectedFilter.values = [values] ?? []
      }
    }
    if (selectedGlobalStateScreenFilter) {
      selectedGlobalStateScreenFilter.value = [val]
    }

    if (filterKey === 'historyInterval' && val?.id === 'custom' && !start && !end) {
      await dispatch(setGlobalFilterData(filterData));
      await dispatch(setGlobalFilterStateData(globalFilterState));
      return
    }

    const { chargerPanelfilters } = await dispatch(fetchChargerGraphDetail({
      chargerId: location?.state?.chargerId,
      filters: selectedScreenFilter?.filterPreference
    }))
    if (filterKey === 'historyInterval') {
    dispatch(fetchChargerConnectorStatusLogs({ 
      chargerId: location?.state?.chargerId, 
      filters: selectedScreenFilter?.filterPreference }))
    }
    const { historyInterval, historyType, statInterval, statType, startDate, endDate } = chargerPanelfilters;
    if (historyInterval === 'custom') {
      //: Partial<ChargerAction>
      const action = {
        type: chargerTypes.SET_CHARGER_HISTORY_DATES,
        chargerHistoryStartDate: startDate ? new Date(startDate) : new Date(),
        chargerHistoryEndDate: endDate ? new Date(endDate) : null
      }
      dispatch(action);
    }
    if (chargerHistoryTypeReferenceData && chargerHistoryTypeReferenceData?.length > 0) {
      const selectedHistoryType = chargerHistoryTypeReferenceData?.find(item => item?.id === historyType);
      const selectedStatInterval = selectedHistoryType?.statInterval?.find(item => item?.id === statInterval);
      const selectedHistoryInterval = selectedHistoryType?.historyInterval?.find(item => item?.id === historyInterval);
      const selectedStatType = selectedHistoryType?.statType?.find(item => item?.id === statType);

      const updateKeys = ['statInterval', 'historyInterval', 'statType'];
      updateKeys?.forEach(updateKey => {
        const selectedValue = selectedScreenFilter?.filterPreference?.find(v => v.key === updateKey)?.values;
        const selectedStateValue = globalStateScreenFilter?.filterStateValues?.find(v => v.key === updateKey)?.value;
        if (selectedValue) {
          selectedValue[0] =
            updateKey === 'statInterval' && selectedStatInterval ? selectedStatInterval.id as string :
              updateKey === 'historyInterval' && selectedHistoryInterval ? selectedHistoryInterval.id as string :
                updateKey === 'statType' && selectedStatType ? selectedStatType.id as string :
                  chargerHistoryTypeReferenceData?.[0]?.[updateKey]?.[0]?.id;
        }
        if (selectedStateValue) {
          selectedStateValue[0] = updateKey === 'statInterval' && selectedStatInterval ? selectedStatInterval :
            updateKey === 'historyInterval' && selectedHistoryInterval ? selectedHistoryInterval :
              updateKey === 'statType' && selectedStatType ? selectedStatType :
                chargerHistoryTypeReferenceData?.[0]?.[updateKey]?.[0]; //
        }
      });

    }
    await dispatch(setGlobalFilterData(filterData));
    await dispatch(setGlobalFilterStateData(globalFilterState));
  }, [globalFiltersWithObjects, globalFiltersWithOnlyIds,])


  const graphFilterProps = useMemo(() => {
    const stateData = cloneDeep(globalFiltersWithObjects);
    const filterStateData = stateData?.find(filter => filter?.screen === 'chargerPanel')?.filterStateValues
    const selectedChargerHistoryTypeValue = filterStateData?.find(filter => filter?.key === 'historyType')?.value;
    const selectedChargerStatValue = filterStateData?.find(filter => filter?.key === 'statType')?.value;
    const selectedChargerStatIntervalValue = filterStateData?.find(filter => filter?.key === 'historyInterval')?.value;
    const selectedChargerIntervalValue = filterStateData?.find(filter => filter?.key === 'statInterval')?.value;
    const selectedHistoryTypeValue: IChargerHistoryTypeRefData = selectedChargerHistoryTypeValue?.[0] as IChargerHistoryTypeRefData;

    const statOptions = selectedHistoryTypeValue?.statType ?? [];
    const statIntervalOptions = selectedHistoryTypeValue?.statInterval ?? [];
    const historyIntervalOptions = selectedHistoryTypeValue?.historyInterval ?? [];
    return [
      {
        selectValue: selectedChargerIntervalValue,
        selectOptions: statIntervalOptions ?? [],
        handleSelectOptionChange: (val): void => {
          handleGraphDropdownChange(val, 'statInterval')
        },
        selectDropDownId: 'charger-charger-historyInterval-dropdown ',
        dropDownClassName: 'charger__charger__interval__dropdown',
        isMultiSelect: false
      },
      {
        selectValue: selectedChargerStatValue,
        selectOptions: statOptions ?? [],
        handleSelectOptionChange: (val): void => {
          handleGraphDropdownChange(val, 'statType')
        },
        selectDropDownId: 'charger-stat-type-dropdown ',
        dropDownClassName: 'charger__stat__type__dropdown',
        isMultiSelect: false
      },

      {
        selectValue: selectedChargerStatValue,
        selectOptions: statOptions ?? [],
        handleSelectOptionChange: (val): void => {
          handleGraphDropdownChange(val, 'statType')
        },
        selectDropDownId: 'charger_stat-type-break',
        dropDownClassName: 'charger__stat__type__dropdown',
        isMultiSelect: false
      },
      {
        selectValue: selectedChargerStatIntervalValue,
        selectOptions: historyIntervalOptions ?? [],
        handleSelectOptionChange: (val): void => {
          handleGraphDropdownChange(val, 'historyInterval')
        },
        selectDropDownId: 'charger-charger-historyInterval-dropdown ',
        dropDownClassName: 'charger__charger__interval__dropdown',
        isMultiSelect: false
      },
    ]
  }, [chargerHistoryTypeReferenceData, globalFiltersWithObjects, handleGraphDropdownChange])

  return (
    <div className='charger__status__history__header__wrap'>
      <div className='charger__status__history__header__wrap-top'>
        <div className='charger__status__history__header__wrap-left'>
          <div className='charger__status__history__header-text'>
            History
          </div>

          <SelectDropDown
            selectValue={globalFiltersWithObjects?.find(filter => filter?.screen === 'chargerPanel')?.filterStateValues?.find(filter => filter?.key === 'historyType')?.value}
            selectOptions={chargerHistoryTypeReferenceData ?? []}
            handleSelectOptionChange={(val): void => {
              handleGraphDropdownChange(val, 'historyType')
            }}
            selectDropDownId='charger-history-type-dropdown'
            dropDownClassName='charger__history__type__dropdown'
            isMultiSelect={false}
          />

        </div>
        <div className='charger__status__history__header__wrap-right'>
          <span className='charger__status__history__header-text reduced-font-size'>
            Interval
          </span>
          {graphFilterProps && graphFilterProps?.length > 0 && graphFilterProps?.map((item, index) => {
            if (item.selectDropDownId === 'charger_stat-type-break') {
              return (<div key={index} className='charger_stat-type-break'></div>)
            }
            return (
              <SelectDropDown
                key={'dropdown' + index}
                selectValue={item?.selectValue}
                selectOptions={item?.selectOptions}
                handleSelectOptionChange={item?.handleSelectOptionChange}
                selectDropDownId={item?.selectDropDownId}
                dropDownClassName={item?.dropDownClassName}
                isMultiSelect={item?.isMultiSelect}
              />
            )
          }
          )}
        </div>

      </div>
      {globalFiltersWithObjects && globalFiltersWithObjects?.length > 0 &&
        globalFiltersWithObjects?.find(filter => filter?.screen === 'chargerPanel')?.filterStateValues?.find(filter => filter?.key === 'historyInterval')?.value?.[0]?.id === 'custom' &&
        <CustomDatePicker startDate={new Date(chargerHistoryStartDate)} endDate={chargerHistoryEndDate ? new Date(chargerHistoryEndDate) : null} selectsRange={true} onChange={onDateChange} />}
    </div>
  )
}


