/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { type FC } from 'react';
import { type GridRenderCellParams, GridValidRowModel } from '@mui/x-data-grid-pro';
import React, { memo, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  alarmCardMapper,
  CustomTooltipWithLabel,
  downloadFile,
  FilterProp,
  LinkButton,
  renderHeaderActionButtons,
  TableDashboard,
  useAppDispatch,
  useAppSelector,
} from '../../../globalUtils/globalExports';
import {
  chargerTypes,
  fetchChargersData,
  getChargerReducer,
  getGlobalReducer,
  getReferenceDataReducer,
  setGlobalFilterData,
} from '../../../rmsReduxStore/reduxExports';
import {
  exportToCsvForChargerList,
  setChargerListTableProps,
  setRedirectedStateForChargerFilters,
  setSelectedChargerData,
  setBroadcastMessageTableProps,
  fetchBroadcastMessages
} from '../../../rmsReduxStore/chargersRedux/chargerCreators';
import { Id, toast } from 'react-toastify';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { cloneDeep } from 'lodash';
import PropTypes from 'prop-types';
import { ActionIcons, IconWithTooltip } from '../../../globalUtils/globalIcons';
import { NoDataComponentChargers } from '../../../globalUtils/TableDashboard/TableComponents';
import CreateBroadcastMessage from './CreateBroadcastMessage';
import BroadcastMessageDetail from './BroadcastMessageDetail';

const ChargerStatusCardType = {
  messageStatusId: PropTypes.string,
  messageStatus: PropTypes.string.isRequired
}

type ChargerStatusCardPropType = PropTypes.InferProps<
  typeof ChargerStatusCardType
>

const BroadcastMessageDashboard = memo(function BroadcastMessageDashboard() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const [exportAnchorEl, setExportAnchorEl] = useState(null);
  const [exportingFile, setExportingFile] = useState(false);
  const { globalFiltersWithOnlyIds, globalFiltersWithObjects } = useAppSelector(getGlobalReducer);
  const { chargerBroadcastMessages, broadcastMessagesCount, broadcastMessageTableLoader, chargerBroadcastMessageTableProps, chargerFiltersAppliedFromRedirection } = useAppSelector(getChargerReducer);
  const globalFiltersWithOnlyIdsRef = useRef(globalFiltersWithOnlyIds);
  const BroadcastMessageTablePropsRef = useRef(chargerBroadcastMessageTableProps);
  const chargerBroadcastMessagesRef = useRef(chargerBroadcastMessages);
  const layouts = ['list'];
  const [layoutView, setLayoutView] = useState<string>('list');

  const getTableRows = useCallback((): ChargerBroadcast[] => {
    return chargerBroadcastMessages !== null && chargerBroadcastMessages?.length > 0 ? chargerBroadcastMessages : [];
  }, [chargerBroadcastMessages]);

  const MessageStatusComponent: FC<ChargerStatusCardPropType> = (
    props
  ) => {
    const { messageStatus } = props

    return (
      <>
        <div
          className={
            messageStatus === 'COMPLETED'
              ? ' rms__table__status__wrap  online'
              : messageStatus === 'SCHEDULED'
                ? 'rms__table__status__wrap  offline'
                : ''
          }
        >
          {messageStatus === 'COMPLETED' && (
            <div className='rms__icon status__online__charger__icon'></div>
          )}
          {messageStatus === 'SCHEDULED' && (
            <div className='rms__icon  status__offline_charger__icon'></div>
          )}
          <div className='rms__table__status-text'>{messageStatus}</div>
        </div>
      </>
    )
  };

  const onEditIconClick = useCallback(() => {
      navigate('/broadcast-message/edit');
  }, [navigate]);

  const onViewDetailClick = useCallback(
    () => {
      navigate('/broadcast-message/detail')
    }, [navigate])
  
  const actionIconsList = useMemo(() => {
    return [
    { icon: 'viewIcon',   action: onViewDetailClick }, 
    { icon: 'pencilIcon', action: onEditIconClick }
  ];
  }, [onEditIconClick, onViewDetailClick]);

  const chargerColumns = useMemo(() => {
    return [
      {
        field: 'recipient',
        headerName: 'Recipient',
        minWidth: 150,
        editable: false,
        flex: 1,
      },
      {
        field: 'message',
        headerName: 'Message',
        minWidth: 150,
        editable: false,
        flex: 1,
      },
      {
        field: 'createdBy',
        headerName: 'Created By',
        flex: 1,
        sortable: true,
        minWidth: 100,
      },
      {
        field: 'createdAt',
        headerName: 'Created On',
        flex: 1,
        sortable: true,
        minWidth: 160,
      },
      {
        field: 'messageStatus',
        headerName: 'Status',
        minWidth: 90,
        flex: 0.8,
        editable: false,
        renderCell: (params: GridRenderCellParams<GridValidRowModel, Date>): JSX.Element => {
          return <MessageStatusComponent messageStatus={params?.row?.messageStatus} />;
        },
      },
      {
        field: 'View',
        headerName: 'View',
        flex: 1,
        sortable: false,
        minWidth: 50,
        maxWidth: 100,
        headerAlign: 'center',
        renderCell: (params: GridRenderCellParams<GridValidRowModel, Date>): JSX.Element => {
          const rowData = params?.row;
          return (
            <div className="charging__station__action__icons__wrap">
              <ActionIcons actionIconsList={actionIconsList} data={rowData} />
            </div>
          );
        },
      },
    ];
  }, [chargerBroadcastMessageTableProps]);

  const handleClose = (): void => {
    setExportAnchorEl(null);
  };

  const fileDownloadSuccessToast = useCallback((): Id => {
    setExportingFile(false);
    return toast.success('File downloaded.');
  }, []);

  const fileDownloadFailureToast = useCallback((): Id => {
    setExportingFile(false);
    return toast.warn('Something went wrong. Please export again.');
  }, []);

  const onExportBtnClick = useCallback(async (fileType: 'csv' | 'xlsx'): Promise<void> => {
    if (exportingFile) {
      // If already exporting, prevent another export
      return;
    }
    try {
      const filtersData: IScreenFilter[] = cloneDeep(globalFiltersWithOnlyIds)
      const selectedScreenFilter = filtersData?.find(
        (filter) => filter?.screen === 'chargers'
      );
      const response = await dispatch(exportToCsvForChargerList({
        fileType: fileType,
        filters: selectedScreenFilter?.filterPreference
      }));
      if (response?.status === 202 || response?.status === 200) {
        downloadFile({ url: response?.data?.url });
        fileDownloadSuccessToast()
      } else {
        fileDownloadFailureToast();
        setExportAnchorEl(null);
      }
    } finally {
      // Ensure that exporting status is updated even if there's an error
      setExportingFile(false);
      setExportAnchorEl(null);
    }
  }, [globalFiltersWithOnlyIds, exportingFile, fileDownloadSuccessToast, fileDownloadFailureToast])

  const onChangeOfSearchText = useCallback(async (val) => {
    if (BroadcastMessageTablePropsRef?.current) {
      await layoutCallback(1, BroadcastMessageTablePropsRef?.current?.pageSize, BroadcastMessageTablePropsRef?.current?.view, BroadcastMessageTablePropsRef?.current?.sortBy, BroadcastMessageTablePropsRef?.current?.order, null, val)
    }
  }, [BroadcastMessageTablePropsRef?.current, chargerBroadcastMessagesRef?.current]);

  const searchBoxConfiguration = useMemo(() => {
    return {
      searchFieldId: 'manage-chargers-search-box',
      searchFieldName: 'manage-chargers-search-box',
      handleSearch: onChangeOfSearchText,
    };
  }, [onChangeOfSearchText]);

  const layoutCallback = useCallback(async (pageNumber: number, pageSize: number, view: string, field: string, order: string, filterData, searchTerm) => {
    const filtersData: IScreenFilter[] = globalFiltersWithOnlyIdsRef.current
    const tableProps: IChargerListTableProps = cloneDeep(BroadcastMessageTablePropsRef.current)
    const selectedScreenFilter = filtersData?.find(
      (filter) => filter?.screen === 'chargers'
    );
    await dispatch(fetchBroadcastMessages({
      view: view,
      sortBy: field || '',
      order: order || '',
      pageSize,
      pageNumber,
      filters: filterData ? filterData : selectedScreenFilter?.filterPreference,
      searchTerm: searchTerm != null && searchTerm != undefined ? searchTerm : BroadcastMessageTablePropsRef?.current?.searchTerm
    }));
    if (tableProps) {
      tableProps.view = view,
      tableProps.sortBy = field || ''
      tableProps.order = order || '';
      tableProps.pageNumber = pageNumber;
      tableProps.pageSize = pageSize;
      if (searchTerm != null && searchTerm != undefined) {
        tableProps.searchTerm = searchTerm;
      }
    }
    await dispatch(setBroadcastMessageTableProps(tableProps))
  }, [globalFiltersWithOnlyIdsRef.current, BroadcastMessageTablePropsRef?.current, dispatch]);


  // ************************************************************************************************
  // TODO: Filter side pop up 
  // onchange -> handles onchange of all the dropdowns, layout props -> pass the required dropdowns, footer button action
  // const handleFilterDataChange = useCallback(async (val, filterKey) => {
  //   const globalFilterState: IFilterStateData[] = cloneDeep(globalFiltersWithObjects);
  //   const filterData: IScreenFilter[] = cloneDeep(globalFiltersWithOnlyIds);
  //   const globalStateScreenFilter = globalFilterState?.find(
  //     (filter) => filter?.screen === 'chargers'
  //   );
  //   const selectedScreenFilter = filterData?.find(
  //     (filter) => filter?.screen === 'chargers'
  //   );
  //   const selectedFilter = selectedScreenFilter?.filterPreference?.find(
  //     (filter) => filter?.key === filterKey
  //   );
  //   const selectedGlobalStateScreenFilter = globalStateScreenFilter?.filterStateValues?.find(
  //     (filter) => filter?.key === filterKey
  //   );


  //   if (selectedFilter) {
  //     if (val && val?.length > 0) {
  //       selectedFilter.values = val?.map(item => item?.id)
  //     } else {
  //       selectedFilter.values = []
  //     }
  //   }

  //   if (selectedGlobalStateScreenFilter) {
  //     selectedGlobalStateScreenFilter.value = val
  //   }
  //   await dispatch(setGlobalFilterData(filterData));
  //   await dispatch(setGlobalFilterStateData(globalFilterState));

  //   if (BroadcastMessageTablePropsRef?.current) {
  //     await layoutCallback(1, BroadcastMessageTablePropsRef?.current?.pageSize, BroadcastMessageTablePropsRef?.current?.view, BroadcastMessageTablePropsRef?.current?.sortBy, BroadcastMessageTablePropsRef?.current?.order, selectedScreenFilter?.filterPreference, BroadcastMessageTablePropsRef?.current?.searchTerm)
  //   }
  //   await refreshMapDataPoints(chargerBroadcastMessagesRef.current)
  //   if (layoutView === 'map') {
  //     await refreshMapDataPoints(chargerBroadcastMessagesRef.current)
  //   }
  // }, [globalFiltersWithOnlyIds, BroadcastMessageTablePropsRef?.current, globalFiltersWithObjects, dispatch, chargerBroadcastMessagesRef?.current])

  // const chargerFilterProps: FilterProp[] = useMemo(() => {
  //   const globalFilterState = cloneDeep(globalFiltersWithObjects);
  //   const filterStateData = globalFilterState?.find(filter => filter?.screen === 'chargers')?.filterStateValues
  //   const filterLocationValue = filterStateData?.find(filter => filter?.key === 'location')?.value;
  //   const filterChargerStatusValue = filterStateData?.find(filter => filter?.key === 'chargerStatus')?.value;
  //   const softwareVersionValue = filterStateData?.find(filter => filter?.key === 'softwareVersion')?.value;
  //   const filteredCategoryValue = filterStateData?.find(filter => filter?.key === 'alarmStatus')?.value;
  //   return [
  //     {
  //       filterLabel: 'Location',
  //       filterType: 'dropdown',
  //       filterId: 'Location',
  //       filterDropdownProps: {
  //         selectValue: filterLocationValue,
  //         selectOptions: chargerLocationReferenceData ?? [],
  //         handleSelectOptionChange: (val): void => {
  //           handleFilterDataChange(val, 'location')
  //         },
  //         selectDropDownId: 'charger-location-filter-dropdown',
  //         isMultiSelect: true
  //       }
  //     },
  //     {
  //       filterLabel: 'Status',
  //       filterType: 'dropdown',
  //       filterId: 'Status',
  //       filterDropdownProps: {
  //         selectValue: filterChargerStatusValue,
  //         selectOptions: chargerStatusReferenceData ?? [],
  //         handleSelectOptionChange: (val): void => {
  //           handleFilterDataChange(val, 'chargerStatus')
  //         },
  //         selectDropDownId: 'charger-status-filter-dropdown',
  //         isMultiSelect: true
  //       }
  //     },
  //     {
  //       filterLabel: 'Alarm Status',
  //       filterType: 'dropdown',
  //       filterId: 'alarmStatus',
  //       filterDropdownProps: {
  //         selectValue: filteredCategoryValue,
  //         selectOptions: alarmSeverityReferenceData ?? [],
  //         handleSelectOptionChange: (val): void => {
  //           handleFilterDataChange(val, 'alarmStatus')
  //         },
  //         selectDropDownId: 'charger-severity-filter-dropdown',
  //         isMultiSelect: true
  //       }
  //     },
  //     {
  //       filterLabel: 'Software Version',
  //       filterType: 'dropdown',
  //       filterId: 'softwareVersion',
  //       filterDropdownProps: {
  //         selectValue: softwareVersionValue,
  //         selectOptions: softwareVersionReferenceData ?? [],
  //         handleSelectOptionChange: (val): void => {
  //           handleFilterDataChange(val, 'softwareVersion')
  //         },
  //         selectDropDownId: 'charger-software-version-filter-dropdown',
  //         isMultiSelect: true
  //       }
  //     },
  //   ]
  // }, [softwareVersionReferenceData, chargerLocationReferenceData, chargerStatusReferenceData, globalFiltersWithObjects, alarmSeverityReferenceData])

  // const onResetButtonClick = useCallback(async (callListApi: boolean) => {
  //   const globalFilterState: IFilterStateData[] = cloneDeep(globalFiltersWithObjects);
  //   const filterData: IScreenFilter[] = cloneDeep(globalFiltersWithOnlyIds);
  //   const globalStateScreenFilter = globalFilterState?.find(
  //     (filter) => filter?.screen === 'chargers'
  //   );
  //   const filterDataByScreen = filterData?.find(
  //     (filter) => filter?.screen === 'chargers'
  //   );
  //   if (globalStateScreenFilter) {
  //     globalStateScreenFilter.filterStateValues = chargerInitialFilterStateValues
  //   }
  //   if (filterDataByScreen) {
  //     filterDataByScreen.filterPreference = [
  //       {
  //         key: 'customer',
  //         values: []
  //       },
  //       {
  //         key: 'softwareVersion',
  //         values: []
  //       },
  //       {
  //         key: 'chargerStatus',
  //         values: []
  //       },
  //       {
  //         key: 'location',
  //         values: []
  //       },
  //       {
  //         key: 'alarmStatus',
  //         values: []
  //       }]
  //   }
  //   await dispatch(setGlobalFilterStateData(globalFilterState))
  //   await dispatch(setGlobalFilterData(filterData))
  //   if (BroadcastMessageTablePropsRef?.current && callListApi) {
  //     layoutCallback(1, BroadcastMessageTablePropsRef?.current?.pageSize, BroadcastMessageTablePropsRef?.current?.view, BroadcastMessageTablePropsRef?.current?.sortBy, BroadcastMessageTablePropsRef?.current?.order, filterDataByScreen?.filterPreference, BroadcastMessageTablePropsRef?.current?.searchTerm)
  //   }
  //   setRedirectedStateForChargerFilters(false)(dispatch)

  // }, [globalFiltersWithObjects, globalFiltersWithOnlyIds, BroadcastMessageTablePropsRef?.current, chargerFiltersAppliedFromRedirection])

  const headerActionBtns = renderHeaderActionButtons({
    actionBtns:
      [
        {
          id: 'add_new_btn',
          headerActionBtnClick: (): void => {
            navigate('/broadcast-message/create')
          }
        },
        {

          id: 'export_csv_btn',
          headerActionBtnClick: (event): void => {
            setExportAnchorEl(event.currentTarget);

          }
        }
      ]
  });

  const handleLayoutToggle = useCallback(async (newLayoutVal) => {
    setLayoutView(newLayoutVal);
  }, [BroadcastMessageTablePropsRef.current])


  // const layoutsConfiguration = useMemo(() => {
  //   return [
  //     {
  //       toolTipText: 'List View',
  //       onIconClick: (): void => {
  //         handleLayoutToggle('list')
  //       },
  //       layout: 'list',
  //       iconClassName: 'rms__table__dashboard-list__view__icon'
  //     },
  //     {
  //       toolTipText: 'Grid View',
  //       onIconClick: (): void => {
  //         handleLayoutToggle('grid')
  //       },
  //       layout: 'grid',
  //       iconClassName: 'rms__table__dashboard-grid__view__icon'
  //     }
  //   ]
  // }, [handleLayoutToggle]);

  return (
    <>
      <TableDashboard
        header={'Broadcast Messages'}
        tableId="manage-chargers-table"
        tableClassName="manage__chargers__table"
        searchBoxIncluded={true}
        searchBoxConfiguration={searchBoxConfiguration}
        gridColumns={chargerColumns}
        tableRows={getTableRows()}
        layouts={layouts}
        layoutView={layoutView}
        headerActionBtns={headerActionBtns}
        listLayoutCallBack={layoutCallback}
        totalCount={broadcastMessagesCount ? broadcastMessagesCount : 0}
        showSkeleton={!chargerBroadcastMessages}
        totalCountText={'Messages'}
        showLoader={broadcastMessageTableLoader}
        customNoDataComponent={NoDataComponentChargers}
        pageNumber={chargerBroadcastMessageTableProps?.pageNumber}
        tablePageSize={chargerBroadcastMessageTableProps?.pageSize}
      />
      <Menu
        id="export-option-dropdown"
        anchorEl={exportAnchorEl}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        keepMounted
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        className='export__option__dropdown'
        sx={{ top: '30px' }}
        open={Boolean(exportAnchorEl)}
        onClose={handleClose}
      >
        <MenuItem>
          <div className='export__btn-option' onClick={(e) => {
            e?.stopPropagation();
            onExportBtnClick('csv')
          }}>
            To CSV
          </div>
        </MenuItem>
        <MenuItem>

          <div className='export__btn-option' onClick={(e) => {
            e?.stopPropagation();
            onExportBtnClick('xlsx')
          }}>
            To Excel
          </div>
        </MenuItem>
      </Menu>
    </>
  );
});

export default BroadcastMessageDashboard
