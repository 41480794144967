import { cloneDeep } from 'lodash';
import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import CustomForm, { ICustomFormProps, IFormContentProp } from '../../../globalUtils/CustomForm/CustomForm';
import {
  CustomButtonGroup,
  useAppDispatch,
  useAppSelector,
  useEffectOnce,
  CustomDatePicker,
  RadioButton,
  CustomStatus,
  CustomBreadCrumbs,
  DashboardHeader,
  CustomInput,
  SelectDropDown,
  CustomTooltipWithLabel
} from '../../../globalUtils/globalExports';
import {
  fetchIndividualParameterDetail,
  updateParameters,
} from '../../../rmsReduxStore/chargersRedux/chargerCreators';

import {
  getReferenceDataReducer,
  getChargerReducer, fetchAddParameterRefChargingStations,
  fetchAddParameterRefChargers,
  fetchCustomerReferenceData,
  chargerTypes,
} from '../../../rmsReduxStore/reduxExports';

import { Id, toast } from 'react-toastify';
import { useLocation, useNavigate } from 'react-router-dom';
import { AxiosResponse } from 'axios';
import './EditParameter.css';
import 'react-datepicker/dist/react-datepicker.css';
import {
  DashboardLoader,
} from '../../../globalUtils/globalExports'
import {
  createBreadCrumbProps,
  formatDateInDateTimeFormatNotSeconds,
} from '../../../globalUtils/globalHooks'
import {
  Collapse,
} from '@mui/material';
import {  parseDropDownValues } from '../ChargerPanel/ChargerPanelSettingsDashboard';

interface HeaderArray {
  title: string,
  value: string
}
interface IChargingStation {
  id: string;
  name: string;
  customerId: string;
}

interface IParams {
  paramKey: string;
  paramName: string;
  values: unknown[]
}
interface ICharger {
  label: string;
  value: string;
  chargerId?: string;
}
interface IAddParameterForm {
  id: string;
  customer: ICustomerReferenceData[];
  chargingStation: IChargingStation[];
  chargers: string[];
  scheduledAt?: Date;
  sendNow: boolean;
  params: IParams[];
}
const EditParameter: FC = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { individualParameterDetail } = useAppSelector(getChargerReducer);
  const [showLoader, setShowLoader] = useState(false);

  const [selectedChargers, setSelectedChargers] = useState<ICharger[] | null>(null);
  const { addParameterRefChargers, addParameterRefChargingStations } = useAppSelector(getChargerReducer);
  const { customerReferenceData } = useAppSelector(getReferenceDataReducer);
  const [chargingStationOptions, setChargingStationOptions] = useState<{ label: string; value: string; }[]>([]);
  const [chargerOptions, setChargerOptions] = useState<{ label: string; value: string; chargerId: string; }[]>([]);
  const [customerSelected, setCustomerSelected] = useState<string[]>([]);
  const [stationSelected, setStationSelected] = useState<string[]>([]);
  const [setParameterDetail, setSetParameterDetail] = useState<HeaderArray[] | null>(null);
  const [selectedbtn, setSelectedbtn] = useState('laterBtn');
  const [selectedDate, setSelectedDate] = useState(individualParameterDetail?.triggerTime ? new Date(individualParameterDetail?.triggerTime) : null);
  const [singleCustomerOnly, setSingleCustomerOnly] = useState(false);
  const location = useLocation();
  const parameterData = location?.state && location?.state?.parameterData;
  const dropdownOptions: { value: string; label: string }[] = [];
  const row = individualParameterDetail?.paramDetail;
  const [isOpen, setIsOpen] = React.useState<boolean>(true);
  const [paramDetail, setParamDetail] = useState(individualParameterDetail);
  const [errorMessage, setErrorMessage] = useState<string>('')
  const initialParameterFormData = useMemo(() => {
    return {
      id: individualParameterDetail?.id || '',
      customer: [],
      chargers: [],
      chargingStation: [],
      sendNow: false,
      params: [],
    }
  }, [individualParameterDetail])
  const [editParameterFormData, setEditParameterFormData] = useState<IAddParameterForm>(initialParameterFormData);

  useEffect(() => {
    const customersList = customerReferenceData?.filter(
      station => station?.id !== 'all'
    );
    if (customersList?.length == 1) {
      setEditParameterFormData({
        ...editParameterFormData,
        customer: customersList
      })
      setCustomerSelected(customersList?.map((item) => item?.value));
      setSingleCustomerOnly(true);
    }
  }, [customerReferenceData])


  const renderDropDownSelectedValue = (param) => {
    const subParameter = param?.paramDetail;
    if (subParameter?.fieldType === 'DropDownMultiple') {
      const values = typeof param?.value == 'string' ? param?.value?.split(',') : param?.value;
      return parseDropDownValues(subParameter?.values).filter((item) => values?.includes(item.value))
    }
    return parseDropDownValues(subParameter?.values).find((item) => item.value === param?.value)
  }

  useEffect(() => {
    if (addParameterRefChargingStations && customerSelected?.length > 0) {
      let chargingStationOptionsWithAll: { label: string; value: string; }[] = [];
      const filteredChargingStations = addParameterRefChargingStations?.filter(
        station => customerSelected?.includes(station?.customerId)
      );
      chargingStationOptionsWithAll = [
        ...filteredChargingStations.map(station => ({
          label: station?.name,
          value: station?.id,
        })),
      ];
      const hasAllOption = chargingStationOptionsWithAll?.some(option => option?.label === 'All');
      if (!hasAllOption) {
        chargingStationOptionsWithAll?.unshift({ label: 'All', value: 'all' });
      }
      setChargingStationOptions(chargingStationOptionsWithAll);
    }
    else {
      setChargingStationOptions([]);
    }
  }, [customerSelected, addParameterRefChargingStations,]);

  useEffect(() => {
    if (addParameterRefChargers && stationSelected?.length > 0) {
      const filteredChargers = addParameterRefChargers?.filter(
        charger => stationSelected?.includes(charger?.chargingStationId)
      );

      const chargerOptionsWithAll = [
        { label: 'All', value: 'all', chargerId: 'all' },
        ...filteredChargers.map(charger => ({
          label: charger?.visibleId,
          value: charger?.id,
          chargerId: charger?.id
        }))
      ]
      setChargerOptions(chargerOptionsWithAll);
    }
    else {
      setSelectedChargers([]);
      setChargerOptions([]);
    }
  }, [stationSelected, addParameterRefChargers]);


  useEffectOnce(() => {
    dispatch(fetchCustomerReferenceData());
    dispatch(fetchAddParameterRefChargingStations({}));
    dispatch(fetchAddParameterRefChargers({}));
    dispatch(fetchIndividualParameterDetail({ id: parameterData?.id }));

  });

  useEffect(() => {
    setParamDetail(individualParameterDetail)
    setCustomerSelected(individualParameterDetail?.selectedCustomers || []);
    setStationSelected(individualParameterDetail?.selectedChargingStations || []);

    const customerAlreadySelected = customerReferenceData?.filter(
      customer => individualParameterDetail?.selectedCustomers?.includes(customer?.id)
    );

    const chargingStationAlreadySelected = addParameterRefChargingStations?.filter(
      chargingStation => individualParameterDetail?.selectedChargingStations?.includes(chargingStation?.id)
    )?.map(chargingStation => ({
      label: chargingStation?.name,
      value: chargingStation?.id,
      id: chargingStation?.id,
      name: chargingStation?.id,
      customerId: chargingStation?.id,
    }));

    const chargerAlreadySelected = addParameterRefChargers?.filter((charger) =>
      individualParameterDetail?.devices?.includes(charger?.id)).map(charger => ({
        chargerId: charger?.id,
        value: charger?.id,
        label: charger?.visibleId
      }));
    setSelectedDate(individualParameterDetail?.triggerTime ? new Date(individualParameterDetail?.triggerTime) : new Date())
    const parameInitialValues = [
      {
        paramKey: individualParameterDetail?.parameterKey || '',
        paramName: individualParameterDetail?.parameterName || '',
        values: individualParameterDetail?.value ? [individualParameterDetail?.value] : [],
      }
    ]
    setEditParameterFormData((prevState) => ({
      ...prevState,
      id: individualParameterDetail?.id || '',
      params: parameInitialValues || [],
      customer: customerAlreadySelected || [],
      chargingStation: chargingStationAlreadySelected || [],
      chargers: chargerAlreadySelected?.map((item) => item?.value) || []
    }));
    setSelectedChargers(chargerAlreadySelected || [])

  }, [individualParameterDetail]);



  useEffectOnce(() => {
    const headerArray: HeaderArray[] = [
      {
        title: 'Parameter Name',
        value: parameterData?.parameterName
      },
      {
        title: 'Set Value',
        value: parameterData?.parameterType === 'Date' ? formatDateInDateTimeFormatNotSeconds(parameterData?.value):(parameterData?.unit && parameterData?.value ? parameterData?.value + parameterData?.unit : parameterData?.value)
      },
      {
        title: 'Set By',
        value: parameterData?.createdBy
      },
      {
        title: 'Requested On',
        value: parameterData?.createdOn ? formatDateInDateTimeFormatNotSeconds(parameterData?.createdOn) : '',
      },
      {
        title: 'Set On',
        value: parameterData?.triggerTime ? formatDateInDateTimeFormatNotSeconds(parameterData?.triggerTime) : '',
      },
      {
        title: 'Status',
        value: parameterData?.status
          ? renderStatusCell(parameterData?.status)
          : '',
      }
    ]
    setSetParameterDetail(headerArray);
  });

  // Redux State CleanUp On UnMount 
  useEffect(() => {
    return (): void => {
      const action = {
        type: chargerTypes.CLEAR_UPDATED_PARAMETER_DETAIL_DATA,

      }
      dispatch(action);
    }

  }, [])

  const renderStatusCell = (status: string): JSX.Element => {
    let statusIconClassName = '';
    if (status === 'InProgress') {
      statusIconClassName = 'rms__icon status__in-progress__parameter__icon';
    }
    else if (status === 'Scheduled') {
      statusIconClassName = 'rms__icon status__scheduled__parameter__icon';
    }
    else {
      statusIconClassName = 'rms__icon status__completed__parameter__icon';
    }
    return (
      <CustomStatus
        statusText={status}
        statusIconClassName={statusIconClassName}
      />
    );
  };

  const handleDateTimeChange = (date) => {
    setSelectedDate(date);
    setEditParameterFormData({
      ...editParameterFormData,
      sendNow: false,
      scheduledAt: date
    })
  };

  const handleFormDataChange = useCallback((val, formKey) => {
    const formData = cloneDeep(editParameterFormData);
    if (val?.id) {
      formData[formKey] = val?.id;
    }
    else {
      formData[formKey] = val;
    }
    setEditParameterFormData(formData);

    if (formKey === 'customer') {
      setCustomerSelected(val?.map((item) => item?.value));

      if (!val || val?.length === 0) {
        setChargingStationOptions([]);
        setChargerOptions([]);
        formData.chargingStation = [];
        formData.chargers = [];
        setSelectedChargers([]);
      }
      else if (addParameterRefChargingStations && val?.length > 0) {
        setEditParameterFormData((prevState) => ({
          ...prevState,
          chargingStation: [],
          chargers: [],
        }))
        setSelectedChargers([]);

        let chargingStationOptionsWithAll: { label: string; value: string; }[] = [];
        const filteredChargingStations = addParameterRefChargingStations?.filter(
          station => val?.some(customer => customer?.id === station?.customerId)
        );
        chargingStationOptionsWithAll = [
          ...filteredChargingStations.map(station => ({
            label: station?.name,
            value: station?.id,
          })),
        ];
        const hasAllOption = chargingStationOptionsWithAll?.some(option => option?.label === 'All');
        if (!hasAllOption) {
          chargingStationOptionsWithAll?.unshift({ label: 'All', value: 'all' });
        }
        setChargingStationOptions(chargingStationOptionsWithAll);
      }
    }
    else if (formKey === 'chargingStation') {
      setStationSelected(val?.map(item => item?.value));
      if (!val || val?.length === 0) {
        setChargerOptions([]);
        formData.chargers = [];
        setSelectedChargers([]);
      }
      else if (addParameterRefChargers && val?.length > 0) {
        setEditParameterFormData((prevState) => ({
          ...prevState,
          chargers: [],
        }))
        setSelectedChargers([]);
        const filteredChargers = addParameterRefChargers?.filter(
          charger => val?.some(chargingStation => chargingStation?.value === charger?.chargingStationId)
        );
        const chargerOptionsWithAll = [
          { label: 'All', value: 'all', chargerId: 'all' },
          ...filteredChargers.map(charger => ({
            label: charger?.visibleId,
            value: charger?.id,
            chargerId: charger?.id
          }))
        ]
        setChargerOptions(chargerOptionsWithAll);
      }

    }
    else if (formKey == 'chargers') {
      setSelectedChargers(val);
      formData[formKey] = val
        ? val
          .map((item) => item?.value)
          .filter((value) => value !== 'all')
        : [];
    }


  }, [editParameterFormData, addParameterRefChargingStations, addParameterRefChargers, chargingStationOptions, chargerOptions, handleDateTimeChange, customerSelected, stationSelected]);

  const resetFormData = useCallback(() => {
    setEditParameterFormData(initialParameterFormData);
    setChargingStationOptions([]);
    setChargerOptions([]);
    setSelectedChargers([]);
  }, [])

  const disableAddButton = useCallback(() => {
    return (!(editParameterFormData?.customer?.length > 0 && editParameterFormData?.chargingStation?.length > 0
      && editParameterFormData?.chargers?.length > 0))
  }, [editParameterFormData])

  const disableCancelButton = useCallback(() => {
    const hasFormChanged = Object.keys(editParameterFormData).some(
      (key) => editParameterFormData[key] !== initialParameterFormData[key]
    );
    return !hasFormChanged
  }, [editParameterFormData])

  const parameterAddedToast = (): Id => toast.success('Setting edited.');
  const somethingWentWrongToast = (): Id => toast.warn('Something went wrong!.');

  const btnsList = useMemo(() => {
    return [
      {
        buttonText: 'Update',
        buttonId: 'add',
        btnClassName: disableAddButton() ? 'primary__btn disabled' : 'primary__btn',
        handleClick: async (): Promise<void> => {
          const response: AxiosResponse = await dispatch(updateParameters(editParameterFormData));
          if (response?.status === 200 || response?.status === 202) {
            parameterAddedToast();
            setTimeout(() => {
              navigate('/update-settings');
            }, 2000)
          }
          else {
            setShowLoader(false)
            somethingWentWrongToast();
            setTimeout(() => {
              resetFormData();
            }, 2000)
          }
        },
        isDisabled: disableAddButton(),
        buttonVariant: 'filled',
      },
      {
        buttonText: 'Cancel',
        buttonId: 'cancel',
        btnClassName: disableCancelButton() ? 'secondary__btn disabled' : 'secondary__btn',
        handleClick: (): void => {
          setTimeout(() => {
            navigate('/update-settings');
          }, 1000)
        },
        isDisabled: disableCancelButton(),
        buttonVariant: 'outlined',
      },

    ];
  }, [editParameterFormData, disableAddButton]);



  const formArray: IFormContentProp[] = useMemo(() => {
    return [
      {
        rowCountClassName: 'grid-3',
        formObject: [
          !singleCustomerOnly ?
            {
              formLabel: 'Customer',
              isRequired: true,
              objectType: 'dropdown',
              selectDropdownProps: {
                isMultiSelect: true,
                selectValue: editParameterFormData?.customer,
                selectOptions: customerReferenceData ?? [],
                handleSelectOptionChange: (val): void => {
                  setEditParameterFormData((prevState) => ({
                    ...prevState,
                    chargingStation: [],
                    charger: [],
                  }));

                  setSelectedChargers([]);
                  handleFormDataChange(val, 'customer');
                },
                selectDropDownId: 'charging-model-dropdown',
                selectDropDownName: 'charging-model-dropdown',
              },
              inputProps: null,
              textAreaProps: null,
            } : {
              formLabel: 'Customer',
              isRequired: true,
              objectType: 'input',
              selectDropdownProps: null,
              inputProps: {
                inputPlaceHolder: 'Type here',
                handleInputChange: (event): void => {
                  handleFormDataChange(event?.target?.value, 'customer');
                },
                inputValue: editParameterFormData?.customer?.[0]?.label,
                inputType: 'string',
                inputFieldId: 'add_charger_mac_address',
                isRequired: false,
                isDisabled: true,
              },
              textAreaProps: null,
            },

          {
            formLabel: 'Charging Station',
            isRequired: true,
            objectType: 'dropdown',
            selectDropdownProps: {
              isMultiSelect: true,
              selectValue: editParameterFormData?.chargingStation,
              selectOptions: chargingStationOptions ?? [],
              handleSelectOptionChange: (val): void => {

                setEditParameterFormData((prevState) => ({
                  ...prevState,
                  charger: [],
                }));

                setSelectedChargers([]);
                handleFormDataChange(val, 'chargingStation');
              },
              selectDropDownId: 'charging-model-dropdown',
              selectDropDownName: 'charging-model-dropdown',
            },
            inputProps: null,
            textAreaProps: null,
          },
          {
            formLabel: 'Charger',
            isRequired: true,
            objectType: 'dropdown',
            selectDropdownProps: {
              isMultiSelect: true,
              selectValue: selectedChargers,
              selectOptions: chargerOptions ?? [],
              handleSelectOptionChange: (val): void => {
                handleFormDataChange(val, 'chargers');
              },
              selectDropDownId: 'charging-model-dropdown',
              selectDropDownName: 'charging-model-dropdown',
            },
            inputProps: null,
            textAreaProps: null,
          },
        ],
      },
    ];
  }, [singleCustomerOnly, editParameterFormData?.chargers, editParameterFormData?.customer, editParameterFormData
    ?.chargingStation, chargingStationOptions, customerReferenceData, chargerOptions])


  // Method: Allowing only numbers for dataType (Integer/Double) in Input field while changing settings parameters
  const keyPressValidation = (event, dataType): void => {
    const { key, target } = event;
    if (dataType === 'Integer') {
      if (key === ' ' || (isNaN(Number(key)) && key !== 'Backspace' && !(event.metaKey || event.ctrlKey && key.toLowerCase() === 'v'))) event.preventDefault();
    } else if (dataType === 'Double') {
      if (key === '.' && target?.value?.includes('.')) event.preventDefault()
      if (key === ' ' || (isNaN(Number(key)) && key !== '.' && key !== 'Backspace' && !(event.metaKey || event.ctrlKey && key.toLowerCase() === 'v'))) event.preventDefault();
    }
  }
  //  TODO: BreadCrumb navigation Props
  const breadCrumbs = createBreadCrumbProps({
    breadCrumbProps:
      [
        {
          objectType: 'link',
          id: 'setParameters',
        },
        {
          objectType: 'text',
          id: 'text',
          text: 'Edit Parameter'
        }
      ]
  })
  const customFormProps: ICustomFormProps = useMemo(() => {
    return {
      formContainerClassName: 'edit__parameter__wrap',
      formArray: formArray
    };
  }, [formArray, btnsList, customerReferenceData, editParameterFormData])


  // handling the seleceted and typed input value of parameters

  const handleParameterValueChange = (paramKey: string, paramName: string, values) => {
    const flattenedValues = Array.isArray(values) ? [].concat(...values) : [values];
    const paramValue = [
      {
        paramKey: individualParameterDetail?.parameterKey || '',
        paramName: individualParameterDetail?.parameterName || '',
        values: flattenedValues || [],
      }
    ]
    setEditParameterFormData((prevState) => ({
      ...prevState,
      params: paramValue || [],
      id: individualParameterDetail?.id || '',
    }));
  };


  const sendNowLaterbtnsList = [
    { radioButtonLabel: 'Now', radioButtonId: 'nowBtn' },
    { radioButtonLabel: 'Later', radioButtonId: 'laterBtn' },
  ]

  const handleSendLaterChange = (event, value) => {
    setSelectedbtn(value);
    if (value === 'nowBtn') {
      setEditParameterFormData((prevState) => ({
        ...prevState,
        sendNow: true,
      }));
      handleFormDataChange(null, 'scheduledAt');
      handleFormDataChange(true, 'sendNow');

    } else {
      setEditParameterFormData((prevState) => ({
        ...prevState,
        sendNow: false,
      }));
    }
  };

  const handleToggle = () => {
    setIsOpen(true);
  };

  if ((row?.fieldType === 'DropDownSingle' || row?.fieldType === 'DropDownMultiple') && row?.values) {
    try {
      const parsedOptions = JSON.parse(row.values);
      Object.entries(parsedOptions).forEach(([value, label]) => {
        dropdownOptions.push({ value, label: String(label) });
      });
    } catch (error) {
      console.error('Error parsing dropdown options:', error);
    }
  }
   const checkValidationsAndShowError = (subParameter, value): string | null => {
    const parsedValues = JSON.parse(subParameter?.values)
    if (subParameter?.dataType === 'String') {
      return parsedValues && value?.length >= parsedValues['max'] ? `Only ${parsedValues['max']} characters are allowed` : null
    } else if (subParameter?.dataType === 'Integer') {
      return parsedValues && !(parseInt(value) >= parsedValues['min'] && parseInt(value) <= parsedValues['max']) ? `Please enter a value between ${parsedValues['min']} and ${parsedValues['max']}.` : null
    } else if (subParameter?.dataType === 'Double') {
      return parsedValues && !(parseFloat(value) >= parsedValues['min'] && parseFloat(value) <= parsedValues['max']) ? `Please enter a value between ${parsedValues['min']} and ${parsedValues['max']}.` : null
    }
    return null
  }

  const handleChange = useCallback((key, value, subParameter) => {
    if (subParameter?.fieldType === 'DropDownSingle') {
      const existingData = cloneDeep(paramDetail)
      if (existingData) {
        existingData.value = value
        setParamDetail(existingData)
        handleParameterValueChange(subParameter?.key, subParameter?.visibleName, value);
      }
    }
    else if (subParameter?.fieldType === 'DropDownMultiple') {
      const selectedValuesString = value?.join(','); // Convert array to comma-separated string
      const existingData = cloneDeep(paramDetail);
      if (existingData) {
        existingData.value = selectedValuesString;
        setParamDetail(existingData);
        handleParameterValueChange(subParameter?.key, subParameter?.visibleName, value);
      }
    }
    else if(subParameter?.fieldType==='Input'){
      const existingData = cloneDeep(paramDetail);
      if(existingData){
        existingData.value=value;
        setParamDetail(existingData);
        handleParameterValueChange(subParameter?.key, subParameter?.visibleName, value);
      }
      const inValidInputErrorMsg = checkValidationsAndShowError(subParameter, value)
      setErrorMessage(inValidInputErrorMsg != null ? inValidInputErrorMsg : '')
    }
    else if(subParameter?.fieldType==='Date'){
      const existingData = cloneDeep(paramDetail);
      if(existingData){
        existingData.value=value;
        setParamDetail(existingData);
        handleParameterValueChange(subParameter?.key, subParameter?.visibleName, value);
      }
    }

  }, [paramDetail])
     
  return (
    <>
      <div className='edit-parameter-content-wrap'>
        <div className='edit-parameter-header-wrap'>
          <div className='back__btn-container'>
            <CustomBreadCrumbs
              breadCrumbs={breadCrumbs}
              containerId={'edit-parameter-breadcrumb'}
              containerClassName={'edit-parameter-breadcrumb'}
            />
          </div>
          <div className='view-parameter-header'>
            <div className='charger__panel-header-content-wrap'>
              <DashboardHeader
                header={'Edit Parameter'}
                headerClassName={'edit__parameter__wrap-header-text'}
              />
            </div>
          </div>
        </div>
        <div className='parameters-cards-summary-wrapper' >
          <div
            key={'view-parameter-sub-header'}
            className='parameter_sub__header__grey__card'>
            <div className='parameter_sub__header__grey__card__content__wrap'>
              {setParameterDetail?.map((arrayData, index) => {
                return (
                  <div key={index} className={'parameter_sub__header__grey__card__content ' + index}>
                    <div className='parameter_sub__header__grey__card__content-title'>
                      {arrayData?.title}
                    </div>
                    <div className='parameter_sub__header__grey__card__content-value'>
                      {
                        typeof arrayData?.value === 'string' ? (
                          <CustomTooltipWithLabel label={arrayData?.value} labelId={parameterData?.id} />
                        ) :
                          (arrayData?.value)
                      }
                    </div>
                  </div>
                )
              })}
            </div>
          </div>
        </div>
        <div className='edit-parameter-content-wrap-top'>
          <CustomForm {...customFormProps} />
          <div className="edit__parameter__parameters__list">
            <DashboardLoader handleClose={setShowLoader} showLoader={showLoader} loaderText={'Changing the parameter on the chargers!!!'} />
          </div>
          <div className='edit-parameter-single-parameter-update'>
            <div className="add__parameter__parameters__container">
              <div className='add__parameter__single__parameters__container'>
                <div className='add__parameters__wrap' onClick={handleToggle}>
                  <div className='add__parameter__toggle__wrap'>
                    <div className='add__parameter__toggle__wrap-text'>{row?.visibleName}</div>
                    {/* <Icon iconClassName={isOpen ? 'grid__layout__expandable__up-icon' : 'grid__layout__expandable__down-icon'} /> */}
                  </div>
                </div>
                <Collapse in={isOpen} timeout="auto" unmountOnExit>
                  <div className='charger__status__toggle__wrap__content'>
                    <div className='charger__status__toggle__wrap__content-row'>
                      <div className='add__parameter__toggle__wrap__content-row__wrap'>
                        <div className='add__parameter__toggle__wrap__content-row-parameter-wrap'>
                          {row?.fieldType === 'DropDownSingle' ? 'Select Value' :
                            row?.fieldType === 'DropDownMultiple' ? 'Select Values' :
                              row?.fieldType === 'Date' ? 'Select Date' : 'Enter Value'
                          }
                        </div>
                        <div className='add__parameter__toggle__wrap__content-row-value-wrap'>
                          {(row?.fieldType === 'DropDownSingle' || row?.fieldType === 'DropDownMultiple') && individualParameterDetail &&

                            <SelectDropDown
                              dropDownClassName="add-parameter-dropdown-options"
                              isDisabled={!row?.values}
                              selectValue={renderDropDownSelectedValue(paramDetail ?? individualParameterDetail) || ''}
                              selectOptions={parseDropDownValues(row?.values)}
                              handleSelectOptionChange={(selectedValues) => {
                                if (row?.fieldType === 'DropDownMultiple') {
                                  const selected = selectedValues?.map((item) => item.value)
                                  handleChange(row?.key, selected, row)
                                } else { handleChange(row?.key, selectedValues.value, row) }
                              }}
                              selectDropDownId={'parameterSelectDropDown'}
                              isMultiSelect={row?.fieldType === 'DropDownMultiple'}
                            />

                          }
                          {row?.fieldType === 'Input' &&
                            <CustomInput
                              inputPlaceHolder="Type here"
                              className="add-parameter-input-area"
                              handleInputChange={(e) => {
                                 e?.stopPropagation()
                                 handleChange(row?.key, e?.target?.value, row)
                              }}
                              handleKeyPress={(e) => {
                                e?.stopPropagation()
                                keyPressValidation(e, row?.dataType)
                              }}
                              inputValue={paramDetail?.value ?? parameterData?.value}
                              inputFieldId={row?.id || ''}
                              isRequired={false}
                              errorMessage={errorMessage}                             
                            />
                          }
                          {row?.fieldType === 'Date' &&
                            <CustomDatePicker
                              datePickerId={row?.key}
                              selectsRange={false}
                              showTimePicker={true}
                              startDate={new Date(paramDetail?.value ?? parameterData?.value)}
                              endDate={null}
                              onChange={(selectedDate) => {
                                handleChange(row?.key,  selectedDate , row)
                              }}
                            />}
                        </div>
                      </div>
                    </div>
                  </div>
                </Collapse>
              </div>
            </div>
          </div>
        </div>
        <div className='edit__parameter__btns'>
          <div className='send-now-and-later-box'>
            <RadioButton
              buttonsList={sendNowLaterbtnsList}
              handleSelectedRadioBtnChange={handleSendLaterChange}
              selectedRadioBtnValue={selectedbtn}
              radionBtnGrpName="customerOptions"
            />
            {selectedbtn === 'laterBtn' && (
              <div className='edit-parameter-scheduled-date'>
                <CustomDatePicker
                  startDate={selectedDate}
                  showTimePicker={true}
                  endDate={null}
                  selectsRange={false}
                  onChange={handleDateTimeChange}
                  datePickerClassName={'edit_parameter_scheduled_date_time'}
                  timeInterval={15}
                />
              </div>
            )}
          </div>
          <CustomButtonGroup buttonsList={btnsList} buttonGroupClassName={'button__group__footer'} />
        </div>

      </div>

    </>
  );
};
export default EditParameter;

