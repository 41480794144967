/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { chargingStationTypes } from '../reduxExports'
import hwaxios from '../api-service/HWAxios';

// Charging Station action Creators

const url = process.env.REACT_APP_SERVER_BASE_URL

export const fetchChargingStationData:any = (reqParams: unknown) => async (dispatch: (arg0: Partial<ChargingStationAction>) => void) => {
  try {
    const response = await hwaxios.get(url + '/chargingStations', { params: reqParams })
    const action: Partial<ChargingStationAction> = {
      type: chargingStationTypes.FETCH_CHARGING_STATION_DATA,
      chargingStations: response.data?.data
    }
    dispatch(action)
  } catch (error) {
    console.error(error)
    throw error
  }
}

export const exportToCsvChargingStationData:any = (reqParams: unknown) => async (dispatch: (arg0: Partial<ChargingStationAction>) => void) => {
  try {
    const response = await hwaxios.get(url + '/export_csv', { params: reqParams })
    const action: Partial<ChargingStationAction> = {
      type: chargingStationTypes.FETCH_CHARGING_STATION_DATA,
      chargingStations: response.data?.data
    };
    dispatch(action);
  } catch (error) {
    console.error(error)
    throw error
  }
}

export const getCustomer:any = () => async (dispatch) => {
  try {
    const customers = await hwaxios.get(url + '/ref/customer')
    const action: Partial<ChargingStationAction> = {
      type: chargingStationTypes.GET_CUSTOMER_DATA,
      customers: customers.data.data
    }
    dispatch(action)
  } catch (error) {
    console.error(error)
    throw error
  }
}

export const getCountryState:any = () => async dispatch => {
  try {
    const countryState = await hwaxios.get('https://countriesnow.space/api/v0.1/countries/states')
    const action: Partial<ChargingStationAction> = {
      type: chargingStationTypes.GET_COUNTRY_STATE_DATA,
      countryState: countryState?.data?.data
    }
    dispatch(action)
  } catch (error) {
    console.error(error)
    throw error
  }
}

export const getReferenceData:any = () => async dispatch => {
  try {
    const referenceData = await hwaxios.get(url + '/ref/charging-station')
    const action: Partial<ChargingStationAction> = {
      type: chargingStationTypes.GET_REFERENCE_DATA,
      locationTypeOptions: referenceData?.data?.data
    }
    dispatch(action)
  } catch (error) {
    console.error(error)
    throw error
  }
}
