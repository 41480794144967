import { cloneDeep } from 'lodash';
import React, { memo, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import {
  DashboardLoader,
  downloadFile,
  FilterProp,
  formatRelativeTimeForTable,
  renderHeaderActionButtons,
  TableDashboard,
  useAppDispatch,
  useAppSelector,
} from '../../globalUtils/globalExports';
import {
  getReportReducer,
  fetchChargingHistoryReportData,
  setReportListTableProps,
  reportTypes,
  getFilterSelectedValue,
  getReferenceDataReducer,
  getFilterReducer,
  setFilterData,
  getCurrentFiltersValues,
  exportChargingHistoryReport,
} from '../../rmsReduxStore/reduxExports';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import './reports.css';
import { Id, toast } from 'react-toastify';
import { NoDataComponentChargingHistory } from '../../globalUtils/TableDashboard/TableComponents';
import access from '../../auth/service/AccessControl';

const ChargerHistoryDashboard = memo(function ChargerHistoryDashboard() {
  const dispatch = useAppDispatch();
  const { chargingHistoryColumnHeaders, chargingHistoryData, chargingHistoryCount, reportLoader, chargingHistoryListTableProps } = useAppSelector(getReportReducer);
  const { chargerVisibleIdReferenceData } = useAppSelector(getReferenceDataReducer);
  const { screenFilters } = useAppSelector(getFilterReducer)
  const [exportAnchorEl, setExportAnchorEl] = useState(null);
  const [exportingFile, setExportingFile] = useState(false);
  const [dashboardLoader, setDashboardLoader] = useState(false)
  const chargingHistoryListTablePropsRefs = useRef(chargingHistoryListTableProps)

  useEffect(() => {
    chargingHistoryListTablePropsRefs.current = chargingHistoryListTableProps;
  }, [chargingHistoryListTableProps])

  // TODO: Handles global search box
  const onChangeOfSearchText = useCallback(async (searchTerm) => {
    if (chargingHistoryListTablePropsRefs) {
      await layoutCallback(1, chargingHistoryListTablePropsRefs?.current?.pageSize, chargingHistoryListTablePropsRefs?.current?.view, chargingHistoryListTablePropsRefs?.current?.sortBy, chargingHistoryListTablePropsRefs?.current?.order, null, searchTerm)
    }
  }, [chargingHistoryListTablePropsRefs, chargingHistoryColumnHeaders, chargingHistoryData]);

  const searchBoxConfiguration = useMemo(() => {
    return {
      searchFieldId: 'manage-charging-history-search-box',
      searchFieldName: 'manage-charging-history-search-box',
      handleSearch: onChangeOfSearchText,
    };
  }, [onChangeOfSearchText]);

  // TODO: Adds action btns in header and their callbacks
  const headerActionBtns = renderHeaderActionButtons({
    actionBtns:
      [
        {
          id: 'export_csv_btn',
          isAccessible: access?.reports?.chargingHistory?.export(),
          headerActionBtnClick: (event): void => {
            event?.stopPropagation()
            setExportAnchorEl(event.currentTarget);
          }
        }
      ]
  })

  // TODO: Table Layout Callbacks
  const mobileViewConfiguration = useMemo(() => {
    return {
      headerDataConfig: {
        headerLeftDataConfig: ['name', 'type'],
        headerRightDataConfig: {
          actionIconsComponent: false,
          statusIconComponent: true,
        },
      },
      statusIconKey: 'status',
      contentDataConfig: [
        [],
      ],
    };
  }, []);

  const getTableRows = useCallback(() => {
    if (!chargingHistoryData || !chargingHistoryColumnHeaders) {
      return [];
    }
    const data = cloneDeep(chargingHistoryData)
    chargingHistoryColumnHeaders?.forEach((column) => {
      const { key, dataType, unit } = column;
      if (key && (dataType || unit)) {

        data?.forEach((historyItem) => {
          if (historyItem?.[key] && dataType === 'Date') {
            historyItem[key] = formatRelativeTimeForTable(historyItem[key]);
          }
          if (unit) {
            historyItem[key] = `${historyItem[key]} ${unit}`;
          }
        })
      }
    })
    return data || [];
  }, [chargingHistoryData, chargingHistoryColumnHeaders]);

  const chargerHistoryColumns = chargingHistoryColumnHeaders?.map(item => {
    return ({ field: item?.key, description: item?.description, flex: 1, headerName: item?.visibleName, minWidth: (item?.visibleName?.length > 25) ? 200 : 150 })
  })

  const layoutCallback = useCallback(async (pageNumber: number, pageSize: number, view: string, sortField: string, sortOrder: string, filterValue, searchTerm) => {
    const tableProps: IListTableProps = cloneDeep(chargingHistoryListTablePropsRefs.current)
    await dispatch(fetchChargingHistoryReportData({
      sortBy: sortField || '',
      order: sortOrder || 'ascending',
      pageSize,
      pageNumber,
      filters: filterValue ? filterValue : getCurrentFiltersValues('chargingHistoryReport'),
      searchTerm: searchTerm != null && searchTerm != undefined ? searchTerm : chargingHistoryListTablePropsRefs?.current?.searchTerm
    }));
    if (tableProps) {
      tableProps.view = view || 'list'
      tableProps.sortBy = sortField || ''
      tableProps.order = sortOrder || 'ascending';
      tableProps.pageNumber = pageNumber;
      tableProps.pageSize = pageSize;
      if (searchTerm != null && searchTerm != undefined) {
        tableProps.searchTerm = searchTerm;
      }
    }
    await dispatch(setReportListTableProps(tableProps, 'chargingHistory'))
  }, [chargingHistoryListTablePropsRefs, dispatch]);

  // TODO: Redux state Clean up
  const chargingHistoryCleanUpStates = useCallback(() => {
    const action = {
      type: reportTypes.CLEAR_CHARGING_HISTORY_LIST_DATA,
    }
    dispatch(action);
  }, [])

  useEffect(() => {
    window.addEventListener('beforeunload', chargingHistoryCleanUpStates);
    return (): void => {
      chargingHistoryCleanUpStates()
      window.removeEventListener('beforeunload', chargingHistoryCleanUpStates);
    }
  }, [])

  // TODO: Export Handlers
  const handleClose = (): void => {
    setExportAnchorEl(null);
  };

  const fileDownloadSuccessToast = useCallback((): Id => {
    setExportingFile(false);
    return toast.success('File downloaded.');
  }, []);

  const fileDownloadFailureToast = useCallback((): Id => {
    setExportingFile(false);
    return toast.warn('Something went wrong. Please export again.');
  }, []);

  const renderExportMenuItem = (fileType: 'csv' | 'xlsx', label: string): JSX.Element => (
    <MenuItem key={fileType}>
      <div className='export__btn-option' onClick={(e) => {
        e?.stopPropagation();
        onExportBtnClick(fileType);
      }}>
        {label}
      </div>
    </MenuItem>
  );

  const dateRangeExceededErrorToast = (message): Id => toast.error(message);

  const dateFilterRangeCheck = (daysLimit) => {
    const filterSetOnValue = getFilterSelectedValue('chargingHistoryInterval', 'chargingHistoryReport', false);
    const startDate = filterSetOnValue?.[0] ? new Date(filterSetOnValue?.[0]) : null;
    const endDate = filterSetOnValue?.[1] ? new Date(filterSetOnValue?.[1]) : null;
    if (startDate && endDate) {
      const differenceInMilliseconds = endDate.getTime() - startDate.getTime();
      const differenceInDays = differenceInMilliseconds / (1000 * 60 * 60 * 24);
      if (differenceInDays < daysLimit) {
        return true;
      }
    }
    return false;
  }

  const onExportBtnClick = useCallback(async (fileType: 'csv' | 'xlsx'): Promise<void> => {
    if (!dateFilterRangeCheck(5)) {
      const filterSetOnValue = getFilterSelectedValue('chargingHistoryInterval', 'chargingHistoryReport', false);
      const startDate = filterSetOnValue?.[0] ? filterSetOnValue?.[0] : null;
      const endDate = filterSetOnValue?.[1] ? filterSetOnValue?.[1] : null;
      if (startDate && endDate) dateRangeExceededErrorToast('Date range exceeded more than 5 days!!')
      else dateRangeExceededErrorToast('Please select date range of 5 days!!')
      return;
    }

    if (exportingFile) {
      // If already exporting, prevent another export
      return;
    }
    try {
      setDashboardLoader(true)
      const response = await dispatch(exportChargingHistoryReport({
        fileType: fileType,
        filters: getCurrentFiltersValues('chargingHistoryReport')
      }));
      if (response?.status === 202 || response?.status === 200) {
        setDashboardLoader(false)
        downloadFile({ url: response?.data?.url });
        fileDownloadSuccessToast()
      } else {
        setDashboardLoader(false)
        fileDownloadFailureToast();
        setExportAnchorEl(null);
      }
    } finally {
      setDashboardLoader(false)
      // Ensure that exporting status is updated even if there's an error
      setExportingFile(false);
      setExportAnchorEl(null);
    }
  }, [exportingFile, fileDownloadSuccessToast, fileDownloadFailureToast])

  // TODO: Filter side pop up 
  const handleFilterDataChange = useCallback(async (val, filterKey, isGlobal) => {
    const res = await setFilterData(val, filterKey, 'chargingHistoryReport', 'SET', isGlobal ? isGlobal : false)(dispatch)
    if (res?.message === 'Action dispatched successfully') {
      if (chargingHistoryListTablePropsRefs?.current) {
        await layoutCallback(1, chargingHistoryListTablePropsRefs?.current?.pageSize, chargingHistoryListTablePropsRefs?.current?.view, chargingHistoryListTablePropsRefs?.current?.sortBy, chargingHistoryListTablePropsRefs?.current?.order, res?.filters, chargingHistoryListTablePropsRefs?.current?.searchTerm)
      }
    }
  }, [chargingHistoryData, dispatch],)

  //TODO: Include Date Intervals for dateFilter
  const chargingHistoryFilterProps: FilterProp[] = useMemo(() => {
    const filterSetOnValue = getFilterSelectedValue('chargingHistoryInterval', 'chargingHistoryReport', false);

    return [
      {
        filterLabel: 'Charger',
        filterType: 'dropdown',
        filterId: 'chargerId',
        filterDropdownProps: {
          selectValue: getFilterSelectedValue('chargerId', 'chargingHistoryReport', false),
          selectOptions: chargerVisibleIdReferenceData ?? [],
          handleSelectOptionChange: (val): void => {
            handleFilterDataChange(val, 'chargerId', false)
          },
          selectDropDownId: 'charger-chargerId-filter-dropdown',
          isMultiSelect: true
        },
      },
      {
        filterLabel: 'Interval',
        filterType: 'dateFilter',
        filterId: 'chargingHistoryInterval',
        dateFilterProps: {
          datePickerId: 'charging__history__time__stamp__set__at__date__picker',
          datePickerClassName: 'charging__history__time__stamp__cleared__at__date__picker',
          selectsRange: true,
          startDate: filterSetOnValue?.[0] ? new Date(filterSetOnValue?.[0]) : null,
          endDate: filterSetOnValue?.[1] ? new Date(new Date(filterSetOnValue?.[1]).getTime()) : null,    
          onChange: (val): void => {
            handleFilterDataChange(val, 'chargingHistoryInterval', false)
          },
          // includeDateIntervals: includeDateIntervals
        }
      },
    ];
  }, [chargerVisibleIdReferenceData, screenFilters]);

  return (
    <>

      <DashboardLoader showLoader={dashboardLoader} />
      <TableDashboard
        tableId="manage-charging-history-table"
        tableClassName="manage__charging__history__table"
        header={'Charging History'}
        searchBoxIncluded={true}
        searchBoxConfiguration={searchBoxConfiguration}
        gridColumns={chargerHistoryColumns}
        tableRows={getTableRows()}
        mobileViewConfiguration={mobileViewConfiguration}
        layoutView={'list'}
        headerActionBtns={headerActionBtns}
        listLayoutCallBack={layoutCallback}
        totalCount={chargingHistoryCount}
        showSkeleton={!chargingHistoryData}
        totalCountText={'charging history'}
        showLoader={reportLoader}
        customNoDataComponent={NoDataComponentChargingHistory}
        pageNumber={chargingHistoryListTableProps?.pageNumber}
        tablePageSize={chargingHistoryListTableProps?.pageSize}
        filterProps={chargingHistoryFilterProps}
        handleFilterDataChange={handleFilterDataChange}
        referenceDataCallbacksFilters={['chargerVisibleId']}
      />
      <Menu
        id="export-option-dropdown"
        anchorEl={exportAnchorEl}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        keepMounted
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        className='export__option__dropdown'
        sx={{ top: '30px' }}
        open={Boolean(exportAnchorEl)}
        onClose={handleClose}
      >
        {renderExportMenuItem('csv', 'To CSV')}
        {renderExportMenuItem('xlsx', 'To Excel')}
      </Menu>
    </>
  );
})

export default ChargerHistoryDashboard;