/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */

import { isAxiosError } from 'axios';
import hwaxios from '../api-service/HWAxios';
import { chargerTypes, fetchAlarmSeverityReferenceData, fetchChargerStatusReferenceData, fetchCustomerReferenceData, getFilterSelectedValue, setFilterData, setGlobalFilterData } from '../reduxExports'
import cachedAPI from '../api-service/CachedAPIs';
import { cloneDeep } from 'lodash';
import rmsStore from '../rmsStore';

const url = process.env.REACT_APP_SERVER_BASE_URL

// TODO: Charger List API
export const fetchChargersData: any = (reqBody: unknown) => async (dispatch: (arg0: Partial<ChargerAction>) => void): Promise<void> => {
  try {
    const loaderStartAction: Partial<ChargerAction> = {
      type: chargerTypes.SET_CHARGER_LOADER,
      chargerTableLoader: true
    }
    dispatch(loaderStartAction);
    const response = await hwaxios.post(url + '/chargers', reqBody)
    const action: Partial<ChargerAction> = {
      type: chargerTypes.FETCH_CHARGERS_DATA,
      chargers: response.data?.data?.chargers,
      chargerCount: response.data?.data?.chargerCount,
    }
    dispatch(action)
    if (response?.status === 200) {
      const loaderEndAction: Partial<ChargerAction> = {
        type: chargerTypes.SET_CHARGER_LOADER,
        chargerTableLoader: false
      }
      dispatch(loaderEndAction);
    }
  } catch (error) {
    const loaderEndAction: Partial<ChargerAction> = {
      type: chargerTypes.SET_CHARGER_LOADER,
      chargerTableLoader: false
    }
    dispatch(loaderEndAction);
    console.error(error)
    throw error
  }
}

// ADD NEW CHARGER
export const createCharger: any = (reqParams: unknown) => async () => {
  try {
    const { data, status } = await hwaxios.post(url + '/charger', reqParams)
    return { data, status };
  } catch (error) {
    if (isAxiosError(error)) {
      if (error.response) {
        const data = error.response.data;
        const status = error.response.status;
        return { data, status };
      }
    } else {
      return {
        data: 'Something went wrong', status: 400
      }
    }
  }
}

// UPDATE CHARGER
export const updateCharger: any = (reqParams: unknown) => async () => {
  try {
    const { data, status } = await hwaxios.put(url + '/charger', reqParams)
    return { data, status };
  } catch (error) {
    if (isAxiosError(error)) {
      if (error.response) {
        const data = error.response.data;
        const status = error.response.status;
        return { data, status };
      }
    } else {
      return {
        data: 'Something went wrong', status: 400
      }
    }
  }
}

// EXPORT CHARGER LIST
export const exportToCsvForChargerList: any = (reqParams: unknown) => async () => {
  try {
    const { data, status } = await hwaxios.post(url + '/charger/export-chargers', reqParams)
    return { data, status };
  } catch (error) {
    if (isAxiosError(error)) {
      if (error.response) {
        const data = error.response.data;
        const status = error.response.status;
        return { data, status };
      }
    } else {
      return {
        data: 'Something went wrong', status: 400
      }
    }
  }
}

export const setSelectedChargerData: any = (data: ICharger) => async (dispatch: (arg0: Partial<ChargerAction>) => void): Promise<void> => {
  try {
    const action: Partial<ChargerAction> = {
      type: chargerTypes.SET_SELECTED_CHARGER_DATA,
      selectedCharger: data
    }
    dispatch(action)
  } catch (error) {
    console.error(error)
    throw error
  }
}

// TODO: Individual User Details API
export const fetchChargerDetail: any = (reqParams: unknown) => async (dispatch: (arg0: Partial<ChargerAction>) => any): Promise<any> => {
  try {
    const loaderStartAction: Partial<ChargerAction> = {
      type: chargerTypes.SET_CHARGER_LOADER,
      chargerTableLoader: true
    }
    dispatch(loaderStartAction);

    const response = await hwaxios.get(url + '/charger', { params: reqParams })
    const action: Partial<ChargerAction> = {
      type: chargerTypes.FETCH_CHARGER_DETAIL_DATA,
      chargerDetail: response?.data?.data,
    }
    dispatch(action)
    if (response?.status === 200) {
      const loaderEndAction: Partial<ChargerAction> = {
        type: chargerTypes.SET_CHARGER_LOADER,
        chargerTableLoader: false
      }
      dispatch(loaderEndAction);
    }
    return response;
  } catch (error) {
    const loaderEndAction: Partial<ChargerAction> = {
      type: chargerTypes.SET_CHARGER_LOADER,
      chargerTableLoader: false
    }
    dispatch(loaderEndAction);
    console.error(error)
    throw error
  }
}

// TODO: Individual Charger Details API
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const fetchIndividualChargerDetail: any = (reqParams: unknown) => async (dispatch: (arg0: Partial<ChargerAction>) => void): Promise<void> => {
  try {
    const loaderStartAction: Partial<ChargerAction> = {
      type: chargerTypes.SET_CHARGER_DETAIL_LOADER,
      individualChargerDetailLoader: true
    }
    dispatch(loaderStartAction);
    const response = await hwaxios.get(url + '/charger/detail', { params: reqParams })
    const action: Partial<ChargerAction> = {
      type: chargerTypes.FETCH_INDIVIDUAL_CHARGER_DETAIL_DATA,
      individualChargerDetail: response?.data?.data,
      lastRefreshTime: new Date().toISOString(),

    }
    dispatch(action)
    if (response?.status === 200) {
      const loaderEndAction: Partial<ChargerAction> = {
        type: chargerTypes.SET_CHARGER_DETAIL_LOADER,
        individualChargerDetailLoader: false
      }
      dispatch(loaderEndAction);
    }
  } catch (error) {
    const loaderEndAction: Partial<ChargerAction> = {
      type: chargerTypes.SET_CHARGER_DETAIL_LOADER,
      individualChargerDetailLoader: false
    }
    dispatch(loaderEndAction);
    console.error(error)
    throw error
  }
}

// TODO: Master Info API
export const fetchChargerMasterInfo: any = (reqParams: any) => async (dispatch: (arg0: Partial<ChargerAction>) => void): Promise<void> => {
  try {
    const loaderStartAction: Partial<ChargerAction> = {
      type: chargerTypes.SET_CHARGER_DETAIL_LOADER,
      individualChargerDetailLoader: true
    }
    dispatch(loaderStartAction);

    cachedAPI.fetchCacheFunction(`/charger/system-info/master?chargerId=${reqParams?.chargerId}`, 30 * 60 * 60, `charger-system-master-info-${reqParams?.chargerId}`).then((response) => {
      const action: Partial<ChargerAction> = {
        type: chargerTypes.FETCH_MASTER_INFO_DATA,
        chargerMasterInfo: response?.data?.data
      }
      dispatch(action)
      if (response?.status === 200) {
        const loaderEndAction: Partial<ChargerAction> = {
          type: chargerTypes.SET_CHARGER_DETAIL_LOADER,
          individualChargerDetailLoader: false
        }
        dispatch(loaderEndAction);
      }
    });


  } catch (error) {
    const loaderEndAction: Partial<ChargerAction> = {
      type: chargerTypes.SET_CHARGER_DETAIL_LOADER,
      individualChargerDetailLoader: false
    }
    dispatch(loaderEndAction);
    console.error(error)
    throw error
  }
}

// TODO: Charger System Info API
export const fetchChargerSystemInfo: any = (reqParams: unknown) => async (dispatch: (arg0: Partial<ChargerAction>) => void): Promise<void> => {
  try {
    const loaderStartAction: Partial<ChargerAction> = {
      type: chargerTypes.SET_CHARGER_DETAIL_LOADER,
      individualChargerDetailLoader: true
    }
    dispatch(loaderStartAction);
    const response = await hwaxios.get(url + '/charger/system-info', { params: reqParams })
    const action: Partial<ChargerAction> = {
      type: chargerTypes.FETCH_SYSTEM_INFO_DATA,
      chargerSystemInfo: response?.data?.data

    }
    dispatch(action)
    if (response?.status === 200) {
      const loaderEndAction: Partial<ChargerAction> = {
        type: chargerTypes.SET_CHARGER_DETAIL_LOADER,
        individualChargerDetailLoader: false
      }
      dispatch(loaderEndAction);
    }
  } catch (error) {
    const loaderEndAction: Partial<ChargerAction> = {
      type: chargerTypes.SET_CHARGER_DETAIL_LOADER,
      individualChargerDetailLoader: false
    }
    dispatch(loaderEndAction);
    console.error(error)
    throw error
  }
}


// TODO: Charger Connector Current Status API
export const fetchChargerConnectorStatus: any = (reqParams) => async (dispatch: (arg0: Partial<ChargerAction>) => void): Promise<void> => {
  try {
    const loaderStartAction: Partial<ChargerAction> = {
      type: chargerTypes.SET_CHARGER_DETAIL_LOADER,
      individualChargerDetailLoader: true
    }
    dispatch(loaderStartAction);
    const response = await hwaxios.get(url + '/connector/status', { params: reqParams })
    const action: Partial<ChargerAction> = {
      type: chargerTypes.FETCH_CHARGER_CONNECTOR_STATUS_DATA,
      chargerConnectorStatus: response?.data?.data?.connectorStatus,
      chargerNetwork: response?.data?.data?.chargerNetwork,
      lastRefreshTime: response?.data?.data?.lastRefreshTime,
    }
    dispatch(action)
    if (response?.status === 200) {
      const loaderEndAction: Partial<ChargerAction> = {
        type: chargerTypes.SET_CHARGER_DETAIL_LOADER,
        individualChargerDetailLoader: false
      }
      dispatch(loaderEndAction);
    }
  } catch (error) {
    const loaderEndAction: Partial<ChargerAction> = {
      type: chargerTypes.SET_CHARGER_DETAIL_LOADER,
      individualChargerDetailLoader: false
    }
    dispatch(loaderEndAction);
    console.error(error)
    throw error
  }
}

// TODO: Charger Graph Detail API
export const fetchChargerGraphDetail: any = (reqBody: unknown) => async (dispatch: (arg0: Partial<ChargerAction>) => unknown): Promise<unknown> => {
  try {
    const loaderStartAction: Partial<ChargerAction> = {
      type: chargerTypes.SET_CHARGER_DETAIL_LOADER,
      individualChargerDetailLoader: true
    }
    dispatch(loaderStartAction);
    const response = await hwaxios.post(url + '/charger/history', reqBody)
    const chargerPanelfilters = response?.data?.data?.filters;
    const action: Partial<ChargerAction> = {
      type: chargerTypes.FETCH_CHARGER_GRAPH_DETAIL,
      chargerHistoryGraphData: response?.data?.data
    }

    dispatch(action)
    if (response?.status === 200) {
      const loaderEndAction: Partial<ChargerAction> = {
        type: chargerTypes.SET_CHARGER_DETAIL_LOADER,
        individualChargerDetailLoader: false
      }
      dispatch(loaderEndAction);
      return { chargerPanelfilters: chargerPanelfilters, message: 'Success' }
    }
  } catch (error) {
    const loaderEndAction: Partial<ChargerAction> = {
      type: chargerTypes.SET_CHARGER_DETAIL_LOADER,
      individualChargerDetailLoader: false
    }
    dispatch(loaderEndAction);
    console.error(error)
    throw error
  }
}

// TODO: Charger Status Logs API
export const fetchChargerConnectorStatusLogs: any = (reqBody: unknown) => async (dispatch: (arg0: Partial<ChargerAction>) => void): Promise<void> => {
  try {
    const loaderStartAction: Partial<ChargerAction> = {
      type: chargerTypes.SET_CHARGER_DETAIL_LOADER,
      individualChargerDetailLoader: true
    }
    dispatch(loaderStartAction);
    const response = await hwaxios.post(url + '/charger/connector-status-logs', reqBody)
    const action: Partial<ChargerAction> = {
      type: chargerTypes.FETCH_CHARGER_STATUS_LOGS,
      chargerConnectorStatusLogs: response?.data?.data
    }
    dispatch(action)
    if (response?.status === 200) {
      const loaderEndAction: Partial<ChargerAction> = {
        type: chargerTypes.SET_CHARGER_DETAIL_LOADER,
        individualChargerDetailLoader: false
      }
      dispatch(loaderEndAction);
    }
  } catch (error) {
    const loaderEndAction: Partial<ChargerAction> = {
      type: chargerTypes.SET_CHARGER_DETAIL_LOADER,
      individualChargerDetailLoader: false
    }
    dispatch(loaderEndAction);
    console.error(error)
    throw error
  }
}

// TODO: Charger Uptime Data API

export const fetchChargerUptimeHistory: any = (reqParams: unknown) => async (dispatch: (arg0: Partial<ChargerAction>) => void): Promise<void> => {
  try {
    const loaderStartAction: Partial<ChargerAction> = {
      type: chargerTypes.SET_CHARGER_DETAIL_LOADER,
      individualChargerDetailLoader: true
    }
    dispatch(loaderStartAction);
    const response = await hwaxios.get(url + '/charger/uptime-history', { params: reqParams })
    const action: Partial<ChargerAction> = {
      type: chargerTypes.FETCH_CHARGER_UPTIME,
      chargerHistoryUptime: response?.data?.data
    }
    dispatch(action)
    if (response?.status === 200) {
      const loaderEndAction: Partial<ChargerAction> = {
        type: chargerTypes.SET_CHARGER_DETAIL_LOADER,
        individualChargerDetailLoader: false
      }
      dispatch(loaderEndAction);
    }
  } catch (error) {
    const loaderEndAction: Partial<ChargerAction> = {
      type: chargerTypes.SET_CHARGER_DETAIL_LOADER,
      individualChargerDetailLoader: false
    }
    dispatch(loaderEndAction);
    console.error(error)
    throw error
  }
}

// TODO: Master Charger status Info API
export const fetchChargerStatusMasterInfo: any = (reqParams: any) => async (dispatch: (arg0: Partial<ChargerAction>) => void): Promise<void> => {
  try {
    const loaderStartAction: Partial<ChargerAction> = {
      type: chargerTypes.SET_CHARGER_DETAIL_LOADER,
      individualChargerDetailLoader: true
    }
    dispatch(loaderStartAction);

    cachedAPI.fetchCacheFunction(`/charger/status/master?chargerId=${reqParams?.chargerId}`, 30 * 60 * 60, `charger-status-master-info-${reqParams?.chargerId}`).then((response) => {
      const action: Partial<ChargerAction> = {
        type: chargerTypes.FETCH_CHARGER_STATUS_MASTER_INFO_DATA,
        chargerStatusMasterInfo: response?.data?.data
      }
      dispatch(action)
      if (response?.status === 200) {
        const loaderEndAction: Partial<ChargerAction> = {
          type: chargerTypes.SET_CHARGER_DETAIL_LOADER,
          individualChargerDetailLoader: false
        }
        dispatch(loaderEndAction);
      }
    });


  } catch (error) {
    const loaderEndAction: Partial<ChargerAction> = {
      type: chargerTypes.SET_CHARGER_DETAIL_LOADER,
      individualChargerDetailLoader: false
    }
    dispatch(loaderEndAction);
    console.error(error)
    throw error
  }
}

// TODO: Charger System Info API
export const fetchChargerStatusSystemInfo: any = (reqParams: unknown) => async (dispatch: (arg0: Partial<ChargerAction>) => void): Promise<void> => {
  try {
    const loaderStartAction: Partial<ChargerAction> = {
      type: chargerTypes.SET_CHARGER_DETAIL_LOADER,
      individualChargerDetailLoader: true
    }
    dispatch(loaderStartAction);
    const response = await hwaxios.get(url + '/charger/status', { params: reqParams })
    const action: Partial<ChargerAction> = {
      type: chargerTypes.FETCH_CHARGER_STATUS_SYSTEM_INFO_DATA,
      chargerStatusSystemInfo: response?.data?.data

    }
    dispatch(action)
    if (response?.status === 200) {
      const loaderEndAction: Partial<ChargerAction> = {
        type: chargerTypes.SET_CHARGER_DETAIL_LOADER,
        individualChargerDetailLoader: false
      }
      dispatch(loaderEndAction);
    }
  } catch (error) {
    const loaderEndAction: Partial<ChargerAction> = {
      type: chargerTypes.SET_CHARGER_DETAIL_LOADER,
      individualChargerDetailLoader: false
    }
    dispatch(loaderEndAction);
    console.error(error)
    throw error
  }
}

// TODO: Master Charger Settings Info API
export const fetchChargerSettingsMasterInfo: any = (reqParams: any) => async (dispatch: (arg0: Partial<ChargerAction>) => void): Promise<void> => {
  try {
    const loaderStartAction: Partial<ChargerAction> = {
      type: chargerTypes.SET_CHARGER_DETAIL_LOADER,
      individualChargerDetailLoader: true
    }
    dispatch(loaderStartAction);

    cachedAPI.fetchCacheFunction(`/charger/setting/master?chargerId=${reqParams?.chargerId}`, 30 * 60 * 60, `charger-settings-master-info-${reqParams?.chargerId}`).then((response) => {
      const action: Partial<ChargerAction> = {
        type: chargerTypes.FETCH_CHARGER_SETTINGS_MASTER_INFO_DATA,
        chargerSettingsMasterInfo: response?.data?.data
      }
      dispatch(action)
      if (response?.status === 200) {
        const loaderEndAction: Partial<ChargerAction> = {
          type: chargerTypes.SET_CHARGER_DETAIL_LOADER,
          individualChargerDetailLoader: false
        }
        dispatch(loaderEndAction);
      }
    });


  } catch (error) {
    const loaderEndAction: Partial<ChargerAction> = {
      type: chargerTypes.SET_CHARGER_DETAIL_LOADER,
      individualChargerDetailLoader: false
    }
    dispatch(loaderEndAction);
    console.error(error)
    throw error
  }
}

// TODO: Charger Settings System Info  API
export const fetchChargerSettingsSystemInfo: any = (reqParams: unknown) => async (dispatch: (arg0: Partial<ChargerAction>) => void): Promise<void> => {
  try {
    const loaderStartAction: Partial<ChargerAction> = {
      type: chargerTypes.SET_CHARGER_DETAIL_LOADER,
      individualChargerDetailLoader: true
    }
    dispatch(loaderStartAction);

    const response = await hwaxios.get(url + '/charger/setting', { params: reqParams })
    const action: Partial<ChargerAction> = {
      type: chargerTypes.FETCH_CHARGER_SETTINGS_SYSTEM_INFO_DATA,
      chargerSettingsSystemInfo: response?.data?.data

    }
    dispatch(action)
    if (response?.status === 200) {
      const loaderEndAction: Partial<ChargerAction> = {
        type: chargerTypes.SET_CHARGER_DETAIL_LOADER,
        individualChargerDetailLoader: false
      }
      dispatch(loaderEndAction);
    }
  } catch (error) {
    const loaderEndAction: Partial<ChargerAction> = {
      type: chargerTypes.SET_CHARGER_DETAIL_LOADER,
      individualChargerDetailLoader: false
    }
    dispatch(loaderEndAction);
    console.error(error)
    throw error
  }
}

export const setChargerListTableProps: any = (data) => async (dispatch: (arg0: Partial<ChargerAction>) => void): Promise<void> => {
  const action: Partial<ChargerAction> = {
    type: chargerTypes.SET_CHARGER_LIST_TABLE_PROPS,
    chargerListTableProps: data,
  };
  dispatch(action);
}

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export const setRedirectedStateForChargerFilters: any = (data, chargerStatusId?: string, customerId?: string) => async (dispatch) => {
  // eslint-disable-next-line no-async-promise-executor
  return new Promise(async (resolve, reject) => {
    try {
      const action = {
        type: chargerTypes.SET_FILTERS_APPLIED_FROM_REDIRECTION,
        chargerFiltersAppliedFromRedirection: data,
      };
      const dashboardSelectedChargerType = getFilterSelectedValue('chargerType', 'dashboard', false)
      if (dashboardSelectedChargerType && data) {
        await setFilterData(dashboardSelectedChargerType,'chargerType','chargers','SET',false)(dispatch)
      }
      if (chargerStatusId === 'Offline' || chargerStatusId === 'Online' || chargerStatusId === 'all' || chargerStatusId === 'Faulty' || chargerStatusId === 'Healthy' || customerId) {
        let severitySelected: Array<string> = [];
        if (customerId) {
          if (!rmsStore.getState().referenceDataReducer?.customerReferenceData) {
            await dispatch(fetchCustomerReferenceData())
          }
          const customer = rmsStore.getState().referenceDataReducer?.customerReferenceData?.find(customer => customer?.id === customerId)
          if (customer) {
            await setFilterData(customer, 'customer', 'chargers', 'SET', true)(dispatch);
          }
        }

        if (chargerStatusId === 'Faulty') {
          chargerStatusId = 'Online'
          severitySelected.push('Critical')
        } else if (chargerStatusId === 'Healthy') {
          chargerStatusId = 'Online'
          severitySelected = severitySelected.concat(['No Alarm', 'Major', 'Minor'])
        } else if (chargerStatusId === 'Offline' || chargerStatusId === 'Online' || chargerStatusId === 'all') {
          severitySelected.push('all')
        }

        const chargerStatusReferenceDataVal = (rmsStore.getState().referenceDataReducer.chargerStatusReferenceData) ? rmsStore.getState().referenceDataReducer?.chargerStatusReferenceData : JSON.parse(await dispatch(fetchChargerStatusReferenceData()));
        if (!rmsStore.getState().referenceDataReducer?.alarmSeverityReferenceData) {
          await dispatch(fetchAlarmSeverityReferenceData())
        }
        const status = chargerStatusReferenceDataVal?.find(chargerStatus => chargerStatus?.id === chargerStatusId)
        const severity = rmsStore.getState().referenceDataReducer?.alarmSeverityReferenceData?.filter(severity => severitySelected?.includes(severity?.id))
        if (status) {
          await setFilterData(status, 'chargerStatus', 'chargers', 'SET', false)(dispatch);
        }
        if (severity && severity?.length > 0) {
          await setFilterData(severity, 'alarmStatus', 'chargers', 'SET', false)(dispatch);
        }

      }
      await dispatch(action);
      // Resolve the promise
      resolve('Action dispatched successfully');
    } catch (error) {
      // Reject the promise with the error
      reject(error);
    }
  });
};

// TODO: Update Charger Settings Parameters API
export const updateChargerSettingsParameters: any = (reqParams: unknown) => async (dispatch: (arg0: Partial<ChargerAction>) => void): Promise<unknown> => {
  try {
    const loaderStartAction: Partial<ChargerAction> = {
      type: chargerTypes.SET_CHARGER_DETAIL_LOADER,
      individualChargerDetailLoader: true
    }
    dispatch(loaderStartAction);
    const response = await hwaxios.put(url + '/charger/setting/configure', reqParams)
    if (response?.status === 200) {
      const loaderEndAction: Partial<ChargerAction> = {
        type: chargerTypes.SET_CHARGER_DETAIL_LOADER,
        individualChargerDetailLoader: false
      }
      dispatch(loaderEndAction);
      return response?.data
    }
  } catch (error) {
    const loaderEndAction: Partial<ChargerAction> = {
      type: chargerTypes.SET_CHARGER_DETAIL_LOADER,
      individualChargerDetailLoader: false
    }
    dispatch(loaderEndAction);
    console.error(error)
    throw error
  }
}

export const fetchBroadcastMessages: any = (reqBody: unknown) => async (dispatch: (arg0: Partial<ChargerAction>) => void): Promise<void> => {
  try {
    const loaderStartAction: Partial<ChargerAction> = {
      type: chargerTypes.SET_CHARGER_LOADER,
      broadcastMessageTableLoader: true
    }
    dispatch(loaderStartAction);
    const response = await hwaxios.post(url + '/broadcast-messages', reqBody)
    const action: Partial<ChargerAction> = {
      type: chargerTypes.FETCH_BROADCAST_MESSAGES,
      chargerBroadcastMessages: response.data?.data?.messages,
      broadcastMessagesCount: response.data?.data?.messageCount,
    }
    dispatch(action)
    if (response?.status === 200 || response?.status === 202) {
      const loaderEndAction: Partial<ChargerAction> = {
        type: chargerTypes.SET_BROADCAST_MESASGE_LOADER,
        broadcastMessageTableLoader: false
      }
      dispatch(loaderEndAction);
    }
  } catch (error) {
    const loaderEndAction: Partial<ChargerAction> = {
      type: chargerTypes.SET_CHARGER_LOADER,
      broadcastMessageTableLoader: false
    }
    dispatch(loaderEndAction);
    console.error(error)
    throw error
  }
}

export const setBroadcastMessageTableProps: any = (data) => async (dispatch: (arg0: Partial<ChargerAction>) => void): Promise<void> => {
  const action: Partial<ChargerAction> = {
    type: chargerTypes.SET_CHARGER_BROADCAST_MESSAGES_TABLE_PROPS,
    chargerBroadcastMessageTableProps: data,
  };
  dispatch(action);
}

export const fetchParametersData: any = (reqBody: unknown) => async (dispatch: (arg0: Partial<ChargerAction>) => void): Promise<void> => {
  try {
    const loaderStartAction: Partial<ChargerAction> = {
      type: chargerTypes.SET_PARAMETERS_LOADER,
      chargerParametersTableLoader: true
    }
    dispatch(loaderStartAction);
    const response = await hwaxios.post(url + '/charger-parameters', reqBody)
    const action: Partial<ChargerAction> = {
      type: chargerTypes.FETCH_PARAMETERS_DATA,

      chargerParameters: response.data?.data?.resultParams,
      chargerParametersCount: response.data?.data?.paramsCount,
    }

    dispatch(action)
    if (response?.status === 200 || response?.status === 202) {
      const loaderEndAction: Partial<ChargerAction> = {
        type: chargerTypes.SET_PARAMETERS_LOADER,
        chargerParametersTableLoader: false
      }
      dispatch(loaderEndAction);
    }
  } catch (error) {
    const loaderEndAction: Partial<ChargerAction> = {
      type: chargerTypes.SET_PARAMETERS_LOADER,
      chargerParametersTableLoader: false
    }
    dispatch(loaderEndAction);
    console.error(error)
    throw error
  }
}

export const setChargerParametersListTableProps: any = (data) => async (dispatch: (arg0: Partial<ChargerAction>) => void): Promise<void> => {
  const action: Partial<ChargerAction> = {
    type: chargerTypes.SET_CHARGER_PARAMETERS_LIST_TABLE_PROPS,
    chargerParametersListTableProps: data,
  };
  dispatch(action);
}

export const exportToCsvForParametersList: any = (reqParams: unknown) => async () => {
  try {
    const { data, status } = await hwaxios.post(url + '/charger-parameter/export', reqParams)
    return { data, status };
  } catch (error) {
    if (isAxiosError(error)) {
      if (error.response) {
        const data = error.response.data;
        const status = error.response.status;
        return { data, status };
      }
    } else {
      return {
        data: 'Something went wrong', status: 400
      }
    }
  }
}

export const createParameters: any = (reqParams: unknown) => async () => {
  try {
    const { data, status } = await hwaxios.post(url + '/charger-parameter', reqParams)
    return { data, status };
  } catch (error) {
    if (isAxiosError(error)) {
      if (error.response) {
        const data = error.response.data;
        const status = error.response.status;
        return { data, status };
      }
    } else {
      return {
        data: 'Something went wrong', status: 400
      }
    }
  }
}

export const setChargerParametersDevicesListTableProps: any = (data) => async (dispatch: (arg0: Partial<ChargerAction>) => void): Promise<void> => {
  const action: Partial<ChargerAction> = {
    type: chargerTypes.SET_CHARGER_PARAMETERS_DEVICES_LIST_TABLE_PROPS,
    chargerParametersDevicesListTableProps: data,
  };
  dispatch(action);
}

export const fetchParametersDevicesData: any = (reqBody: unknown) => async (dispatch: (arg0: Partial<ChargerAction>) => void): Promise<void> => {
  try {
    const loaderStartAction: Partial<ChargerAction> = {
      type: chargerTypes.SET_PARAMETERS_DEVICES_LOADER,
      chargerParametersDevicesTableLoader: true
    }
    dispatch(loaderStartAction);
    const response = await hwaxios.post(url + '/charger-parameter/devices', reqBody)
    const action: Partial<ChargerAction> = {
      type: chargerTypes.FETCH_PARAMETERS_DEVICES_DATA,

      chargerParametersDevices: response.data?.data?.devices,
      chargerParametersDevicesCount: response.data?.data?.total,
    }


    dispatch(action)
    if (response?.status === 200 || response?.status === 202) {
      const loaderEndAction: Partial<ChargerAction> = {
        type: chargerTypes.SET_PARAMETERS_DEVICES_LOADER,
        chargerParametersDevicesTableLoader: false
      }
      dispatch(loaderEndAction);
    }
  } catch (error) {
    const loaderEndAction: Partial<ChargerAction> = {
      type: chargerTypes.SET_PARAMETERS_DEVICES_LOADER,
      chargerParametersDevicesTableLoader: false
    }
    dispatch(loaderEndAction);
    console.error(error)
    throw error
  }
}

export const fetchParameterDevicesStatusData: any = (reqParams) => async (dispatch): Promise<void> => {
  try {
    const response = await hwaxios.post(url + '/charger-parameter/count', reqParams);

    const action: Partial<ChargerAction> = {
      type: chargerTypes.FETCH_PARAMETER_DEVICES_STATUS_COUNT,
      parameterDevicesStatusCount: response?.data?.data,
    };
    dispatch(action);
  } catch (error) {
    console.error(error);

    throw error;
  }
};

export const fetchAddParametersListDetail: any = (reqParams: unknown) => async (dispatch: (arg0: Partial<ChargerAction>) => void): Promise<void> => {
  try {
    const loaderStartAction: Partial<ChargerAction> = {
      type: chargerTypes.FETCH_ADD_PARAMETERS_DETAIL_LIST,
      addParametersDetailsListLoader: true
    }
    dispatch(loaderStartAction);
    const response = await hwaxios.get(url + '/charger/setting/master/charger-level', { params: reqParams })
    const action: Partial<ChargerAction> = {
      type: chargerTypes.FETCH_ADD_PARAMETERS_DETAIL_LIST,
      addParametersDetailsList: response?.data?.data,

    }



    dispatch(action)
    if (response?.status === 200) {
      const loaderEndAction: Partial<ChargerAction> = {
        type: chargerTypes.FETCH_ADD_PARAMETERS_DETAIL_LIST,
        addParametersDetailsListLoader: false
      }
      dispatch(loaderEndAction);
    }
  } catch (error) {
    const loaderEndAction: Partial<ChargerAction> = {
      type: chargerTypes.FETCH_ADD_PARAMETERS_DETAIL_LIST,
      addParametersDetailsListLoader: false
    }
    dispatch(loaderEndAction);
    console.error(error)
    throw error
  }
}

export const fetchAddParameterRefChargingStations: any = (reqParams) => async (dispatch): Promise<void> => {
  try {
    const response = await hwaxios.get(url + '/ref/charging-station', reqParams);

    const action: Partial<ChargerAction> = {
      type: chargerTypes.FETCH_ADD_PARAMETERS_REF_CHARGING_STATIONS,
      addParameterRefChargingStations: response?.data?.data,
    };
    dispatch(action);
  } catch (error) {
    console.error(error);

    throw error;
  }
};

export const fetchAddParameterRefChargers: any = (reqParams) => async (dispatch): Promise<void> => {
  try {
    const response = await hwaxios.get(url + '/ref/charger-visible-id', reqParams);

    const action: Partial<ChargerAction> = {
      type: chargerTypes.FETCH_ADD_PARAMETERS_REF_CHARGERS,
      addParameterRefChargers: response?.data?.data,
    };
    dispatch(action);
  } catch (error) {
    console.error(error);

    throw error;
  }
};

export const fetchParametersSettingsSystemInfo: any = (reqParams: unknown) => async (dispatch: (arg0: Partial<ChargerAction>) => void): Promise<void> => {
  try {
    const loaderStartAction: Partial<ChargerAction> = {
      type: chargerTypes.SET_PARAMETERS_DETAIL_LOADER,
      parametersDetailLoader: true
    }
    dispatch(loaderStartAction);
    const response = await hwaxios.post(url + '/charger/setting/values', reqParams)
    const action: Partial<ChargerAction> = {
      type: chargerTypes.FETCH_PARAMETERS_SETTINGS_SYSTEM_INFO_DATA,
      parametersSettingsSystemInfo: response?.data?.data
    }
    dispatch(action)
    if (response?.status === 200) {
      const loaderEndAction: Partial<ChargerAction> = {
        type: chargerTypes.SET_PARAMETERS_DETAIL_LOADER,
        parametersDetailLoader: false
      }
      dispatch(loaderEndAction);
    }
  } catch (error) {
    const loaderEndAction: Partial<ChargerAction> = {
      type: chargerTypes.SET_PARAMETERS_DETAIL_LOADER,
      parametersDetailLoader: false
    }
    dispatch(loaderEndAction);
    console.error(error)
    throw error
  }
}

export const deleteParameter: any = (reqParams: unknown) => async () => {
  try {
    const { data, status } = await hwaxios.delete(url + '/charger-parameter', { params: reqParams })
    return { data, status };
  } catch (error) {
    if (isAxiosError(error)) {
      if (error.response) {
        const data = error.response.data;
        const status = error.response.status;
        return { data, status };
      }
    } else {
      return {
        data: 'Something went wrong', status: 400
      }
    }
  }
}
export const setParametersExpandedToggleIds:any = (data) => async (dispatch: (arg0: Partial<ChargerAction>) => void): Promise<void> => {
  const action: Partial<ChargerAction> = {
    type: chargerTypes.SET_PARAMETERS_TOGGLE_IDS,
    parametersToggleExpandedIds: data ,
  };
  dispatch(action);
};

export const fetchParametersSettingsMasterInfo:any = (reqParams: unknown) => async (dispatch: (arg0: Partial<ChargerAction>) => void): Promise<void> => {
  try {
    const loaderStartAction: Partial<ChargerAction> = {
      type: chargerTypes.SET_PARAMETERS_DETAIL_LOADER,
      parametersDetailLoader: true
    }
    dispatch(loaderStartAction);
    const response = await hwaxios.post(url + '/charger/setting/master-common',  reqParams)
    const action: Partial<ChargerAction> = {
      type: chargerTypes.FETCH_CHARGER_PARAMETERS_SETTINGS_MASTER_INFO_DATA,
     chargerParametersSettingsMasterInfo: response?.data?.data
    }
    dispatch(action)
    if (response?.status === 200) {
      const loaderEndAction: Partial<ChargerAction> = {
        type: chargerTypes.SET_PARAMETERS_DETAIL_LOADER,
        parametersDetailLoader: false
      }
      dispatch(loaderEndAction);
    }
  } catch (error) {
    const loaderEndAction: Partial<ChargerAction> = {
      type: chargerTypes.SET_PARAMETERS_DETAIL_LOADER,
      parametersDetailLoader: false
    }
    dispatch(loaderEndAction);
    console.error(error)
    throw error
  }
}

export const fetchIndividualParameterDetail: any = (reqParams: unknown) => async (dispatch: (arg0: Partial<ChargerAction>) => void): Promise<void> => {
  try {
    const loaderStartAction: Partial<ChargerAction> = {
      type: chargerTypes.SET_PARAMETERS_DETAIL_LOADER,
      parametersDetailLoader: true
    }
    dispatch(loaderStartAction);
    const response = await hwaxios.get(url + '/charger-parameter', { params: reqParams })
    const action: Partial<ChargerAction> = {
      type: chargerTypes.FETCH_INDIVIDUAL_PARAMETER_DETAIL_DATA,
      individualParameterDetail: response?.data?.data,
    }
    dispatch(action)
    if (response?.status === 200) {
      const loaderEndAction: Partial<ChargerAction> = {
        type: chargerTypes.SET_PARAMETERS_DETAIL_LOADER,
        parametersDetailLoader: false
      }
      dispatch(loaderEndAction);
    }
  } catch (error) {
    const loaderEndAction: Partial<ChargerAction> = {
      type: chargerTypes.SET_PARAMETERS_DETAIL_LOADER,
      parametersDetailLoader: false
    }
    dispatch(loaderEndAction);
    console.error(error)
    throw error
  }
}

export const updateParameters: any = (reqParams: unknown) => async () => {
  try {
    const { data, status } = await hwaxios.put(url + '/charger-parameter',  reqParams )
    return { data, status };
  } catch (error) {
    if (isAxiosError(error)) {
      if (error.response) {
        const data = error.response.data;
        const status = error.response.status;
        return { data, status };
      }
    } else {
      return {
        data: 'Something went wrong', status: 400
      }
    }
  }
}