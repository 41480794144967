/* eslint-disable @typescript-eslint/no-unused-vars */

import React, { memo, } from 'react'
import ChargerPanelRemoteCommandsComponent from './ChargerPanelRemoteCommands'

interface ChargerPanelAdminActions { contentHeight: number }

const ChargerPanelAdminActionsDashboard = memo(

  function ChargerPanelRemoteCommandsDashboard({ contentHeight }: ChargerPanelAdminActions) {
    const adminActionsNavList = [
      { buttonText: 'FTP Log Upload', buttonIconClassName: 'panel_ftp_log_upload_icon', buttonId: 'ftp_log_upload' },
      { buttonText: 'FTP Certificate Upgrade', buttonIconClassName: 'panel_ftp_certificate_upgrade_icon', buttonId: 'ftp_certificate_upgrade'},
      { buttonText: 'FTP Screen Saver Images Upgrade', buttonIconClassName: 'panel_ftp_screen_saver_images_upgrade_icon', buttonId: 'ftp_screen_saver_images_upgrade'},
      { buttonText: 'Clear Charge Records', buttonIconClassName: 'panel_clear_charge_records_icon', buttonId: 'clear_charge_records'},
      { buttonText: 'Clear History Alarm Records', buttonIconClassName: 'panel_clear_history_alarm_records_icon', buttonId: 'clear_history_alarm_records'},
      { buttonText: 'Clear Authorization Cache', buttonIconClassName: 'panel_clear_authorization_cache_icon', buttonId: 'clear_authorization_cache'},
      { buttonText: 'Clear Local List', buttonIconClassName: 'panel_clear_local_list_icon', buttonId: 'clear_local_list'},
      { buttonText: 'Clear All Pending Txn Data', buttonIconClassName: 'panel_clear_txn_data_icon', buttonId: 'clear_all_pending_txn_data'},
      { buttonText: 'Clear Next Pending Txn Data', buttonIconClassName: 'panel_clear_txn_data_icon', buttonId: 'clear_next_pending_txn_data'}
    ]
    return (<ChargerPanelRemoteCommandsComponent contentHeight={contentHeight} commands={adminActionsNavList}></ChargerPanelRemoteCommandsComponent>)
  }
)

export default ChargerPanelAdminActionsDashboard
