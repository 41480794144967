
import { LinearProgress } from '@mui/material';
import React, { memo, useCallback, useEffect, useState } from 'react';
import { SelectDropDown } from '../globalExports';
import './AutoRefresh.css';
import { useLocation } from 'react-router-dom';
import { formatRelativeDateTime } from '../globalHooks';
import PageVisibility from 'react-page-visibility/dist/umd/PageVisibility'
import HWLocalStorage from '../../rmsReduxStore/api-service/HWLocalStorage';
interface AutoRefreshProps {
  // eslint-disable-next-line @typescript-eslint/ban-types
  onRefreshCallback: Function,
  containerClassName: string,
  pathName: string,
  refreshDate: string | Date,
  refreshLocation: boolean
}

interface IAutoRefreshOptionType {
  id: string, value: number, label: string, timerLabel: string
}

const AutoRefresh = memo(function AutoRefresh(props: AutoRefreshProps) {
  const location = useLocation();
  const { containerClassName, onRefreshCallback, pathName, refreshDate, refreshLocation } = props;
  const autoRefreshIntervals = [
    { id: 'off', value: 0, label: 'Off', timerLabel: 'Off' },
    { id: '30_seconds', value: 30000, label: '30 seconds', timerLabel: '30 seconds ago' },
    { id: '1_minute', value: 60000, label: '1 minute', timerLabel: '1 minute ago' },
    { id: '5_minutes', value: 300000, label: '5 minutes', timerLabel: '5 minutes ago' },
    { id: '15_minutes', value: 1500000, label: '15 minutes', timerLabel: '15 minutes ago' },
  ];

  const [autoRefreshInterval, setAutoRefreshInterval] = useState<IAutoRefreshOptionType>(
    { id: '1_minute', value: 60000, label: '1 minute', timerLabel: '1 minute ago' },);
  const [refreshTime, setRefreshTime] = useState(60000);
  const [newRefreshDate, setNewRefreshDate] = useState<string | Date>()
  const [showAnimation, setShowAnimation] = useState(false);

  useEffect(() => {
    const historyInterval = setInterval(() => {
      if (autoRefreshInterval && autoRefreshInterval?.id !== 'off') {
        setRefreshTime((prevTimer) =>
          prevTimer === 0 ? autoRefreshInterval.value : prevTimer - 1000
        );
      } else {
        setRefreshTime(0);
      }
    }, 1000);

    return (): void => clearInterval(historyInterval);
  }, [autoRefreshInterval]);

  useEffect(() => {
    const dateTimeInterval = setInterval(() => {
      if (refreshDate) {
                const formattedDate = formatRelativeDateTime(new Date(refreshDate));
        setNewRefreshDate(formattedDate);
      }
    }, 2000);
    return (): void => {
      clearInterval(dateTimeInterval)
    };
  }, [refreshDate, autoRefreshInterval?.id]);

  const handleRefresh = useCallback(() => {
    onRefreshCallback?.();
  }, [setNewRefreshDate, onRefreshCallback])

  useEffect(() => {
    if (refreshTime === autoRefreshInterval?.value && pathName === location?.pathname && autoRefreshInterval?.id !== 'off') {
            setShowAnimation(true);
      handleRefresh();
      setTimeout(() => {
        setShowAnimation(false);
      }, 2000)
    }
  }, [refreshTime, autoRefreshInterval?.id])

  // On page visible, Reset the refresh timer, its will trigger handle refresh to refresh the page.
  const onVisibiltyChange = useCallback((isVisible) => {
    if (!isVisible) {
      setAutoRefreshInterval({ id: 'off', value: 0, label: 'Off', timerLabel: 'Off' });
      return;
    }
    const cachedOption = HWLocalStorage.load('autoRefreshOption') as unknown as IAutoRefreshOptionType
    if (cachedOption) {
      setRefreshTime(cachedOption.value);
      setAutoRefreshInterval(cachedOption)
    }
    // setRefreshTime(0);
  }, [handleRefresh, setRefreshTime])

  useEffect(() => {
    const cachedOption = HWLocalStorage.load('autoRefreshOption') as unknown as IAutoRefreshOptionType
    if (cachedOption) {
      setRefreshTime(cachedOption.value);
      setAutoRefreshInterval(cachedOption);
    } else {
      setAutoRefreshInterval({ id: '1_minute', value: 60000, label: '1 minute', timerLabel: '1 minute ago' })
    }
  }, [])

  return (
    <>
    <div style={{display:'flex',flexDirection: refreshLocation ?  'row-reverse' : 'row'}} >
      <div className="app__dashboard__header-grey-text">
        {newRefreshDate?.toString() ? newRefreshDate?.toString() : '0 seconds ago'}
      </div>
      <PageVisibility onChange={onVisibiltyChange} />   {/* Check for access token expiry time. */}
        <div className={containerClassName}>
          <div className="auto__refresh__component">
            <div className="auto__refresh__component-circular-progress-wrap"
              onClick={(e) => { e?.stopPropagation(); handleRefresh(); setRefreshTime(0); }}>
              <div className={showAnimation ? 'rms__dashboard__refresh__icon animation rms__icon' : 'rms__dashboard__refresh__icon rms__icon'}></div>
            </div>
            <div className="auto__refresh__component-dropdown-wrap">
              <SelectDropDown
                selectValue={autoRefreshInterval} selectOptions={autoRefreshIntervals ?? []}
                handleSelectOptionChange={(val) => {
                  setRefreshTime(0);
                  setAutoRefreshInterval(val);
                  if (val?.id !== 'off') {
                    const formattedDate = formatRelativeDateTime(new Date());
                    setNewRefreshDate(formattedDate)
                  }
                  HWLocalStorage?.save('autoRefreshOption', val)
                }}
                selectDropDownId="auto-refresh-dropdown" selectDropDownName="auto__refresh__dropdown"
              />
            </div>
          </div>
          <LinearProgress className="auto-refresh-linear-progress-bar" variant="determinate"
            value={autoRefreshInterval?.id === 'off' ? 0 : ((refreshTime * 100) / autoRefreshInterval?.value)}
          />
        </div>
    </div> 
    </>
  );
})

export default AutoRefresh;
