import { cloneDeep } from 'lodash';
import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import {
  CustomBreadCrumbs,
  CustomButtonGroup,
  CustomForm,
  DashboardHeader,
  DashboardLoader,
  TableSubHeader,
  renderHeaderActionButtons,
  useAppDispatch,
  useAppSelector,
  useEffectOnce,
} from '../../../globalUtils/globalExports';
import { Id, toast } from 'react-toastify';
import { useLocation, useNavigate } from 'react-router-dom';
import { AxiosResponse } from 'axios';
import { isValidPhoneNumber } from 'libphonenumber-js';
import { createCustomer, customerTypes, fetchCustomerReferenceData, fetchIndividualCustomerDetail, getCustomerReducer, updateCustomer } from '../../../rmsReduxStore/reduxExports';
import { Icon, IconWithTooltip } from '../../../globalUtils/globalIcons';
import { ICustomFormProps, IFormContentProp } from '../../../globalUtils/CustomForm/CustomForm';
import './CustomerForm.css'
import { checkCharactersPaste, checkPINCodePaste, createBreadCrumbProps, keyPressValidationForCharacters, keyPressValidationForPINCode } from '../../../globalUtils/globalHooks';
import ChargerListing from './chargerListing';
import access from '../../../auth/service/AccessControl';


export interface ICustomerForm {
  name: string;
  contactPerson: string | null;
  emailAddress: string | null;
  countryCode: string | null;
  mobileNumber: string | null
  addressLine1: string | null;
  addressLine2: string | null;
  pinCode: string | null;
  city: string | null;
  state: string | null;
  notes: string | null;
}

const CustomerForm: FC = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const { individualCustomerDetail } = useAppSelector(getCustomerReducer);
  const initialChargerFormData = {
    name: '',
    contactPerson: null,
    emailAddress: null,
    countryCode:'',
    mobileNumber: null,
    addressLine1: null,
    addressLine2: null,
    pinCode: null,
    city: null,
    state: null,
    notes: null
  };

  const [addCustomerFormData, setAddCustomerFormData] = useState<ICustomerForm>(initialChargerFormData);
  const [initialCustomerData, setInitialCustomerData] = useState<ICustomerForm>(initialChargerFormData);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [customerId, setCustomerId] = useState(null);
  const [emailError, setEmailError] = useState('');
  const [mobileError, setMobileError] = useState('');
  const [mode, setMode] = useState<'View' | 'Edit' | 'Add' | undefined>(location?.state?.mode ?? 'View');
  const [showLoader, setShowLoader] = useState(false);

  useEffectOnce(() => {
    if (location?.state?.rowData || location?.state?.name) {
      setAddCustomerFormData({
        ...addCustomerFormData,
        name: location?.state?.rowData?.name || location?.state?.name ,
      })
    }
    dispatch(fetchCustomerReferenceData());
    setMode(location?.state?.mode || 'View');
    const id = location?.state?.id;
    if (id != null) {
      setCustomerId(id);
      initialiseData(id);
    } else {
      setCustomerId(null);
    }
  });


  useEffect(() => {
    loadInitialData()
  }, [individualCustomerDetail])


  const loadInitialData = () => {
    if (individualCustomerDetail != null) {
      const customerDetail = {
        name: individualCustomerDetail?.name,
        contactPerson: individualCustomerDetail?.contactPerson,
        emailAddress: individualCustomerDetail?.emailAddress,
        countryCode: individualCustomerDetail?.countryCode,
        mobileNumber: individualCustomerDetail?.mobileNumber ? individualCustomerDetail?.countryCode + individualCustomerDetail?.mobileNumber : individualCustomerDetail?.mobileNumber  ,
        addressLine1: individualCustomerDetail?.addressLine1,
        addressLine2: individualCustomerDetail?.addressLine2,
        pinCode: individualCustomerDetail?.pinCode,
        city: individualCustomerDetail?.city,
        state: individualCustomerDetail?.state,
        notes: individualCustomerDetail?.notes
      };
      setInitialCustomerData(customerDetail)
      setAddCustomerFormData(customerDetail);
    }
  }

  const initialiseData = async (customerId: string): Promise<void> => {
    await dispatch(fetchIndividualCustomerDetail({
      customerId: customerId,
    }));
  }

  const onMobileNumberChange = (value: string,countryCode,dialCode): void => {
    const isValidMobileNumber = isValidPhoneNumber(`+${value}`, countryCode);
    if (!isValidMobileNumber && value?.slice(dialCode?.length)?.length > 0) {
      setMobileError('Please enter a valid mobile number.')
    } else {
      setMobileError('')
    }
    handleFormDataChange(value, 'mobileNumber', dialCode);
  }

  const handleFormDataChange = useCallback((val, formKey,mobileDialCode?) => {
    const formData = cloneDeep(addCustomerFormData);
    if (formKey === 'mobileNumber') formData['countryCode'] = mobileDialCode
    formData[formKey] = val;
    setAddCustomerFormData(formData);
  }, [addCustomerFormData]);

  const disableAddButton = useCallback(() => {
    return (!(
      addCustomerFormData?.name?.length > 0 &&
      emailError?.length == 0 &&
      mobileError?.length == 0
    ))
  }, [addCustomerFormData])

  const disableEditButton = useCallback(() => {
    let formModified = false;
    const keys = Object.keys(addCustomerFormData);
    if (initialCustomerData != null) {
      for (let i = 0; i < keys.length; i++) {
        const key = keys[i];
        if (initialCustomerData[key] !== addCustomerFormData[key]) {
          formModified = true;
          break
        }
      }
    }

    if (!formModified) return true;

    return (!(
      addCustomerFormData?.name?.length > 0 &&
      emailError?.length == 0 &&
      mobileError?.length == 0
    ))
  }, [addCustomerFormData, individualCustomerDetail])

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const disableCancelButton = useCallback(() => {
    const hasFormChanged = Object.keys(addCustomerFormData).some(
      (key) => addCustomerFormData[key] !== initialChargerFormData[key]
    );
    return !hasFormChanged
  }, [addCustomerFormData])


  const customerFormSuccessToast = (): Id => toast.success(`Customer ${mode}ed.`);
  const duplicateCustomerToast = (): Id => toast.warn('Customer with same ID exists.');
  const somethingWentWrongToast = (): Id => toast.warn('Something went wrong.');

  const btnsList = useMemo(() => {
    const disableCheck = mode === 'Edit' ? disableEditButton : disableAddButton
    return [
      {
        buttonText: mode === 'Edit' ? 'Update' : 'Add',
        buttonId: 'submit',
        btnClassName: disableCheck() ? 'primary__btn disabled' : 'primary__btn',
        handleClick: (): void => {
          handleSubmit?.(mode)
        },
        isDisabled: disableCheck(),
        buttonVariant: 'filled',
      },
      {
        buttonText: 'Cancel',
        buttonId: 'cancel',
        btnClassName: 'secondary__btn',
        handleClick: (): void => {
          navigate('/customers')
        },
        buttonVariant: 'outlined',
      },
    ];
  }, [addCustomerFormData, mode, disableAddButton]);

  const validateEmail = useCallback((email: string) => {
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return regex.test(email);
  }, []);

  const headerActionBtns = renderHeaderActionButtons({
    actionBtns:
      [
        {
          id: 'add_new_charger',
          isAccessible: access?.chargers?.add(),
          headerActionBtnClick: (): void => {
            navigate('/add-charger', { state: { from: '/customer', name: addCustomerFormData?.name, id: customerId, customerMode: 'Edit' } })
          }
        },
        {
          id: 'add_new_charging_station',
          isAccessible: access?.chargers?.add(),
          headerActionBtnClick: (): void => {
            navigate('/add-station', { state: { from: '/customer', id: customerId, name: addCustomerFormData?.name, mode: 'Edit' } })
          }
        },
      ]
  });

  const formContent: IFormContentProp[] =
    [
      {
        rowCountClassName: 'grid-3',
        sectionHeading: 'Personal Information',
        formObject: [
          {
            formLabel: 'Customer',
            isRequired: true,
            objectType: 'input',
            selectDropdownProps: null,
            inputProps: {
              inputPlaceHolder: 'Type here',
              handleInputChange: (event): void => {
                handleFormDataChange(event?.target?.value, 'name');
              },
              inputValue: addCustomerFormData?.name,
              inputType: 'string',
              inputFieldId: 'customer_name',
              isRequired: true,
              isDisabled: mode === 'View',
              autoFocus: true, 
            },
            textAreaProps: null,
          },
          {
            formLabel: 'Contact Person Name',
            isRequired: false,
            objectType: 'input',
            selectDropdownProps: null,
            inputProps: {
              inputPlaceHolder: mode !== 'View' ? 'Type here' : '',
              handleInputChange: (event): void => {
                handleFormDataChange(event?.target?.value, 'contactPerson');
              },
              inputValue: addCustomerFormData?.contactPerson ?? '',
              inputType: 'string',
              inputFieldId: 'adding_station',
              isRequired: false,
              isDisabled: mode === 'View',
            },
            textAreaProps: null,
          },
          {
            formLabel: 'Mobile Number',
            isRequired: false,
            objectType: 'mobile-number',
            selectDropdownProps: null,
            onMobileNumberChange: onMobileNumberChange,
            inputProps: {
              errorMessage: mobileError,
              inputValue: addCustomerFormData?.mobileNumber ?? '',
              inputType: 'string',
              inputFieldId: 'mobile_number',
              isRequired: false,
              isDisabled: mode === 'View'
            },
            textAreaProps: null,
          },
        ],
      },
      {
        rowCountClassName: '',
        formObject: [
          {
            formLabel: 'Email Address',
            isRequired: false,
            objectType: 'input',
            selectDropdownProps: null,
            inputProps: {
              inputPlaceHolder: mode !== 'View' ? 'Enter Email' : '',
              handleInputChange: (event): void => {
                if (!validateEmail(event?.target?.value) && event?.target?.value?.length > 0) {
                  setEmailError('Please enter a valid email address.');
                } else {
                  setEmailError('');
                }
                handleFormDataChange(event?.target?.value, 'emailAddress');
              },
              inputValue: addCustomerFormData?.emailAddress ?? '',
              inputFieldId: 'add_customer_email',
              isRequired: false,
              isDisabled: mode === 'View',
              errorMessage: emailError
            },
            textAreaProps: null,
          },
        ],
      },
      {
        rowCountClassName: '',
        sectionHeading: 'Address Detail',
        formObject: [
          {
            formLabel: 'Address',
            isRequired: false,
            objectType: 'input',
            selectDropdownProps: null,
            inputProps: {
              inputPlaceHolder: mode !== 'View' ? 'Enter Address Line 1' : '',
              handleInputChange: (event): void => {
                handleFormDataChange(event?.target?.value, 'addressLine1');
              },
              inputValue: addCustomerFormData?.addressLine1 ?? '',
              inputType: 'string',
              className: 'form-column-66',
              inputFieldId: 'address_line_1',
              isRequired: false,
              isDisabled: mode === 'View'
            },
            textAreaProps: null,
          },
        ],
      },
      {
        rowCountClassName: '',
        formObject: [
          {
            formLabel: '',
            isRequired: false,
            objectType: 'input',
            selectDropdownProps: null,
            inputProps: {
              inputPlaceHolder: mode !== 'View' ? 'Enter Address Line 2' : '',
              handleInputChange: (event): void => {
                handleFormDataChange(event?.target?.value, 'addressLine2');
              },
              inputValue: addCustomerFormData?.addressLine2 ?? '',
              inputType: 'string',
              className: 'form-column-66',
              inputFieldId: 'address_line_2',
              isRequired: false,
              isDisabled: mode === 'View'
            },
            textAreaProps: null,
          },
        ],
      },
      {
        rowCountClassName: 'grid-4',
        formObject: [
          {
            formLabel: 'City',
            isRequired: false,
            objectType: 'input',
            selectDropdownProps: null,
            inputProps: {
              inputPlaceHolder: mode !== 'View' ? 'Enter City' : '',
              handleInputChange: (event): void => {
                handleFormDataChange(checkCharactersPaste(event?.target?.value), 'city');
              },
              inputValue: addCustomerFormData?.city ?? '',
              inputType: 'string',
              className: 'form-column-33',
              inputFieldId: 'city',
              isRequired: true,
              isDisabled: mode === 'View',
              handleKeyPress: keyPressValidationForCharacters
            },
            textAreaProps: null,
          },
          {
            formLabel: 'State',
            isRequired: false,
            objectType: 'input',
            selectDropdownProps: null,
            inputProps: {
              inputPlaceHolder: mode !== 'View' ? 'Enter State' : '',
              handleInputChange: (event): void => {
                handleFormDataChange(checkCharactersPaste(event?.target?.value), 'state');
              },
              inputValue: addCustomerFormData?.state ?? '',
              inputType: 'string',
              className: 'form-column-33',
              inputFieldId: 'state',
              isRequired: true,
              isDisabled: mode === 'View',
              handleKeyPress: keyPressValidationForCharacters
            },
            textAreaProps: null,
          },
          {
            formLabel: 'PIN Code',
            isRequired: false,
            objectType: 'input',
            selectDropdownProps: null,
            inputProps: {
              inputPlaceHolder: mode !== 'View' ? 'Enter PIN Code' : '',
              handleInputChange: (event): void => {
                handleFormDataChange(checkPINCodePaste(event), 'pinCode');
              },
              inputValue: addCustomerFormData?.pinCode ?? '',
              inputType: 'string',
              className: 'form-column-66',
              inputFieldId: 'pin_code',
              isRequired: false,
              isDisabled: mode === 'View',
              handleKeyPress: keyPressValidationForPINCode
            },
            textAreaProps: null,
          },
        ],
      },
      {
        rowCountClassName: 'grid-1',
        formObject: [
          {
            formLabel: 'Notes',
            isRequired: false,
            objectType: 'text-area',
            selectDropdownProps: null,
            inputProps: null,
            textAreaProps: {
              inputPlaceHolder: 'Type here',
              handleInputChange: (event) => {
                handleFormDataChange(event?.target?.value, 'notes')
              },
              isRequired: false,
              inputValue: addCustomerFormData?.notes ?? '',
              inputFieldId: 'adding_station',
              isDisabled: mode === 'View'
            },
          },
        ],
      },
    ];

  const customFormPropsPersonalDetail: ICustomFormProps = useMemo(() => {
    return {
      formContainerClassName: '',
      formArray: formContent,
    };
  }, [addCustomerFormData, mode])

  // TODO: BreadCrumb navigation Props
  const breadCrumbs = createBreadCrumbProps({
    breadCrumbProps:
      [
        {
          objectType: 'link',
          id: 'customers',
        },
        {
          objectType: 'text',
          id: 'text',
          text: mode !== 'Add' ? addCustomerFormData?.name || location?.state?.rowData?.name || location?.state?.name : `${mode} Customer`
        }
      ]
  })

  // TODO: FORM HEADER TEXT *****
  const setHeaderText = useCallback(() => {
    return mode !== 'Add' ? addCustomerFormData?.name || location?.state?.rowData?.name || location?.state?.name : `${mode} Customer`
  }, [mode,addCustomerFormData])

  //TODO: SUBMIT FORM *******
  const handleSubmit = useCallback(async (mode) => {
    setShowLoader(true)
    const reqBody = {
      id: mode === 'Edit' ? individualCustomerDetail?.id : null,
      name: addCustomerFormData?.name,
      contactPerson: addCustomerFormData?.contactPerson,
      emailAddress: addCustomerFormData?.emailAddress,
      countryCode:addCustomerFormData?.countryCode?.includes('+') ? addCustomerFormData?.countryCode  : '+' + addCustomerFormData?.countryCode,
      mobileNumber: addCustomerFormData?.mobileNumber?.slice(addCustomerFormData?.countryCode?.length),
      addressLine1: addCustomerFormData?.addressLine1,
      addressLine2: addCustomerFormData?.addressLine2,
      pinCode: addCustomerFormData?.pinCode,
      city: addCustomerFormData?.city,
      state: addCustomerFormData?.state,
      notes: addCustomerFormData?.notes
    }
    let response: AxiosResponse
    if (mode === 'Edit') {
      response = await dispatch(updateCustomer(reqBody));
    } else {
      response = await dispatch(createCustomer(reqBody));
    }
    if (response?.status === 200 || response?.status === 202) {
      setShowLoader(false)
      customerFormSuccessToast();
      setTimeout(() => {
        navigate('/customers');
      }, 2000)
    }
    else if (response.data === 'Customer with same Id exists') {
      setShowLoader(false)
      duplicateCustomerToast();
    } else {
      setShowLoader(false)
      somethingWentWrongToast();
    }
  }, [mode, addCustomerFormData])

  // CLEAN UP ON UNMOUNT
  useEffect(() => {
    // Method to call just before the component unmounts
    const cleanup = () => {
      cleanUpIndividualCustomerDetail();
    };
    return cleanup;
  }, []);

  const cleanUpIndividualCustomerDetail = (): void => {
    const action = {
      type: customerTypes.CLEAR_CUSTOMER_DETAIL_DATA,
    }
    dispatch(action);
  }

  return (
    <>
      <DashboardLoader showLoader={showLoader} />
      <div className={'customer__form__wrap '}>
        <div className='customer__form__wrap-header-link-row'>
          <CustomBreadCrumbs breadCrumbs={breadCrumbs} />
        </div>

        <div className='customer__form__wrap-header-row'>
          <div className='customer__form__wrap-header'>
            <DashboardHeader header={setHeaderText()} />
            {mode !== 'Add' && access?.customers?.edit() && <Icon iconClassName={mode === 'View' ? 'rms__pencil__icon' : 'rms__view__icon'} containerClassName='org__form_header-edit' onIconClick={() => {
              if (mode === 'Edit') loadInitialData()
              setMode(mode === 'View' ? 'Edit' : 'View')
            }
            } />}
          </div>
          {mode === 'Edit' && <div className='customer__form__wrap-header-wrap-buttons'>
            <TableSubHeader headerActionBtns={headerActionBtns} />
            <IconWithTooltip
              key={''}
              toolTipText={'View Update History'}
              onIconClick={() => { navigate('/edit-history', { state: { id: customerId, mode: mode,name:addCustomerFormData?.name } }) }}
              iconClassName={'rms_history_outlined_icon_customer'}
            />
          </div>}
        </div>

        <div className='customer__form__wrap-content'>
          <CustomForm {...customFormPropsPersonalDetail} />
          {(mode === 'Edit' || mode === 'View') && customerId && <div className='customer__form__wrap-header-text'>Chargers</div>}
          {(mode === 'Edit' || mode === 'View') && customerId && <ChargerListing key={'customerChargerList ' + customerId} customerId={customerId} />}
        </div>

        <div className='customer__form__wrap-footer'>
          <CustomButtonGroup
            buttonsList={mode === 'View' ? btnsList?.filter((button) => button?.buttonId === 'cancel') : btnsList}
            buttonGroupClassName={'button__group__footer'}
          />
        </div>
      </div>
    </>
  );
};

export default CustomerForm;



