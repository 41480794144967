/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { FC, useCallback, useMemo, useState, type ChangeEvent } from 'react';
import { cloneDeep } from 'lodash';
import {
  useAppDispatch,
  useAppSelector,
  useEffectOnce,
  RadioButton,
  DashboardHeader,
  LinkButton
} from '../../../globalUtils/globalExports';
import { fetchChargerModelReferenceData, getReferenceDataReducer } from '../../../rmsReduxStore/reduxExports';
import { createCharger } from '../../../rmsReduxStore/chargersRedux/chargerCreators';
import { removeAllFromReferenceData } from '../../../globalUtils/globalHooks';
import { Id, toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { AxiosResponse } from 'axios';
import CustomForm, { ICustomFormProps, IFormContentProp } from '../../../globalUtils/CustomForm/CustomForm';
import { broadcastMessageDevice } from '../chargerConstants';
import { useLocation } from 'react-router-dom';

interface IChargerForm {
  macId: string;
  model: string;
  visibleId: string;
  latitude: string;
  longitude: string;
  remarks: string;
}

const CreateBroadcastMessage: FC = () => {
  const location = useLocation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { chargerModelReferenceData } = useAppSelector(getReferenceDataReducer);
  const [selectedConnectorValue, setSelectedConnectorValue] = useState<string | null>('all');
  const initialChargerFormData = {
    macId: '',
    model: '',
    visibleId: '',
    latitude: '',
    longitude: '',
    remarks: ''
  };
  const initialStateData = {
    macIdValue: '',
    chargerModelValue: null,
    chargerSerialNumber: '',
    latitude: '',
    longitude: '',
    remarks: '',
  };

  const [addChargerFormData, setAddChargerFormData] = useState<IChargerForm>(initialChargerFormData);
  const [addChargerStateData, setAddChargerStateData] = useState(initialStateData);

  const handleFormDataChange = useCallback((val, formKey, stateKey) => {
    const stateData = cloneDeep(addChargerStateData);
    const formData = cloneDeep(addChargerFormData);
    if (val?.id) {
      formData[formKey] = val?.id;
    } else {
      formData[formKey] = val;
    }

    stateData[stateKey] = val;
    setAddChargerStateData(stateData);
    setAddChargerFormData(formData);
  }, [addChargerStateData, addChargerFormData]);

  const resetFormData = useCallback(() => {
    setAddChargerFormData(initialChargerFormData);
    setAddChargerStateData({
      macIdValue: '',
      chargerModelValue: null,
      chargerSerialNumber: '',
      latitude: '',
      longitude: '',
      remarks: '',
    })
  }, []);

  const disableAddButton = useCallback(() => {
    return (!(addChargerFormData?.macId?.length > 0 && addChargerFormData?.visibleId?.length > 0 && addChargerFormData?.model?.length > 0 && addChargerFormData?.latitude?.length > 0 && addChargerFormData?.longitude?.length > 0))
  }, [addChargerFormData]);

  const disableCancelButton = useCallback(() => {
    const hasFormChanged = Object.keys(addChargerFormData).some(
      (key) => addChargerFormData[key] !== initialChargerFormData[key]
    );
    return !hasFormChanged
  }, [addChargerFormData]);

  const chargerAddedToast = (): Id => toast.success('Charger added.');
  const duplicateChargerToast = (): Id => toast.warn('Charger with the same ID exists.');
  const somethingWentWrongToast = (): Id => toast.warn('Something went wrong.');
  const btnsList = useMemo(() => {
    return [
      {
        buttonText: 'Send Now',
        buttonId: 'sendNow',
        btnClassName: disableAddButton() ? 'primary__btn disabled' : 'primary__btn',
        handleClick: async (): Promise<void> => {
          // Validate latitude here
          const latitudeValue = parseFloat(addChargerStateData.latitude);
          if (isNaN(latitudeValue) || latitudeValue < 8.4 || latitudeValue > 37.6) {
            toast.error('Latitude should be in range of [8.4,37.6]');
            return;
          }
          // Validate longitude here
          const longitudeValue = parseFloat(addChargerStateData.longitude);
          if (isNaN(longitudeValue) || longitudeValue < 68.7 || longitudeValue > 97.25) {
            toast.error('Longitude should be in range of [68.7,97.25]');
            return;
          }

          const response: AxiosResponse = await dispatch(createCharger(addChargerFormData));
          if (response?.status === 200 || response?.status === 202) {
            chargerAddedToast();
            setTimeout(() => {
              navigate('/broadcast-message');
            }, 2000)
          }
          else if (response.data === 'Charger with same Id exists') {
            duplicateChargerToast();
            setTimeout(() => {
              resetFormData();
            }, 2000)
          } else {
            somethingWentWrongToast();
            setTimeout(() => {
              resetFormData();
            }, 2000)
          }
        },
        isDisabled: disableAddButton(),
        buttonVariant: 'filled',
      },
      {
        buttonText: 'Send Later',
        buttonId: 'sendLater',
        btnClassName: disableCancelButton() ? 'secondary__btn disabled' : 'secondary__btn',
        handleClick: (): void => {
          resetFormData()
        },
        isDisabled: disableCancelButton(),
        buttonVariant: 'outlined',
      },
    ];
  }, [addChargerFormData, disableAddButton]);

  useEffectOnce(() => {
    dispatch(fetchChargerModelReferenceData());
  });

  const keyPressValidation = (event): void => {
    const { key, target } = event;
    // Check for dot (.)
    if (key === '.' && target?.value?.includes('.')) {
      event.preventDefault();
    }
    // Check for numeric keys and backspace
    if (isNaN(Number(key)) && key !== '.' && key !== 'Backspace') {
      event.preventDefault();
    }
  };

  const formArray: IFormContentProp[] = [
    {
      rowCountClassName: 'grid-3',
      formObject: [
        {
          formLabel: 'Customer',
          isRequired: true,
          objectType: 'input',
          selectDropdownProps: null,
          inputProps: {
            inputPlaceHolder: 'Type here',
            handleInputChange: (event): void => {
              handleFormDataChange(event?.target?.value, 'macId', 'macIdValue');
            },
            inputValue: addChargerStateData?.macIdValue,
            inputType: 'string',
            inputFieldId: 'macId',
            isRequired: false,
          },
          textAreaProps: null,
        },
        {
          formLabel: 'Charging Station',
          isRequired: true,
          objectType: 'input',
          selectDropdownProps: null,
          inputProps: {
            inputPlaceHolder: 'Type here',
            handleInputChange: (event): void => {
              handleFormDataChange(event?.target?.value, 'visibleId', 'chargerSerialNumber');
            },
            inputValue: addChargerStateData?.chargerSerialNumber,
            inputType: 'string',
            inputFieldId: 'adding_station',
            isRequired: false,
          },
          textAreaProps: null,
        },
        {
          formLabel: 'Charger',
          isRequired: true,
          objectType: 'dropdown',
          selectDropdownProps: {
            selectValue: addChargerStateData?.chargerModelValue,
            selectOptions: removeAllFromReferenceData(chargerModelReferenceData) as IChargerModelReferenceData[] || [],
            handleSelectOptionChange: (val): void => {
              handleFormDataChange(val, 'model', 'chargerModelValue');
            },
            selectDropDownId: 'charging-model-dropdown',
            selectDropDownName: 'charging-model-dropdown',
          },
          inputProps: null,
          textAreaProps: null,
        },
      ],
    }
  ];


  const customFormProps: ICustomFormProps = useMemo(() => {
    return {
      formContainerClassName: 'add__charger__wrap',
      linkBtnProps: null,
      headerPropTypes: null,
      formArray: formArray,
      footerBtnProps: {
        buttonsList: btnsList
      },
    };
  }, [formArray, btnsList]);

  const handleConnectorChange = useCallback((event: ChangeEvent<HTMLInputElement>, value: string): void => {
    setSelectedConnectorValue(value)
  }, []);

  return (
    <div className='charger__broadcast__message__panel'>
      <div className='back__btn-container'>
        <LinkButton btnText='Back' linkBtnState={location?.state} linkBtnId='individual-charger-back-btn' link='/broadcast-message' showArrow={true}
          handleOnClick={() => { }} />
      </div>
      <DashboardHeader
        headerClassName={'rms__table__dashboard-header'}
        header={'Create Broadcast Message'}
      />
      <div className='broadcast_message_radio_button_wrap'>
        <RadioButton
          buttonsList={broadcastMessageDevice}
          handleSelectedRadioBtnChange={handleConnectorChange}
          selectedRadioBtnValue={selectedConnectorValue}
          radionBtnGrpName={'charger-remote-commands-dashboard-radio-buttons'}
        />
      </div>
      {selectedConnectorValue === 'device' &&
        <div>
          <CustomForm {...customFormProps} />
        </div>}
    </div>
  )
}

export default CreateBroadcastMessage
