import { type GridRenderCellParams, GridValidRowModel } from '@mui/x-data-grid-pro';
import { cloneDeep } from 'lodash';
import React, { ReactNode, memo, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  alarmCardMapper,
  CustomStatus,
  CustomTooltipWithLabel,
  FilterProp,
  formatRelativeTimeForTable,
  LinkButton,
  TableDashboard,
  useAppDispatch,
  useAppSelector,
} from '../../../globalUtils/globalExports';
import {
  setCustomerTableProps,
  setSelectedChargerData
} from '../../../rmsReduxStore/customersRedux/customerCreators';
import {
  chargerTypes,
  fetchCustomerChargersData,
  getCustomerReducer,
  getCurrentFiltersValues,
  getFilterReducer,
  getFilterSelectedValue,
  getReferenceDataReducer,
  setFilterData,
  customerTypes,
} from '../../../rmsReduxStore/reduxExports';
import '../../chargers/chargers.css';
import { IconWithTooltip } from '../../../globalUtils/globalIcons';
import { NoDataComponentChargers } from '../../../globalUtils/TableDashboard/TableComponents';

interface MapTooltipData {
  id: string,
  headerText: string;
  headerOnClick: () => void
  data: {
    icon: string;
    text: string;
  }[];
}

interface IMapFocusedData {
  latitude: number;
  longitude: number;
  id: string
}

interface ChargerListingProps {
  customerId: string | null
}

const ChargerListing = memo(function ChargerListing(props:ChargerListingProps) {
  const {customerId} = props
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const { chargers, chargerCount, loader, customerChargerListTableProps,  } = useAppSelector(getCustomerReducer);
  const {
    chargerStatusReferenceData,
    chargerLocationReferenceData,
    softwareVersionReferenceData,
    alarmSeverityReferenceData
  } = useAppSelector(getReferenceDataReducer);
  const { screenFilters } = useAppSelector(getFilterReducer)
  const [layoutView, setLayoutView] = useState<string>(location?.state?.layoutView === 'map' ? 'map' : 'list');
  const [individualChargerMapView, setIndividualChargerMapView] = useState(false);
  const [mapDataPoints, setMapDataPoints] = useState<{
    latitude: number;
    longitude: number;
    status: string,
    id: string,
    tooltipData: MapTooltipData
  }[]>();
  const [mapSelectedChargerDataPoint, setMapSelectedChargerDataPoint] = useState<IMapFocusedData>({ latitude: 0, longitude: 0, id: '' })
  // const [showChargerFilterScreen, setShowChargerFilterScreen] = useState(false);
  const [defaultMapCenter, setDefaultMapCenter] = useState(true);
  const chargerListTablePropsRef = useRef(customerChargerListTableProps);
  const chargersRef = useRef(chargers);
  const [headerIndividualChargerMapView, setHeaderIndividualChargerMapView] = useState('Charger')

  // ********************************************
  // TODO: Callback wasn't taking the updated value
  useEffect(() => {
    chargerListTablePropsRef.current = customerChargerListTableProps;
  }, [customerChargerListTableProps]);

  useEffect(() => {
    chargersRef.current = chargers;
  }, [chargers]);

  // TODO: Handles global search box
  // In config - pass id, classname and onChange
  // In onChange - just add a callback rest is handled in search box component
  const onChangeOfSearchText = useCallback(async (val) => {
    if (chargerListTablePropsRef?.current) {
      await layoutCallback(1, chargerListTablePropsRef?.current?.pageSize, chargerListTablePropsRef?.current?.view, chargerListTablePropsRef?.current?.sortBy, chargerListTablePropsRef?.current?.order, null, val)
    }
    if (layoutView === 'map') {
      await refreshMapDataPoints(chargersRef?.current)
    }
  }, [layoutView, chargerListTablePropsRef?.current, chargersRef?.current]);

  const searchBoxConfiguration = useMemo(() => {
    return {
      searchFieldId: 'manage-chargers-search-box',
      searchFieldName: 'manage-chargers-search-box',
      handleSearch: onChangeOfSearchText,
    };
  }, [onChangeOfSearchText,layoutView,chargerListTablePropsRef?.current]);




  // TODO: Map Layout callbacks
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const onMapIconClick = useCallback(async (event, data) => {
    const charger: ICharger = data;
    const mapData: IMapFocusedData = {
      latitude: Number(charger?.latitude) || 0,
      longitude: Number(charger?.longitude) || 0,
      id: charger?.id
    };
    setMapSelectedChargerDataPoint(mapData)
    await refreshMapDataPoints([data])
    setHeaderIndividualChargerMapView(charger?.customerName + ', ' + charger?.city);
    setLayoutView('map');
    setIndividualChargerMapView(true);
    setDefaultMapCenter(false)
    history.pushState(null, '', '/manage-chargers');
  }, [mapSelectedChargerDataPoint, defaultMapCenter, headerIndividualChargerMapView]);

  const refreshMapDataPoints = useCallback(async (chargerValue) => {
    try {
      const mapDataPoint = chargerValue !== null && chargerValue?.length > 0 && chargerValue?.map((charger) => {
        const chargerStatusIcon = charger.status === 'Offline' ? 'map__tooltip__offline__icon' : charger.status === 'Online' ? 'map__tooltip__online__icon' : charger.status === 'Faulty' ? 'map__tooltip__faulty__icon' : '';
        return {
          latitude: parseFloat(charger?.latitude),
          longitude: parseFloat(charger?.longitude),
          status: charger.status,
          id: charger?.id,
          tooltipData: {
            id: charger?.id, headerText: charger.visibleId, headerOnClick: (): void => {
              navigate('/charger?chargerId=' + charger.visibleId, {
                state: {
                  layoutView: 'map',
                  chargerId: charger?.id,
                  pageNumber: chargerListTablePropsRef?.current?.pageNumber || 1,
                  chargerValue: chargerValue,
                  individualChargerMapView: true,
                }
              })
            },
            data: [
              { icon: 'map__tooltip__customer__icon', text: `${charger.customerName}` },
              { icon: 'map__tooltip__connector__icon', text: `${charger.connectorTypes}` },
              { icon: chargerStatusIcon, text: `${charger.status}` },
            ],
          }
        };
      });
      if (mapDataPoint) {
        const filteredDataPoints = mapDataPoint.filter(i =>
          i.latitude != null && true && !isNaN(i.latitude) && i.longitude != null && true && !isNaN(i.longitude));
        setMapDataPoints(filteredDataPoints);

      } else {
        setMapDataPoints([]); // If there are no chargers coming from server.
      }
    } catch (error) {
      console.error('Error in refreshMapDataPoints:', error);
    }
  }, [chargerListTablePropsRef?.current, setMapDataPoints]);


  const actionIconsList = useMemo(() => {
    return [{ icon: 'sheildIcon', action: onMapIconClick }];
  }, [onMapIconClick]);

  const handleSelectedCharger = useCallback((event, data) => {
    const action = {
      type: chargerTypes.SET_CHARGER_REDUCER_STATE,
      isChargerTablePropsRetained: true
    }
    dispatch(action);
    dispatch(setSelectedChargerData(data));
  }, [dispatch]);


  // TODO: Table Layout Callbacks
  const mobileViewConfiguration = useMemo(() => {
    return {
      headerDataConfig: {
        headerLeftDataConfig: ['visibleId', 'model'],
        headerRightDataConfig: {
          actionIconsComponent: false,
          statusIconComponent: true,
        },
      },
      statusIconKey: 'status',
      statusIconMapper: {
        'Online' : 'status__online__charger__icon',
        'Offline' : 'status__offline__charger__icon'
      },
      statusIconBackgroundColorMapper: {
        'Online' : 'var(--green-color)',
        'Offline' : 'var(--grey-color)'
      } ,
      contentDataConfig: [
        [
          {
            key: 'softwareVersion',
            iconClassName: 'chargers__icon',
          },
          {
            key: 'customerName',
            iconClassName: 'customer__management__icon',
          },
        ],
        [
          {
            key: 'chargerSwVersion',
            iconClassName: 'alarm__management__icon  ',
          },
          {
            key: 'connectorType',
            iconClassName: 'navbar__logout__icon  ',
          },
        ],
      ],
    };
  }, []);

  const getTableRows = useCallback((): ICharger[] => {
    return chargers !== null && chargers?.length > 0 ? chargers : [];
  }, [chargers]);

  const chargerColumns = useMemo(() => {
    return [
      {
        field: 'visibleId',
        flex: 1,
        headerName: 'Charger',
        minWidth: 150,
        renderCell: (params: GridRenderCellParams<GridValidRowModel>): JSX.Element => {
          const rowData = params?.row;
          return (
            <div
              className="individual__charger__wrap"
              onClick={(e) => {
                e?.stopPropagation()
                const action = {
                  type: chargerTypes.SET_CHARGER_REDUCER_STATE,
                  isChargerTablePropsRetained: true
                }
                dispatch(action)
              }
              }
            >
              <LinkButton
                linkBtnId={rowData?.visibleId}
                link={'/charger?chargerId=' + rowData.visibleId}
                btnClassName="individual__charger__text"
                btnText={rowData?.visibleId}
                linkBtnState={{ chargerId: rowData?.id, chargerListTableProps: customerChargerListTableProps,charger:rowData}}
              />
            </div>
          );
        },
      },
      {
        field: 'model',
        headerName: 'Model',
        minWidth: 150,
        editable: false,
        flex: 1,
      },
      {
        field: 'status',
        headerName: 'Status',
        minWidth: 90,
        flex: 0.8,
        editable: false,
        renderCell: (params: GridRenderCellParams<GridValidRowModel, Date>): JSX.Element => {
          return <CustomStatus 
          statusText={params?.row?.status} 
          statusIconClassName={params?.row?.status === 'Online' ? 'status__online__charger__icon' : 'status__offline__charger__icon' }
          backgroundColor={ params?.row?.status === 'Online' ? 'var(--green-color)' : 'var(--grey-color)' } />;
        },
      },
      {
        field: 'customerName',
        headerName: 'Customer',
        flex: 1,
        sortable: true,
        minWidth: 100,
      },
      {
        field: 'softwareVersion',
        headerName: 'Software Version',
        flex: 1,
        sortable: true,
        minWidth: 160,
      },
      {
        field: 'connectorTypes',
        headerName: 'Connectors',
        flex: 1,
        sortable: true,
        minWidth: 100,
        renderCell: (params: GridRenderCellParams<GridValidRowModel, Date>): JSX.Element => {
          const rowData = params?.row;
          const connectorTypes = rowData?.connectorTypes;
          const labelId = params?.row?.id
          return (
            <CustomTooltipWithLabel label={connectorTypes} labelId={labelId} />
          );
        },
      },
      {
        field: 'lastPingTime',
        headerName: 'Last Ping Time',
        flex: 1,
        sortable: true,
        minWidth: 80,
        renderCell:(params: GridRenderCellParams<GridValidRowModel>): ReactNode => {
          const rowData = params?.row;
          return rowData?.lastPingTime ? formatRelativeTimeForTable(new Date(rowData?.lastPingTime)) : '-';
        },
      },
      {
        field: 'alarmStatus',
        headerName: 'Alarm',
        flex: 1,
        headerAlign: 'center',
        align: 'center',
        sortable: true,
        minWidth: 80,
        maxWidth: 80,
        renderCell: (params: GridRenderCellParams<GridValidRowModel, Date>): JSX.Element => {
          const rowData = params?.row;
          const severityIcon = rowData?.alarmStatus === 'No Alarm' ? 'TOTAL' : rowData?.alarmStatus?.toUpperCase();
          const tooltipText = rowData?.alarmStatus === 'No Alarm' ? 'No Alarm' : alarmCardMapper[severityIcon]?.title;
          return (
            <div className="charger__connector__type__cell">
              <IconWithTooltip iconClassName={rowData?.alarmStatus === 'No Alarm' ? 'no_alarm_icon' : alarmCardMapper[severityIcon].alarmSummaryIcon}
                toolTipText={tooltipText} />

            </div>
          );
        },
      },
    ];
  }, [actionIconsList, handleSelectedCharger, customerChargerListTableProps]);

  const layoutCallback = useCallback(async (pageNumber: number, pageSize: number, view: string, field: string, order: string, filterData, searchTerm) => {
    const tableProps: IChargerListTableProps = cloneDeep(chargerListTablePropsRef.current)
    const filters = filterData ? filterData : getCurrentFiltersValues('customerCharger', true)
    const customerFilter:Filters = {key:'customer', values : [customerId ?? '']}
    filters.push(customerFilter)
    await dispatch(fetchCustomerChargersData({
      view: view ?? layoutView,
      sortBy: field || 'alarmStatus',
      order: order || 'ascending',
      pageSize,
      pageNumber,
      filters: filters,
      searchTerm: searchTerm != null && searchTerm != undefined ? searchTerm : chargerListTablePropsRef?.current?.searchTerm
    }));
    if (tableProps) {
      tableProps.view = view || layoutView
      tableProps.sortBy = field || 'alarmStatus'
      tableProps.order = order || 'ascending';
      tableProps.pageNumber = pageNumber;
      tableProps.pageSize = pageSize;
      if (searchTerm != null && searchTerm != undefined) {
        tableProps.searchTerm = searchTerm;
      }
    }
    await dispatch(setCustomerTableProps(tableProps, 'chargerList'))
  }, [layoutView, chargerListTablePropsRef?.current, dispatch]);


  // ********************************************
  // TODO: Filter side pop up 
  // onchange -> handles onchange of all the dropdowns, layout props -> pass the required dropdowns, footer button action
  const handleFilterDataChange = useCallback(async (val, filterKey, isGlobal) => {
    const res = await setFilterData(val, filterKey, 'customerCharger', 'SET', isGlobal ? isGlobal : false, true)(dispatch)
    if (res?.message === 'Action dispatched successfully') {
      if (chargerListTablePropsRef?.current) {
        await layoutCallback(1, chargerListTablePropsRef?.current?.pageSize, chargerListTablePropsRef?.current?.view, chargerListTablePropsRef?.current?.sortBy, chargerListTablePropsRef?.current?.order, res?.filters, chargerListTablePropsRef?.current?.searchTerm)
      }
      if (layoutView === 'map') {
        await refreshMapDataPoints(chargersRef.current)
      }
    }
  }, [chargerListTablePropsRef?.current, dispatch, chargersRef?.current])

  const chargerFilterProps: FilterProp[] = useMemo(() => {
    return [
      {
        filterLabel: 'Location',
        filterType: 'dropdown',
        filterId: 'Location',
        filterDropdownProps: {
          selectValue: getFilterSelectedValue('location', 'customerCharger', false),
          selectOptions: chargerLocationReferenceData ?? [],
          handleSelectOptionChange: (val): void => {
            handleFilterDataChange(val, 'location', false)
          },
          selectDropDownId: 'charger-location-filter-dropdown',
          isMultiSelect: true
        }
      },
      {
        filterLabel: 'Status',
        filterType: 'dropdown',
        filterId: 'Status',
        filterDropdownProps: {
          selectValue: getFilterSelectedValue('chargerStatus', 'customerCharger', false),
          selectOptions: chargerStatusReferenceData ?? [],
          handleSelectOptionChange: (val): void => {
            handleFilterDataChange(val, 'chargerStatus', false)
          },
          selectDropDownId: 'charger-status-filter-dropdown',
          isMultiSelect: true
        }
      },
      {
        filterLabel: 'Alarm Status',
        filterType: 'dropdown',
        filterId: 'alarmStatus',
        filterDropdownProps: {
          selectValue: getFilterSelectedValue('alarmStatus', 'customerCharger', false),
          selectOptions: alarmSeverityReferenceData ?? [],
          handleSelectOptionChange: (val): void => {
            handleFilterDataChange(val, 'alarmStatus', false)
          },
          selectDropDownId: 'charger-severity-filter-dropdown',
          isMultiSelect: true
        }
      },
      {
        filterLabel: 'Software Version',
        filterType: 'dropdown',
        filterId: 'softwareVersion',
        filterDropdownProps: {
          selectValue: getFilterSelectedValue('softwareVersion', 'customerCharger', false),
          selectOptions: softwareVersionReferenceData ?? [],
          handleSelectOptionChange: (val): void => {
            handleFilterDataChange(val, 'softwareVersion', false)
          },
          selectDropDownId: 'charger-software-version-filter-dropdown',
          isMultiSelect: true
        }
      },
    ]
  }, [softwareVersionReferenceData, chargerLocationReferenceData, chargerStatusReferenceData, alarmSeverityReferenceData, screenFilters])

//   const onResetButtonClick = useCallback(async (callListApi: boolean) => {
//     const res = await setFilterData(null, '', 'chargers', 'CLEAR', false)(dispatch);
//     if (res?.message === 'Action dispatched successfully') {
//       if (chargerListTablePropsRef?.current && callListApi) {
//         layoutCallback(1, chargerListTablePropsRef?.current?.pageSize, chargerListTablePropsRef?.current?.view, chargerListTablePropsRef?.current?.sortBy, chargerListTablePropsRef?.current?.order, res?.filters, chargerListTablePropsRef?.current?.searchTerm)
//       }
//     }
//     // setRedirectedStateForChargerFilters(false)(dispatch)
//   }, [chargerListTablePropsRef?.current, chargerFiltersAppliedFromRedirection])


  // TODO: Redux state Clean up
  const chargerCleanUpStates = useCallback(() => {
    // : Partial<ChargerAction>
    const action = {
      type: customerTypes.CLEAR_CUSTOMER_CHARGER_LIST_DATA,
    }
    dispatch(action);
    // if (chargerFiltersAppliedFromRedirection) {
    //   onResetButtonClick(false)
    // }
    // setRedirectedStateForChargerFilters(false)(dispatch)

  }, [])


  useEffect(() => {
    window.addEventListener('beforeunload', chargerCleanUpStates);
    return (): void => {
      chargerCleanUpStates()
      window.removeEventListener('beforeunload', chargerCleanUpStates);
    }
  }, [])

  const getMapData = useCallback(() => {
    if (mapDataPoints) {
      return mapDataPoints ?? []
    }
  }, [mapDataPoints])

  // ***********************************************************
  // TODO: Export Handlers


  // ***************************************************************
  // TODO : Toggle between map and list view & Handling state change using the callback passed in TableDashboard
  const handleLayoutToggle = useCallback(async (newLayoutVal, newIndividualChargerMapView?) => {
    if (newLayoutVal === 'map') {
      setDefaultMapCenter(true);
      setMapSelectedChargerDataPoint({ latitude: 0, longitude: 0, id: '' });
      await refreshMapDataPoints(chargersRef.current)
    }
    setLayoutView(newLayoutVal);
    if (newIndividualChargerMapView !== undefined && individualChargerMapView != newIndividualChargerMapView) setIndividualChargerMapView(prevState => !prevState);     // when we click on Back button in Table Dashboard, we pass newIndividualChargerMapView as false 
  }, [chargersRef.current, setIndividualChargerMapView])


  const layoutsConfiguration = useMemo(() => {
    return [
      {
        toolTipText: 'List View',
        onIconClick: (): void => {
          handleLayoutToggle('list')
        },
        layout: 'list',
        iconClassName: 'rms__table__dashboard-list__view__icon'
      },
      {
        toolTipText: 'Grid View',
        onIconClick: (): void => {
          handleLayoutToggle('grid')
        },
        layout: 'grid',
        iconClassName: 'rms__table__dashboard-grid__view__icon'
      },
      {
        toolTipText: 'Map View',
        onIconClick: (): void => {
          handleLayoutToggle('map')
        },
        layout: 'map',
        iconClassName: 'rms__table__dashboard-map__view__icon'
      }
    ]
  }, [handleLayoutToggle])

  // TODO : Render Header Text
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const setChargerHeader = useCallback(() => {
    const headerTextMapper = {
      'Offline': 'Offline Chargers',
      'Online': 'Online Chargers',
      'all': 'Chargers',
      'Faulty': 'Faulty Chargers',
      'Healthy': 'Healthy Chargers'
    }
    if (location.state?.chargerStatusId) return headerTextMapper[location.state?.chargerStatusId]
    return 'Chargers'
  }, [location.state?.chargerStatusId])

  useEffect(() => {
    /* This code will work when we are going to charger detail from 
       single map view and needs to come back to single map view only*/
    if (location.state) {
      const { chargerValue } = location.state;
      if (chargerValue && chargerValue.length === 1) {
        onMapIconClick(null, chargerValue[0]);
      }
      else {
        refreshMapDataPoints(chargerValue || chargersRef.current)
      }
    }
  }, [location.state]);

  return (
    <>
      <TableDashboard
        layoutsEnabled={true}
        tableId="manage-chargers-table"
        tableClassName="manage__chargers__table"
        searchBoxIncluded={true}
        searchBoxConfiguration={searchBoxConfiguration}
        gridColumns={chargerColumns}
        tableRows={getTableRows()}
        mobileViewConfiguration={mobileViewConfiguration}
        layoutView={layoutView}
        listLayoutCallBack={layoutCallback}
        mapDataPoints={getMapData()}
        totalCount={chargerCount}
        showSkeleton={!chargers}
        totalCountText={'chargers'}
        showLoader={loader}
        mapFocusedDataPoint={mapSelectedChargerDataPoint}
        usedefaultMapCenter={defaultMapCenter}
        customNoDataComponent={NoDataComponentChargers}
        pageNumber={customerChargerListTableProps?.pageNumber}
        tablePageSize={customerChargerListTableProps?.pageSize}
        layoutsConfiguration={layoutsConfiguration}
        excludeGlobalFilters={true}
        filterProps={chargerFilterProps}
        individualChargerMapView={individualChargerMapView}
        callbackFunction={handleLayoutToggle}
        handleFilterDataChange={handleFilterDataChange}
        referenceDataCallbacksFilters={['location', 'status', 'softwareVersion', 'severity']}
      />
    </>
  );
})

export default ChargerListing;
