// A reducer is a pure function that receives the state of the store and an action as parameters and then returns the updated state.

import { chargingStationTypes } from '../reduxExports'

// Declare an initial state to have some chargingStationData. The state object needs to match the type ChargingStationState - otherwise, TypeScript will throw an error.
const chargingStationInitialState: ChargingStationState = {
  chargingStations: null,
  customers: null,
  countryState: null,
  locationTypeOptions: []
}

// Reducer function that expects the previous state and an action to be able to update the store.
const chargingStationReducer = (
  state: ChargingStationState = chargingStationInitialState,
  action: ChargingStationAction
): ChargingStationState => {
  switch (action.type) {
    case chargingStationTypes.FETCH_CHARGING_STATION_DATA:
      return {
        ...state,
        chargingStations: action.chargingStations
      }

    case chargingStationTypes.GET_CUSTOMER_DATA:{
      return {
        ...state,
        customers: action.customers
      }
    }
    case chargingStationTypes.GET_COUNTRY_STATE_DATA:{
      return {
        ...state,
        countryState: action.countryState
      }
    }
    case chargingStationTypes.GET_REFERENCE_DATA:{
      return {
        ...state,
        locationTypeOptions: action.locationTypeOptions
      }
    }

    default:
      return state
  }
}

export default chargingStationReducer
