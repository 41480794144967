import React, { memo, useCallback, useState, type MouseEvent, useMemo } from 'react'
import './alarmPanel.css';
import { useLocation, useNavigate } from 'react-router-dom';
import { alarmCardMapper } from '../../../globalUtils/AlarmCards/AlarmCards';
import { Icon } from '../../../globalUtils/globalIcons';
import { CustomBreadCrumbs, CustomButtonGroup, CustomTextArea, HorizontalNavbar, calculateAging, formatRelativeTimeForTable, useAppDispatch} from '../../../globalUtils/globalExports';
import { alarmPanelNavList } from '../alarmConstants';
import AlarmPanelCoreDashboard from './AlarmPanelCore/AlarmPanelCoreDashboard';
import { IBreadCrumb, createBreadCrumbProps } from '../../../globalUtils/globalHooks';
import { BreadCrumbObject } from '../../../globalUtils/CustomBreadCrumbs/CustomBreadCrumbs';
import { saveNotes } from '../../../rmsReduxStore/alarmsRedux/alarmCreators';
import { Id, toast } from 'react-toastify';
import { FormLabel } from '@mui/material';
// import AlarmPanelRemoteTriggerDashboard from './AlarmPanelRemoteTrigger/AlarmPanelRemoteTriggerDashboard';
// import AlarmPanelFirmwareManagementDashboard from './AlarmPanelFirmwareManagement/AlarmPanelFirmwareManagementDashboard';
// import AlarmPanelReservationDashboard from './AlarmPanelReservation/AlarmPanelReservationDashboard';
// import AlarmPanelLocalAuthListManagement from './AlarmPanelLocalAuthListManagement/AlarmPanelLocalAuthListManagement';
// import AlarmPanelSmartChargingDashboard from './AlarmPanelSmartCharging/AlarmPanelSmartChargingDashboard';
const topCard = ['alarmVisibleName', 'alarmSetTime', 'alarmClearTime'];

const bottomCard = [
  'chargerVisibleId',
  'connectorType',
  'connectorNumber',
  'customerName',
  'location',
];

const columns = [
  {
    field: 'severity',
    headerName: 'Severity'
  },
  {
    field: 'customerName',
    headerName: 'Customer'
  },
  {
    field: 'chargerVisibleId',
    headerName: 'Charger'
  },
  {
    field: 'alarmVisibleName',
    headerName: 'Alarm'

  },
  {
    field: 'connectorType',
    headerName: 'Connector Type'

  },
  {
    field: 'connectorNumber',
    headerName: 'Connector No.'

  },
  {
    field: 'alarmStatus',
    headerName: 'Status'
  },
  {
    field: 'notes',
    headerName: 'Notes'
  },
  {
    field: 'alarmSetTime',
    headerName: 'Time (Set at)'

  },
  {
    field: 'alarmClearTime',
    headerName: 'Time (Cleared at)'
  },

  {
    field: 'warranty',
    headerName: 'Warranty'
  },
];

const AlarmPanelDashboard = memo(function AlarmPanelDashboard() {
  const [selectedNav, setSelectedNav] = useState('core');
  const handleNavBtnClick = useCallback(
    (event: MouseEvent<HTMLButtonElement>, val: string) => {
      setSelectedNav(val)
    },
    []
  )
  const dispatch = useAppDispatch();
  const navigate = useNavigate()
  const location = useLocation();
  const rowData = location?.state.row;
  // TODO: BreadCrumb navigation Props
  const createBreadCrumbsNavigation = useCallback((): BreadCrumbObject[] => {
    let breadCrumbArray: IBreadCrumb[] = [];
    if (location?.state.calledFrom === 'charger_panel') {
      const chargerListNavigation: IBreadCrumb[] = [
        {
          objectType: 'link',
          id: 'chargers',
        },
        {
          objectType: 'link',
          id: 'chargerDetail',
          link: `/charger?chargerId=${rowData?.chargerVisibleId}`,
          btnText: rowData?.chargerVisibleId,
          linkBtnState: {
            chargerId: rowData?.chargerId,
            selectedNav: (location?.state.calledFrom === 'charger_panel') ? 'alarm_history' : 'active',
          }
        }
      ]
      breadCrumbArray = breadCrumbArray?.concat(chargerListNavigation)
    } else if (location?.state.calledFrom === 'dashboard') {
      const dashboardNavigation: IBreadCrumb[] = [
        {
          objectType: 'link',
          id: 'dashboard',
        }
      ]
      breadCrumbArray = breadCrumbArray?.concat(dashboardNavigation)
    }
    else {
      const alarmNavigation: IBreadCrumb[] = [
        {
          objectType: 'link',
          id: 'alarms',
          linkBtnState: {
            calledFrom: location?.state.calledFrom,
          }
        }]
      breadCrumbArray = breadCrumbArray?.concat(alarmNavigation)
    }
    breadCrumbArray.push({
      objectType: 'text',
      id: 'text',
      text: rowData?.alarmVisibleName
    })
    return createBreadCrumbProps({ breadCrumbProps: breadCrumbArray })
  }, [location?.state, rowData])

  const alarmNotesDeletionSuccessToast = useCallback((): Id => {
    navigate('/alarms')
    return toast.success('Alarm notes added.');
  }, []);

  const alarmNotesDeletionFailureToast = useCallback((message: string): Id => {
    return toast.warn(message ?? 'Something went wrong.');
  }, []);

  function NotesComponent() {
    const [note, setNote] = useState(rowData?.notes ?? '');
  
    const handleNoteChange = (event) => {
      setNote(event.target.value);
    };
  
    const handleSaveNote = async () => {
      const { data, status } = await dispatch(saveNotes({
        alarmId: rowData?.id,
        notes: note
      }));
      if ((status === 200 || status === 202) && data?.message === 'Success') {
        alarmNotesDeletionSuccessToast()
      } else {
        alarmNotesDeletionFailureToast(data.message)
      }
    };

    const btnsList = useMemo(() => {
      return [
        {
          buttonText: 'Save',
          buttonId: 'add',
          btnClassName: note === rowData?.notes ? 'primary__btn disabled' : 'primary__btn',
          handleClick: handleSaveNote,
          isDisabled: note === rowData?.notes,
          buttonVariant: 'filled'
        }
      ]
    }, [note,handleSaveNote]);

    return (
      <div className='alarm__card__notes'>
       <FormLabel className='rms-form-label'> Notes</FormLabel>
        <CustomTextArea
          inputPlaceHolder='Type here'
          className='alarm__card__notes-container'
          handleInputChange={handleNoteChange}
          isRequired={false}
          inputValue={note}
          inputFieldId='alarmNotes'
          isDisabled={false}
         />
        <div className='alarm__card__notes-button'>
         <CustomButtonGroup buttonsList={btnsList} buttonGroupClassName={'button__group__footer'} />
        </div>
      </div>
    );
  }

  return (
    <div className='alarm__panel'>
      <div className='charger__panel-header-wrap'>
        <div className='back__btn-container'>
          <CustomBreadCrumbs breadCrumbs={createBreadCrumbsNavigation()} />
        </div>
      </div>
      <div className='alarm__panel-header'>
        <div className='alarm__panel-header-content-wrap'>
          <div className="alarm__row__card__container">
            <div className="alarm__row__card__container-top">
              <div
                className="alarm__row__card__container-top-border"
                style={{ backgroundColor: alarmCardMapper[rowData?.severity]?.color }}
              ></div>

              {topCard?.map((columnKey, index) => {
                const headerName =
                  columns?.find(
                    (column) => column?.field === columnKey
                      && rowData[columnKey] != null)?.headerName || '';
                const value = rowData[columnKey];
                return (
                  <div key={index} className="alarm__row__card__container-top-card">
                    <div className="alarm__row__card__container-top-card-header">
                      <span>{headerName}</span>
                      {headerName === 'Alarm' && (
                        <span
                          className={'alarm__row__card__container-top-card-severity-status-text '}
                          style={{
                            backgroundColor:
                              alarmCardMapper[rowData?.severity]?.color,
                          }}
                        >
                          {rowData?.severity}
                        </span>
                      )}
                    </div>

                    <div className="alarm__row__card__container-top-card-content">
                      {headerName === 'Alarm' && (
                        <Icon iconClassName={alarmCardMapper[rowData?.severity]?.alarmSummaryIcon} />
                      )}
                      <div className={'text ' + headerName} >{(headerName === 'Time (Set at)') || (headerName === 'Time (Cleared at)') ? formatRelativeTimeForTable(value) : value}</div>
                    </div>
                  </div>
                );
              })}

              <div className="alarm__row__card__container-top-card">
                <div className="alarm__row__card__container-top-card-header">
                  <span>{'Aging'}</span>
                </div>
                <div className="alarm__row__card__container-top-card-content">
                  {calculateAging(rowData?.alarmAge)}
                </div>
              </div>
            </div>
            <div className="alarm__row__card__container-bottom">
              {bottomCard?.map((columnKey, index) => {
                const headerName = columnKey === 'connectorNumber' ? 'Connector No.' :
                  columnKey === 'connectorType' ? 'Connector Type' :
                    columnKey === 'location' ? 'Location' :
                      columns?.find(
                        (column) => column?.field === columnKey
                      )?.headerName || '';
                const value = rowData[columnKey];
                return (
                  <div key={index} className="alarm__row__card__container-bottom-card">
                    <div className="alarm__row__card__container-bottom-card-header">
                      {headerName}

                    </div>

                    <div className="alarm__row__card__container-bottom-card-content">
                      {value}
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
      <NotesComponent />

      <div className='alarm__panel-detail-wrapper'>
        <div className='alarm__panel-detail-navbar'>
          <HorizontalNavbar selectedNav={selectedNav} navList={alarmPanelNavList} onNavClick={handleNavBtnClick} />
        </div>
      </div>
      {selectedNav === 'core' && (<AlarmPanelCoreDashboard contentHeight={280} />)}
      {/* {selectedNav === 'remoteTrigger' && (<AlarmPanelRemoteTriggerDashboard contentHeight={280} />)}
      {selectedNav === 'firmwareManagement' && (<AlarmPanelFirmwareManagementDashboard contentHeight={280} />)}
      {selectedNav === 'reservation' && (<AlarmPanelReservationDashboard contentHeight={280} />)}
      {selectedNav === 'localAuthListManagement' && (<AlarmPanelLocalAuthListManagement contentHeight={280} />)}
      {selectedNav === 'smartCharging' && (<AlarmPanelSmartChargingDashboard contentHeight={280} />)} */}

    </div>

  );
});

export default AlarmPanelDashboard;