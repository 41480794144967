import { cloneDeep } from 'lodash';
import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import CustomForm, { ICustomFormProps, IFormContentProp } from '../../../globalUtils/CustomForm/CustomForm';
import {
  DashboardLoader,
  useAppDispatch,
  useAppSelector,
  useEffectOnce,
} from '../../../globalUtils/globalExports';
import { getReferenceDataReducer, fetchCustomerReferenceData, updateCharger, createCharger, fetchChargerDetail, getChargerReducer, chargerTypes } from '../../../rmsReduxStore/reduxExports';
import { IBreadCrumb, createBreadCrumbProps, removeAllFromReferenceData } from '../../../globalUtils/globalHooks';
import { Id, toast } from 'react-toastify';
import { useLocation, useNavigate } from 'react-router-dom';
import { AxiosResponse } from 'axios';
import { BreadCrumbObject } from '../../../globalUtils/CustomBreadCrumbs/CustomBreadCrumbs';
interface IChargerForm {
  macId: string;
  model: string;
  customer: string;
  visibleId: string;
  latitude: string;
  longitude: string;
  remarks: string;
}

const AddCharger: FC = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const { customerReferenceData } = useAppSelector(getReferenceDataReducer);
  const { chargerDetail } = useAppSelector(getChargerReducer)
  const initialChargerFormData: IChargerForm = {
    macId: '',
    model: '',
    customer: location?.state?.id ?? '',
    visibleId: '',
    latitude: '',
    longitude: '',
    remarks: ''
  };
  const initialStateData = {
    macIdValue: '',
    chargerModelValue: null,
    customerValue: location?.state?.name ? { name: location?.state?.name, id: location?.state?.id, value: location?.state?.name, label: location?.state?.name } : null,
    chargerSerialNumber: '',
    latitude: '',
    longitude: '',
    remarks: '',
  }

  const [addChargerFormData, setAddChargerFormData] = useState(initialChargerFormData);
  const [addChargerStateData, setAddChargerStateData] = useState(initialStateData);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [mode, setMode] = useState(location?.state?.mode ?? 'Add')
  const [showLoader, setShowLoader] = useState(false);


  useEffectOnce(() => {
    if (location?.state?.rowData) {
      dispatch(fetchChargerDetail({ chargerId: location?.state?.rowData?.id }))
    }
    dispatch(fetchCustomerReferenceData());
  });

  useEffect(() => {
    loadInitialData()
  }, [chargerDetail])

  const loadInitialData = () => {
    if (chargerDetail) {
      setAddChargerFormData({
        ...addChargerFormData,
        macId: chargerDetail?.macId,
        customer: chargerDetail?.customerId,
        visibleId: chargerDetail?.visibleId,
        latitude: chargerDetail?.latitude,
        longitude: chargerDetail?.longitude,
        remarks: chargerDetail?.remarks
      })
      setAddChargerStateData({
        ...addChargerStateData,
        macIdValue: chargerDetail?.macId,
        chargerSerialNumber: chargerDetail?.visibleId,
        latitude: chargerDetail?.latitude,
        longitude: chargerDetail?.longitude,
        remarks: chargerDetail?.remarks,
        customerValue: {
          id: chargerDetail?.customerId,
          value: chargerDetail?.customerName,
          label: chargerDetail?.customerName,
          name: chargerDetail?.customerName
        }
      })
    }
  }

  const handleFormDataChange = useCallback((val, formKey, stateKey) => {
    const stateData = cloneDeep(addChargerStateData);
    const formData = cloneDeep(addChargerFormData);
    if (val?.id) {
      formData[formKey] = val?.id;
    } else {
      formData[formKey] = val;
    }

    stateData[stateKey] = val;
    setAddChargerStateData(stateData);
    setAddChargerFormData(formData);
  }, [addChargerStateData, addChargerFormData]);

  const disableAddButton = useCallback(() => {
    return (!(addChargerFormData?.macId?.length > 0 && addChargerFormData?.visibleId?.length > 0 && addChargerFormData?.latitude?.length > 0 && addChargerFormData?.longitude?.length > 0 && addChargerFormData?.customer?.length > 0))
  }, [addChargerFormData])

  const chargerAddedToast = (): Id => toast.success(`Charger ${mode || 'add'}ed.`);
  const duplicateChargerToast = (): Id => toast.warn('Charger with same ID exists.');
  const somethingWentWrongToast = (): Id => toast.warn('Something went wrong.');

  const btnsList = useMemo(() => {
    return [
      {
        buttonText: mode === 'Edit' ? 'Update' : 'Add',
        buttonId: 'add',
        btnClassName: disableAddButton() ? 'primary__btn disabled' : 'primary__btn',
        handleClick: (): void => {
          handleSubmit?.(mode)
        },
        isDisabled: disableAddButton(),
        buttonVariant: 'filled',
      },
      {
        buttonText: 'Cancel',
        buttonId: 'cancel',
        btnClassName: 'secondary__btn',
        handleClick: (): void => {
          if (location?.state?.from)
            navigate(location?.state?.from, { state: {...location?.state, mode:location?.state?.customerMode}})
          else
            navigate('/manage-chargers');
        },
        isDisabled: false,//disableCancelButton(),
        buttonVariant: 'outlined',
      },
    ];
  }, [addChargerFormData, disableAddButton, mode, addChargerStateData]);

  const handleSubmit = useCallback(async (mode) => {
    // Validate latitude here
    const latitudeValue = parseFloat(addChargerStateData.latitude);
    if (isNaN(latitudeValue) || latitudeValue < -90|| latitudeValue > 90) {
      toast.error('Latitude should be in range of [-90,90]');
      return;
    }
    // Validate longitude here
    const longitudeValue = parseFloat(addChargerStateData.longitude);
    if (isNaN(longitudeValue) || longitudeValue < -180 || longitudeValue > 180) {
      toast.error('Longitude should be in range of [-180,180]');
      return;
    }
    setShowLoader(true)
    
    let response: AxiosResponse
    if (mode === 'Edit') {
      response = await dispatch(updateCharger({ ...addChargerFormData, chargerId: location?.state?.rowData?.id }))
    } else {
      response = await dispatch(createCharger(addChargerFormData));
    }
    if (response?.status === 200 || response?.status === 202) {
      setShowLoader(false)
      chargerAddedToast();
      setTimeout(() => {
        if (location?.state?.from) navigate(location?.state?.from, { state: {...location?.state, mode:location?.state?.customerMode} })
        else navigate('/manage-chargers');
      }, 1000)
    }
    else if (response?.data === 'Charger with same Id exists') {
      setShowLoader(false)
      duplicateChargerToast();
    } else {
      setShowLoader(false)
      somethingWentWrongToast();
    }
  }, [addChargerStateData, addChargerFormData])

  const keyPressValidationLatLong = (event): void => {
    const { key, target } = event;
    
    // Allow dot (.) for decimal values
    if (key === '.' && target?.value?.includes('.')) event.preventDefault();
    if (key === '-' && target?.selectionStart === 0 && target?.selectionEnd === 0 && !target?.value?.includes('-')) {
      // Allow minus sign (-) only if it's the first character and the input field is empty
      // This condition checks if the cursor is at the beginning of the input field
      // and if the input field does not already contain a minus sign
      return;
    }
    // Check for numeric keys, backspace, and allow CMD+V (for Mac) or CTRL+V (for Windows/Linux)
    if (key === ' ' || (isNaN(Number(key)) && key !== '.' && key !== 'Backspace' && !(event.metaKey || event.ctrlKey && key.toLowerCase() === 'v'))) {
      event.preventDefault();
    }
  };

  function checkValidLatLong(value: string): string {
    // Regular expression to match a decimal number
    return /^[-+]?\d*\.?\d+$/.test(value + '0') ? value : '';
  }

  //TODO: Key Press Validation for MAC Address
  const keyPressValidationForMACAddress = (event): void => {
    const keyPressed = event.key;
    // Allow Ctrl+C (copy) and Ctrl+V (paste) and Backspace
    if (keyPressed === 'Backspace' || ((event.ctrlKey || event.metaKey) && (keyPressed.toLowerCase() === 'c' || keyPressed.toLowerCase() === 'v'))) return;
    // Check Only HEx and allow - and : 
    if (!(/^[0-9a-fA-F\-:]+$/.test(keyPressed))) event.preventDefault();
  }

  function checkValidMAC(value: string): string {
    // Regular expression to validate a MAC address
    return /^[0-9a-fA-F\-:]+$/.test(value) ? value : ''
  }


  const formArray: IFormContentProp[] = [
    {
      rowCountClassName: 'grid-3',
      formObject: [
        {
          formLabel: 'Charger Serial Number',
          isRequired: true,
          objectType: 'input',
          selectDropdownProps: null,
          inputProps: {
            inputPlaceHolder: mode !== 'View' ? 'Enter Serial Number' : '',
            handleInputChange: (event): void => {
              handleFormDataChange(event?.target?.value, 'visibleId', 'chargerSerialNumber');
            },
            inputValue: addChargerStateData?.chargerSerialNumber,
            inputType: 'string',
            inputFieldId: 'adding_charger_serial_name',
            isRequired: false,
            isDisabled: mode !== 'Add',
            autoFocus: true,
          },
          textAreaProps: null,
        },
        {
          formLabel: 'MAC Address',
          isRequired: true,
          objectType: 'input',
          selectDropdownProps: null,
          inputProps: {
            inputPlaceHolder: mode !== 'View' ? 'Enter MAC Address' : '',
            handleInputChange: (event): void => {
              handleFormDataChange(checkValidMAC(event?.target?.value), 'macId', 'macIdValue');
            },
            inputValue: addChargerStateData?.macIdValue,
            inputType: 'string',
            inputFieldId: 'add_charger_mac_address',
            handleKeyPress: keyPressValidationForMACAddress,
            isRequired: false,
            isDisabled: mode !== 'Add'
          },
          textAreaProps: null,
        },
        {
          formLabel: 'Customer',
          isRequired: true,
          objectType: 'dropdown',
          selectDropdownProps: {
            selectValue: addChargerStateData?.customerValue,
            selectOptions: removeAllFromReferenceData(customerReferenceData) as IChargerModelReferenceData[] || [],
            handleSelectOptionChange: (val): void => {
              handleFormDataChange(val, 'customer', 'customerValue');
            },
            selectDropDownId: 'charging-model-dropdown',
            selectDropDownName: 'charging-model-dropdown',
            isDisabled: mode !== 'Add' || location?.state?.name
          },
          inputProps: null,
          textAreaProps: null,
        },
      ],
    },
    {
      rowCountClassName: 'grid-3',
      formObject: [
        {
          formLabel: 'Latitude',
          isRequired: true,
          objectType: 'input',
          selectDropdownProps: null,
          inputProps: {
            inputPlaceHolder: mode !== 'View' ? 'Enter latitude' : '',
            handleInputChange: (event): void => {
              handleFormDataChange(checkValidLatLong(event?.target?.value), 'latitude', 'latitude');
            },
            inputValue: addChargerStateData?.latitude,
            inputFieldId: 'add_charger_latitude',
            isRequired: true,
            handleKeyPress: keyPressValidationLatLong,
            isDisabled: mode === 'View'
          },
          textAreaProps: null,
        },
        {
          formLabel: 'Longitude',
          isRequired: true,
          objectType: 'input',
          selectDropdownProps: null,
          inputProps: {
            inputPlaceHolder: mode !== 'View' ? 'Enter longitude' : '',
            handleInputChange: (event): void => {
              handleFormDataChange(checkValidLatLong(event?.target?.value), 'longitude', 'longitude');
            },
            inputValue: addChargerStateData?.longitude,
            inputFieldId: 'adding_station',
            isRequired: true,
            handleKeyPress: keyPressValidationLatLong,
            isDisabled: mode === 'View'
          },
          textAreaProps: null,
        },
      ],
    },
    {
      rowCountClassName: '',
      formObject: [
        {
          formLabel: 'Notes',
          isRequired: false,
          objectType: 'text-area',
          selectDropdownProps: null,
          inputProps: null,
          textAreaProps: {
            inputPlaceHolder: mode !== 'View' ? 'Type here' : '',
            handleInputChange: (event): void => {
              handleFormDataChange(event?.target?.value, 'remarks', 'remarks');
            },
            isRequired: false,
            inputValue: addChargerStateData?.remarks,
            inputFieldId: 'adding_station',
            isDisabled: mode === 'View'
          },
        },
      ],
    },
  ];

  // TODO: BreadCrumb navigation Props
  const createBreadCrumbsNavigation = useCallback((): BreadCrumbObject[] => {
    let breadCrumbArray: IBreadCrumb[] = [];
    if (location?.state?.from) {
      const customerListNavigation: IBreadCrumb[] = [
        {
          objectType: 'link',
          id: 'customers',
        },
        {
          objectType: 'link',
          id: 'editCustomer',
          linkBtnState: {...location?.state, mode:location?.state?.customerMode},
        }
      ]
      breadCrumbArray = breadCrumbArray?.concat(customerListNavigation)
    }
    else {
      const chargerNavigation: IBreadCrumb[] = [{ objectType: 'link', id: 'chargers' }]
      breadCrumbArray = breadCrumbArray?.concat(chargerNavigation)
    }
    breadCrumbArray.push({
      objectType: 'text',
      id: 'text',
      text: mode != 'Add' ? location?.state?.rowData?.visibleId : 'Add Charger'
    })
    return createBreadCrumbProps({ breadCrumbProps: breadCrumbArray })
  }, [location?.state])

  const customFormProps: ICustomFormProps = useMemo(() => {
    return {
      formContainerClassName: 'add__charger__wrap',
      breadCrumbprops: {
        containerId: 'add-charger-breadcrumb',
        containerClassName: 'add-charger-breadcrumb',
        breadCrumbs: createBreadCrumbsNavigation()
      },
      headerPropTypes: {
        header: mode && mode !== 'Add' ? location?.state?.rowData?.visibleId : 'Add Charger',
        headerClassName: 'add__charger__wrap-header-text',
      },
      formArray: formArray,
      footerBtnProps: {
        buttonsList: mode === 'View' ? btnsList?.filter((button) => button?.buttonId === 'cancel') : btnsList
      },
    };
  }, [formArray, btnsList,mode])

  // TODO: Clear all redux states on unmount
  useEffect(() => {
    return (): void => {
      // Partial<ChargerAction> 
      const chargerAction = {
        type: chargerTypes.CLEAR_CHARGER_DETAIL,
      }
      dispatch(chargerAction);
    }
  }, [])

  return (
    <>
      <DashboardLoader showLoader={showLoader} />
      <CustomForm {...customFormProps} />
    </>
  );
};

export default AddCharger;