import { referenceDataTypes } from '../reduxExports';

const referenceDataInitialState: ReferenceDataState = {
  regionReferenceData: null,
  softwareVersionReferenceData: null,
  chargerStatusReferenceData: null,
  customerReferenceData: null,
  chargerTypeReferenceData: null,
  alarmSeverityReferenceData: null,
  chargerModelReferenceData: null,
  chargingStationReferenceData: null,
  chargerLocationReferenceData: null,
  chargerHistoryTypeReferenceData: null,
  alarmTypeReferenceData: null,
  chargerVisibleIdReferenceData: null,
  userRoleTypeReferenceData: null,
  userUserTypeReferenceData: null,
  parametersReferenceData : null ,
  userReferenceData:null ,
  orgReferenceData:null,
  orgTypeReferenceData:null,
  rolePermissionReferenceData: null
};

const referenceDataReducer = (state: ReferenceDataState = referenceDataInitialState, action: ReferenceDataAction): ReferenceDataState => {
  switch (action.type) {
    case referenceDataTypes.FETCH_CHARGER_STATUS_REFERENCE_DATA:
      return {
        ...state,
        chargerStatusReferenceData: action.chargerStatusReferenceData,
      };
    case referenceDataTypes.FETCH_CHARGER_TYPE_REFERENCE_DATA:
      return {
        ...state,
        chargerTypeReferenceData: action.chargerTypeReferenceData,
      };
    case referenceDataTypes.FETCH_CUSTOMER_REFERENCE_DATA:
      return {
        ...state,
        customerReferenceData: action.customerReferenceData,
      };
    case referenceDataTypes.FETCH_REGION_REFERENCE_DATA:
      return {
        ...state,
        regionReferenceData: action.regionReferenceData,
      };

    case referenceDataTypes.FETCH_SOFTWARE_VERSION_REFERENCE_DATA:
      return {
        ...state,
        softwareVersionReferenceData: action.softwareVersionReferenceData,
      };
    case referenceDataTypes.FETCH_ALARM_SEVERITY_REFERENCE_DATA:
      return {
        ...state,
        alarmSeverityReferenceData: action.alarmSeverityReferenceData,
      };
    case referenceDataTypes.FETCH_CHARGER_MODEL_REFERENCE_DATA:
      return {
        ...state,
        chargerModelReferenceData: action.chargerModelReferenceData,
      };
    case referenceDataTypes.FETCH_CHARGER_STATION_REFERENCE_DATA:
      return {
        ...state,
        chargingStationReferenceData: action.chargingStationReferenceData,
      };
    case referenceDataTypes.FETCH_CHARGER_LOCATION_REFERENCE_DATA:
      return {
        ...state,
        chargerLocationReferenceData: action.chargerLocationReferenceData,
      };
    case referenceDataTypes.FETCH_HISTORY_TYPE_REFERENCE_DATA:
      return {
        ...state,
        chargerHistoryTypeReferenceData: action.chargerHistoryTypeReferenceData,
      };
    case referenceDataTypes.FETCH_ALARM_TYPE_REFERENCE_DATA:
      return {
        ...state,
        alarmTypeReferenceData: action.alarmTypeReferenceData,
      };
    case referenceDataTypes.FETCH_CHARGER_VISIBLE_ID_REFERENCE_DATA:
      return {
        ...state,
        chargerVisibleIdReferenceData: action.chargerVisibleIdReferenceData,
      };
    case referenceDataTypes.FETCH_USER_ROLE_TYPE_REFERENCE_DATA:
      return {
        ...state,
        userRoleTypeReferenceData: action.userRoleTypeReferenceData,
      };
    case referenceDataTypes.FETCH_PARAMETERS_REFERENCE_DATA:
      return {
        ...state,
        parametersReferenceData: action.parametersReferenceData,
      }
    case referenceDataTypes.FETCH_ORG_REFERENCE_DATA:
      return {
        ...state,
        orgReferenceData: action.orgReferenceData,
      };
    case referenceDataTypes.FETCH_ORG_TYPE_REFERENCE_DATA:
      return {
        ...state,
        orgTypeReferenceData: action.orgTypeReferenceData
      };
      case referenceDataTypes.FETCH_USERS_REFERENCE_DATA:
        return {
          ...state,
          userReferenceData: action.userReferenceData,
        };
      case referenceDataTypes.FETCH_ROLE_PERMISSION_DATA:
        return {
          ...state,
          rolePermissionReferenceData: action.rolePermissionReferenceData
        };
    default:
      return state;
  }
};

export default referenceDataReducer;
