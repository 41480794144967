import React, { type FC } from 'react'
import { checkForScreenResolution } from '../globalHooks'
import { IconWithTooltip } from '../globalIcons'
import {
  AddFilterBtnTheme,
  CustomButton,

} from '../globalExports'
import { TableType } from './TableDashboard'


const TableSubHeader: FC<Partial<TableType>> = ({
  headerActionBtns
}) => {
  const smallScreenResolution = checkForScreenResolution?.('sm')
  return smallScreenResolution?.result
    ? (
      <>
        {headerActionBtns != null &&
          headerActionBtns?.length > 0 &&
          headerActionBtns?.filter((actionbtn) => actionbtn?.isAccessible)?.map((actionBtn, index) => {
            if (actionBtn?.buttonId === 'add_filter_btn') {
              return (
                <CustomButton
                  key={actionBtn?.buttonId ?? '' + index}
                  buttonId={actionBtn?.buttonId ?? ''}
                  customTheme={AddFilterBtnTheme}
                  buttonIconClassName={actionBtn?.iconClassName}
                  buttonText={actionBtn?.buttonText ?? ''}
                  btnClassName={actionBtn?.btnClassName}
                  handleClick={(event) => actionBtn?.handleClick?.(event)}
                  buttonVariant={actionBtn?.buttonVariant}
                  handleKeyPress={() => { }}
                />
              )
            }
            return (
              <IconWithTooltip
                key={actionBtn?.buttonId ?? '' + index}
                toolTipText={actionBtn?.toolTipText}
                iconClassName={actionBtn?.iconClassName ?? ''}
                containerClassName={actionBtn?.tooltipContainerClassName}
                onIconClick={(event)=>actionBtn?.handleClick?.(event)}
              />
            )
          })}
      </>
    )
    : (
      <>
        {headerActionBtns != null &&
          headerActionBtns?.length > 0 &&
          headerActionBtns?.filter((actionbtn) => actionbtn?.isAccessible)?.map((actionBtn, index) => {
            return (
              <CustomButton
                key={actionBtn?.buttonId ?? '' + index}
                buttonId={actionBtn?.buttonId ?? ''}
                customTheme={actionBtn?.customTheme}
                buttonIconClassName={actionBtn?.iconClassName}
                buttonText={actionBtn?.buttonText ?? ''}
                btnClassName={actionBtn?.btnClassName}
                handleClick={(event) => actionBtn?.handleClick?.(event)}
                buttonVariant={actionBtn?.buttonVariant}
                handleKeyPress={() => { }}
              />
            )
          })}
      </>
    )
}

export default TableSubHeader
