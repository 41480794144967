import React, { useState, type FC, useCallback, ReactNode, useMemo, useEffect, memo } from 'react';
import {
  CustomHighCharts,
  useAppDispatch,
  useAppSelector,
} from '../../globalUtils/globalExports';
import { getDashboardReducer, setRedirectedStateForAlarmFilters } from '../../rmsReduxStore/reduxExports';
import { Options, Point, TooltipFormatterContextObject } from 'highcharts';
import { renderToStaticMarkup } from 'react-dom/server';
import { useNavigate } from 'react-router-dom';
import { RectangularSkeleton } from '../../globalUtils/SkeletonDesign/SkeletonDesign';
import { Icon, IconWithTooltip } from '../../globalUtils/globalIcons';
import { setRedirectedStateForChargerFilters } from '../../rmsReduxStore/chargersRedux/chargerCreators';
import { formatNumberWithCommas } from '../../globalUtils/globalHooks';

interface ChargerHealthGraphData {
  y: number;
  metaData: IDashboardChargerHealth[];
}

interface CustomPoint extends Point {
  metaData: IDashboardChargerHealth[];
}

const ChargerHealthStatus: FC = memo(function ChargerHealthStatus() {
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const [customerHealthCategories, setCustomerHealthCategories] = useState<string[]>();
  const [customerHealthSeriesData, setCustomerHealthSeriesData] = useState<ChargerHealthGraphData[]>();
  const { chargerHealthData } = useAppSelector(getDashboardReducer);

  const renderData = useCallback(() => {
    const categories: string[] = [];
    if (chargerHealthData && chargerHealthData?.length > 0) {
      const mappedData: ChargerHealthGraphData[] = chargerHealthData?.map(
        (charger) => {
          categories?.push(charger?.status);
          return {
            y: parseInt(formatNumberWithCommas(charger?.count)),
            metaData: charger?.chargers,
          };
        }
      );

      setCustomerHealthCategories(categories);
      setCustomerHealthSeriesData(mappedData);
    }
  }, [chargerHealthData]);

  useEffect(() => {
    renderData();
  }, [chargerHealthData]);

  const options: Options = useMemo(() => {
    return {
      chart: {
        type: 'bar',
        height: 350,
        renderTo: 'container',
        animation: false,
        events: {
					// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
					load: function () {
						this.reflow();
					}
				},
      },
      accessibility: {
        enabled: false
      },
      credits: {
        enabled: false,
      },
      xAxis: {
        title: {
          text: undefined,
        },
        gridLineColor: '#BBBBBB',
        labels: {
          style: {
            fontSize: '16px',
            fontWeight: '600',
            color: '#002126CC',
            fontFamily: 'Inter',
            textTransform: 'capitalize',
            textAlign: 'left',
          },
        },
        categories: customerHealthCategories,

      },
      yAxis: {
        title: {
          text: undefined,
        },
        labels: {
          enabled: false,
        },
        lineWidth: 0,
        gridLineWidth: 0,
      },
      tooltip: {
        enabled: true,
        useHTML: true,
        stickOnContact: true,
        backgroundColor: 'transparent',
        shadow: false,
        outside: true,
        className: 'dashboard__tooltip',
        formatter: function (this: TooltipFormatterContextObject): string {
          const pointData = this.point as CustomPoint;
          return renderToStaticMarkup(
            <GraphToolTip data={this} pointData={pointData} />
          );
        },
      },

      plotOptions: {
        bar: {
          colorByPoint: true,
          dataLabels: {
            enabled: true,
            style: {
              fontSize: '14.5px',
              fontWeight: '600',
              color: '#002126CC',
              fontFamily: 'Inter',
              textTransform: 'capitalize',
              textAlign: 'left',
            },
          },
        },
      },
      title: {
        text: undefined,
      },
      legend: {
        enabled: false,
      },
      colors: ['var(--green-color)', 'var(--grey-color)', 'var(--red-color)','var(--cms-connectivity-fail-color)'],
      series: [
        {
          type: 'bar',
          data: customerHealthSeriesData,
          name: 'Chargers',
          cursor: 'pointer',
          className: 'custom__series',
          point: {
            events: {
              // eslint-disable-next-line @typescript-eslint/no-unused-vars
              click: function (event): void {
                const filterId = this.category === 'faulty' ? 'Faulty' : this.category === 'healthy' ? 'Healthy' : this.category === 'offline' ? 'Offline' : this.category === 'CMS Offline' ? 'CMS Comm Fail' : null;
                if (filterId) {
                  this.category === 'CMS Offline' ? setRedirectedStateForAlarmFilters(true,null,filterId)(dispatch).then((res) => {
                    if (res === 'Action dispatched successfully') {
                      navigate('/alarms', {
                        state: { alarmType: filterId }
                      });
                    }
                  }) :
                  setRedirectedStateForChargerFilters(true, filterId)(dispatch)
                    .then((res) => {
                      if (res === 'Action dispatched successfully') {
                        navigate('/manage-chargers', {
                          state: { chargerStatusId: filterId }
                        });
                      }
                  })
                  .catch((error) => {
                    console.error('Error dispatching action:', error); // Handle error
                  });
        
                }
              }
            }
          }
        },
      ],
    };
  }, [customerHealthSeriesData])

  const healthStatusTooltip = 
  <div className="tooltip_container">
    <ul>
      <li>Healthy chargers have no critical alarms, and have a live connection to RMS and CMS.</li>
      <li>Offline chargers have stopped sending live data to RMS.</li>
      <li>Faulty chargers have critical alarms.</li>
    </ul>
  </div>
  return (
    <div className="charger__health__status__container">
      <div className="charger__health__status-header-wrap">
        <div className="charger__health__status-header">
          Charger Health
        </div>
        <IconWithTooltip iconClassName='rms_info_icon' toolTipText={<div>{healthStatusTooltip}</div>} containerClassName='charger__health__status-header-info' />
      </div>
      <div className="charger__health__status-graph-wrap">
        {!chargerHealthData || customerHealthCategories === undefined   || customerHealthSeriesData === null ?
          <RectangularSkeleton skeletonContainerClassName='charger__health__status-graph-skeleton' /> :
          <CustomHighCharts highChartOptions={options} />
        }
      </div>
    </div>
  );
});

interface TooltipInterface {
  data: TooltipFormatterContextObject;
  pointData: CustomPoint;
}

const GraphToolTip = ({ data, pointData }: TooltipInterface): ReactNode => {
  const headerText =
    data?.x === 'healthy'
      ? 'Healthy'
      : data?.x === 'offline'
        ? 'Offline'
        : data?.x === 'faulty'
          ? 'Faulty'
          : data?.x;
  const chargerData = pointData?.metaData;
  const yValue = data?.y || 0

  // TODO: Fix for -> because of renderToStatic Markup
  // useLayoutEffect does nothing on the server, because its effect cannot be encoded into the server renderer's output format. This will lead to a mismatch between the initial, non-hydrated UI and the intended UI. To avoid this, useLayoutEffect should only be used in components that render exclusively on the client
  useEffect(() => {
    if (typeof document === 'undefined') {
      React.useLayoutEffect = React.useEffect;
    }
  }, [])

  return (
    <div className="graph__tooltip__container charger__health__status__tooltip">
      <div className="graph__tooltip__header">{headerText}</div>
      <div className="graph__tooltip__total__count">
        Total: {formatNumberWithCommas(data?.y)}
        { yValue > 10 && <div className="graph__tooltip__total__count_right">{'( Top 10 )'}</div>}
      </div>
      <div className="graph__tooltip__total___content">

        {chargerData &&
          chargerData?.length > 0 &&
          chargerData?.map((charger, index) => {
            return (
              <div key={index} className="graph__tooltip__total___content-row">
                <div className="graph__tooltip__total___content-left">
                  <div className="graph__tooltip__total___content-icon">
                    <Icon containerClassName="graph__tooltip__customer__health__status__icon-wrapper"
                      iconClassName='graph__tooltip__customer__health__status__icon' />
                  </div>
                  <div className="graph__tooltip__total___content-charger__text">
                    {charger?.visibleId}
                  </div>
                </div>

                <div className="graph__tooltip__total___content-customer__text">
                  {charger?.customerName}
                </div>
              </div>
            );
          })}
      </div>
    </div>
  );
};
export default ChargerHealthStatus;
