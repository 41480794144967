import AppBar from '@mui/material/AppBar';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import { ThemeProvider } from '@mui/material/styles';
import React, { FC, useCallback, useEffect, useState } from 'react';
import authService from '../../auth/service/AuthService';
import {
  NavbarTheme,
  useScreenSize,
} from '../../globalUtils/globalExports';
import localStorageInstance from '../../rmsReduxStore/api-service/LocalStorageService';
import { SidebarPropTypes } from '../AppLayout';
import { Arrow } from '../../globalUtils/globalIcons';
import { persistor } from '../../rmsReduxStore/rmsStore';
export interface IUser {
  email_address: string;
  id: string;
  mobile_number: string;
  name: string;
  role_id: string;
}

const Navbar: FC<SidebarPropTypes> = (props) => {
  const { expandSideBar, handleSidebarToggle } = props;
  const screenSize = useScreenSize?.();
  const [anchorEl, setAnchorEl] = useState(null);
  const [userDetails, setUserDetails] = useState<IUser | null>(null);

  const handleClose = (): void => {
    setAnchorEl(null);
  };

  const handleMenu = (event): void => {
    setAnchorEl(event.currentTarget);
  };

  const handleSideBar = useCallback(() => {
    handleSidebarToggle?.(!expandSideBar);
  }, [expandSideBar, handleSidebarToggle]);

  useEffect(() => {
    const user = localStorageInstance.fetchUser();
    setUserDetails(user);
  }, []);

  const handleUserLogout = async (): Promise<void> => {
    const user = localStorageInstance?.fetchUser();
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const res = await authService.handleLogout({
      userId: user?.id,
      sessionId: localStorage?.getItem('sessionId'),
    });
     // if (res?.status === 202) {
      // navigate('/');
      window.location.reload();
      // Object.keys(localStorage).forEach(itemKey => {
      //   window.localStorage.removeItem(itemKey);
      // });
      localStorage.clear();
      persistor.purge(); 
      history.pushState(null, '', location.href);
      window.onpopstate = (): void => {
        history.go(1);
      }
    // }
    handleClose();
  };

  return (
    <ThemeProvider theme={NavbarTheme}>
      <AppBar
        style={{ background: 'var(--navbar-color)', height: '48px' }}
        position="fixed"
        component={'div'}
      >
        <Toolbar disableGutters={true} variant="dense">
          <div className="app__navbar__left">
            <IconButton
              size="small"
              edge="start"
              color="inherit"
              aria-label="menu"
              style={{ marginRight: 12 }}
              onClick={() => handleSideBar()}
            >
              <div
                className={
                  screenSize?.width <= 800 && expandSideBar
                    ? 'navbar__close__icon rms__icon'
                    : 'navbar__expand__collapse__icon rms__icon'
                }
              ></div>
            </IconButton>

            <IconButton
              size="small"
              edge="start"
              color="inherit"
              aria-label="menu"
              style={{ marginRight: 12,cursor: 'default' }}
              // onClick={() => handleSideBar()}
            >
              <div className="rms__icon navbar__logo__icon"></div>
            </IconButton>
          </div>

          <div className="app__navbar__right">
            <IconButton
              size="small"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              color="inherit"
            >
              <div className="navbar__settings__icon rms__icon"></div>
            </IconButton>
            <div className="app__navbar__profile" onClick={handleMenu}>
              <IconButton
                size="small"
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                color="inherit"
              >
                <div className="navbar__profile__icon rms__icon"></div>
              </IconButton>
              <Typography
                variant="h6"
                component="div"
                sx={{
                  fontSize: '13px',
                  marginTop: '1px',
                  fontWeight: 300,
                  fontFamily: 'var(--font-family)',
                  marginRight: '5px',
                }}
                className="navbar__profile__name"
              >
                {userDetails && userDetails?.name}
              </Typography>
              <div className="navbar__profile__arrows__wrap">
                <IconButton
                  size="small"
                  edge="start"
                  color="inherit"
                  aria-label="menu"
                >
                  {anchorEl ? (
                    <Arrow color={'white'} direction={'up'} />
                  ) : (
                    <Arrow color={'white'} direction={'down'} />
                  )}
                </IconButton>
              </div>
            </div>
            <Menu
              id="menu-appbar"
              anchorEl={anchorEl}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              sx={{ top: '30px' }}
              open={Boolean(anchorEl)}
              onClose={handleClose}
              classes={{ list: 'profile__list' }}
            >
              <MenuItem onClick={handleClose}>
                <IconButton
                  size="small"
                  edge="start"
                  color="inherit"
                  aria-label="menu"
                  sx={{ mr: 2 }}
                >
                  <div className="rms__icon navbar__avatar__icon"></div>
                </IconButton>
                <Typography
                  variant="h6"
                  component="div"
                  sx={{ fontSize: '14px' }}
                >
                  My Profile
                </Typography>
              </MenuItem>
              <MenuItem onClick={handleUserLogout}>
                <IconButton
                  size="small"
                  edge="start"
                  color="inherit"
                  aria-label="menu"
                  sx={{ mr: 2 }}
                >
                  <div className="rms__icon navbar__logout__icon"></div>
                </IconButton>
                <Typography
                  variant="h6"
                  component="div"
                  sx={{ fontSize: '14px' }}
                  onClick={() => { }}
                >
                  Logout
                </Typography>
              </MenuItem>
            </Menu>
          </div>
        </Toolbar>
      </AppBar>
    </ThemeProvider>
  );
};
export default Navbar;
