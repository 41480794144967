/* eslint-disable @typescript-eslint/strict-boolean-expressions */
/* eslint-disable no-unneeded-ternary */
import { Button, ButtonGroup, ThemeProvider } from '@mui/material';
import PropTypes from 'prop-types';
import { KeyboardEvent, type FC } from 'react';
import {
  FilledBtnTheme,
  OutlinedBtnTheme,
  ButtonTheme
} from '../globalExports';
import React  from 'react';

export const CustomButtonType = {
  buttonText: PropTypes.string.isRequired,
  buttonId: PropTypes.string.isRequired,
  btnClassName: PropTypes.string,
  handleClick: PropTypes.func as PropTypes.Validator<
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  (event: React.MouseEvent<HTMLButtonElement>) => void
    >,// eslint-disable-next-line @typescript-eslint/no-unused-vars
  handleKeyPress:  PropTypes.func as PropTypes.Validator<(event: KeyboardEvent<HTMLButtonElement>) => void>,
  isDisabled: PropTypes.bool,
  buttonIconClassName: PropTypes.string,
  buttonVariant: PropTypes.string, // 'filled' or 'outlined'
  customTheme: PropTypes.any
};

export type CustomButtonPropTypes = PropTypes.InferProps<typeof CustomButtonType>

export const CustomButtonGroupType = {
  buttonsList: PropTypes.arrayOf(
    PropTypes.shape({
      buttonText: PropTypes.string.isRequired,
      buttonId: PropTypes.string.isRequired,
      btnClassName: PropTypes.string,
      handleClick: PropTypes.func as PropTypes.Validator<
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      ((event: React.MouseEvent<HTMLButtonElement> | KeyboardEvent<HTMLButtonElement>)  => void) | undefined
      >,
      isDisabled: PropTypes.bool,
      buttonIconClassName: PropTypes.string,
      buttonVariant: PropTypes.string, // 'filled' or 'outlined'
      customTheme: PropTypes.any,
     
    })
  ),
  buttonGroupClassName: PropTypes.string
};

export type CustomButtonGroupPropTypes = PropTypes.InferProps<
  typeof CustomButtonGroupType
>

export const CustomButton: FC<CustomButtonPropTypes> = (props) => {
  const {
    buttonText,
    btnClassName,
    handleClick,
    buttonId,
    isDisabled,
    buttonVariant,
    customTheme,
    buttonIconClassName,
    handleKeyPress
  } = props;

  
  return (
    <ThemeProvider
      theme={
        customTheme
          ? customTheme
          : buttonVariant === 'filled'
            ? FilledBtnTheme
            : buttonVariant === 'outlined'
              ? OutlinedBtnTheme
              : ButtonTheme
      }
    >
      <Button
        className={btnClassName ?? undefined}
        onClick={(event) => { event?.stopPropagation(); handleClick?.(event); }}
        id={buttonId}
        variant={
          customTheme ||
          buttonVariant === 'filled' ||
          buttonVariant === 'outlined'
            ? 'outlined'
            : 'contained'
        }
        disabled={isDisabled ?? undefined}
        sx={{
          backgroundColor: customTheme
            ? ''
            : buttonVariant === 'filled'
              ? 'var(--secondary-blue-color)'
              : 'var(--primary-white-color)',
          color: customTheme
            ? ''
            : buttonVariant === 'filled'
              ? 'var(--primary-white-color)'
              : 'var(--secondary-blue-color)'
        }}
        startIcon={
          buttonIconClassName && (
            <div className={'rms__icon ' + buttonIconClassName}></div>
          )
        }
        onKeyPress={(event: KeyboardEvent<HTMLButtonElement>) => {
          if(event?.key ==='Enter' || event?.keyCode === 13)
          // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
          (handleKeyPress ?? (() => {}))(event);
        }}
        
      >
        {buttonText}
      </Button>
    </ThemeProvider>
  );
};

export const CustomButtonGroup: FC<CustomButtonGroupPropTypes> = (props) => {
  const { buttonsList, buttonGroupClassName } = props;

  return (
    <ButtonGroup
      variant='outlined'
      className={buttonGroupClassName ?? ''}
      aria-label='outlined button-group'
    >
      {buttonsList &&
        buttonsList?.length > 0 &&
        buttonsList?.map((btn, index) => {
          return (
            <CustomButton
              key={index} // Adding a unique key for each button in the array
              buttonText={btn?.buttonText ?? ''}
              btnClassName={btn?.btnClassName}
              handleClick={(event) => btn?.handleClick?.(event)}
              buttonId={btn?.buttonId ?? ''}
              isDisabled={btn?.isDisabled}
              buttonVariant={btn?.buttonVariant}
              customTheme={btn?.customTheme}
              buttonIconClassName={btn?.buttonIconClassName}
              // eslint-disable-next-line @typescript-eslint/no-unused-vars
              handleKeyPress={(event) => () => {
                
              }}
            />
          );
        })}
    </ButtonGroup>
  );
};

const RmsCustomButtonComponents = {
  CustomButton,
  CustomButtonGroup
};

export default RmsCustomButtonComponents;
