/* eslint-disable @typescript-eslint/no-explicit-any */
import { cloneDeep } from 'lodash';
import { filterTypes } from '../reduxExports';
import rmsStore from '../rmsStore';
import { alarmInitialFilterValues, chargerInitialFilterValues, usersInitialFilterValues } from './filterReducer';

export const setFilterData = (data, filterKey: string, screenName: string, action: string, isGlobal: boolean, excludeGlobalFilters?: boolean) => async (dispatch): Promise<FilterUpdateResolve> => {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve, reject) => {
        try {
            const globalFilters: InnerFilterState[] = cloneDeep(rmsStore.getState().filterReducer.globalFilters);
            const screenFilters: ScreenFilterState[] = cloneDeep(rmsStore.getState().filterReducer.screenFilters);

            if (isGlobal) {
                const selectedGlobalFilter = globalFilters?.find((filter) => filter?.key === filterKey);
                if (selectedGlobalFilter) {
                    selectedGlobalFilter.value = Array.isArray(data) ? data : [data]
                }
                const action: Partial<FilterDataAction> = {
                    type: filterTypes.SET_GLOBAL_FILTERS_DATA,
                    globalFilters: globalFilters,
                };
                await dispatch(action);
            }

            if (action === 'SET' && !isGlobal) {
                const selectedScreenFilters = screenFilters?.find((filter) => filter?.screen === screenName);
                const selectedScreenSelectedFilter = selectedScreenFilters?.filters?.find((filter => filter?.key === filterKey));
                if (selectedScreenSelectedFilter) {
                    selectedScreenSelectedFilter.value = Array.isArray(data) ? data : [data]
                }
                const action: Partial<FilterDataAction> = {
                    type: filterTypes.SET_SCREEN_FILTERS_DATA,
                    screenFilters: screenFilters,
                };
                await dispatch(action);
            }

            /* CLEAR ACTION WILL COME ONLY WHEN ALARMS OR CHARGERS DASHBOARD ARE UNMOUNTED
                AND FILTERS WERE APPLIED FROM REDIRECTION */
            if (action === 'CLEAR') {
                const selectedScreenFilters = screenFilters?.find((filter) => filter?.screen === screenName);
                if (selectedScreenFilters) {
                    selectedScreenFilters.filters = screenName == 'chargers' ? chargerInitialFilterValues : (screenName === 'users' ? usersInitialFilterValues : alarmInitialFilterValues)
                }
                const action: Partial<FilterDataAction> = {
                    type: filterTypes.SET_SCREEN_FILTERS_DATA,
                    screenFilters: screenFilters,
                };
                await dispatch(action);
                // globalFilters = globalFilters?.map((filter) => {
                //     return {
                //     key:filter.key,
                //     value: null
                //     }
                // })
                // const globalAction: Partial<FilterDataAction> = {
                //     type: filterTypes.SET_GLOBAL_FILTERS_DATA,
                //     globalFilters:globalFilters,
                // };
                // await dispatch(globalAction);
            }
            const globalFiltersToBeSent: Filters[] = globalFilters?.map((item) => {
                return {
                    key: item.key,
                    values: item.value?.map((item) => item.id) || []
                }
            })
            const screenFiltersToBeSent: Filters[] = screenFilters?.find((filter) => filter.screen === screenName)?.filters.map((item) => {
                if (item.key === 'alarmClearedTimeStamp' || item.key === 'alarmSetTimeStamp' || item.key === 'setInterval' || item.key === 'updateInterval' || item.key === 'chargingHistoryInterval' || item.key === 'chargerLogStatusInterval' || item.key === 'chargerLogSettingInterval' || item.key === 'chargerLogSystemLogInterval') {                    
                    return {
                        key: item.key,
                        values: item.value?.map(item => item && new Date(item)?.toISOString()) || []
                    }
                }
                return {
                    key: item.key,
                    values: item.value?.map((item) => item.id) || []
                }
            }) || []
            const filtersToBeSent = excludeGlobalFilters ? [...screenFiltersToBeSent] : [...globalFiltersToBeSent, ...screenFiltersToBeSent];
            resolve({
                message: 'Action dispatched successfully',
                filters: filtersToBeSent
            });
        } catch (error) {
            // Reject the promise with the error
            reject(error);
        }
    });
};

export const getFilterSelectedValue: any = (filterKey: string, screenName: string, isGlobal): null | IChargerHistoryTypeRefData[] | ISoftwareVersionReferenceData[] | IChargerStatusReferenceData[] | ICustomerReferenceData[] | IChargerTypeReferenceData[] | IAlarmSeverityReferenceData[] | IChargerModelReferenceData[] | IChargingStationReferenceData[] | IChargerLocationRefData[] | string[] | IParametersReferenceData[] | IUsersReferenceData[] => {
    const globalFilters: InnerFilterState[] = cloneDeep(rmsStore.getState().filterReducer.globalFilters);
    const screenFilters: ScreenFilterState[] = cloneDeep(rmsStore.getState().filterReducer.screenFilters);

    if (isGlobal) {
        return globalFilters?.find((filter) => filter?.key === filterKey)?.value || null;
    }
    const selectedScreenFilters = screenFilters?.find((filter) => filter?.screen === screenName);
    return selectedScreenFilters?.filters?.find((filter => filter?.key === filterKey))?.value || null
}


export const getCurrentFiltersValues: any = (screenName: string, excludeGlobalFilters?: boolean): Filters[] => {
    const globalFilters: InnerFilterState[] = cloneDeep(rmsStore.getState().filterReducer.globalFilters);
    const screenFilters: ScreenFilterState[] = cloneDeep(rmsStore.getState().filterReducer.screenFilters);
    const globalFiltersToBeSent: Filters[] = globalFilters?.map((item) => {
        return {
            key: item.key,
            values: item.value?.map((item) => item.id) || []
        }
    })
    const screenFiltersToBeSent: Filters[] = screenFilters?.find((filter) => filter.screen === screenName)?.filters.map((item) => {
        if (item.key === 'alarmClearedTimeStamp' || item.key === 'alarmSetTimeStamp' || item.key === 'setInterval' || item.key === 'updateInterval' || item.key === 'chargingHistoryInterval' || item.key === 'chargerLogStatusInterval' || item.key === 'chargerLogSettingInterval' || item.key === 'chargerLogSystemLogInterval') {
            return {
                key: item.key,
                values: item.value?.map(item => item && new Date(item)?.toISOString()) || []
            }
        }
        return {
            key: item.key,
            values: item.value?.map((item) => item.id) || []
        }
    }) || []

    const filtersToBeSent = excludeGlobalFilters ? [...screenFiltersToBeSent] : [...globalFiltersToBeSent, ...screenFiltersToBeSent]
    return filtersToBeSent
}
