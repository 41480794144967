import type React from 'react'
import { CustomButton, GreyBtnWithIconTheme } from '../../../globalUtils/globalExports'
import { type FC } from 'react'

interface AlarmPanelSubNav {
  buttonText: string
  buttonIconClassName: string
  buttonId: string

}
interface AlarmPanelSubNavList {
  dashboardNavRef: React.Ref<HTMLDivElement>
  navList: AlarmPanelSubNav[]
  // eslint-disable-next-line @typescript-eslint/ban-types
  handleNavBtnClick: Function
  selectedNav: string
}

const AlarmPanelSubNavListHeader: FC<AlarmPanelSubNavList> = ({ dashboardNavRef, selectedNav, navList, handleNavBtnClick }) => {
  return (
    <div ref={dashboardNavRef} className="alarm__panel__sub_tab_dashboard-header">
      {navList?.length > 0 &&
        navList?.map((navItem, index) => {
          return (
            <div
              key={'navbtn' + index}
              className={
                selectedNav === navItem?.buttonId
                  ? 'alarm__panel__sub_tab_dashboard__nav__btn-wrapper selected'
                  : 'alarm__panel__sub_tab_dashboard__nav__btn-wrapper'
              }
            >
              <CustomButton
                customTheme={GreyBtnWithIconTheme}
                btnClassName="alarm__panel__sub_tab_dashboard__nav__btn"
                buttonText={navItem?.buttonText}
                buttonIconClassName={navItem?.buttonIconClassName}
                buttonId={navItem?.buttonId}
                handleClick={(e) => handleNavBtnClick?.(e, navItem?.buttonId)}
                handleKeyPress={() => { }}
              />
            </div>
          )
        })}
    </div>
  )
}

export default AlarmPanelSubNavListHeader
