/* eslint-disable @typescript-eslint/no-explicit-any */
import { globalTypes } from '../reduxExports';

export const setGlobalFilterData:any = (data) => async (dispatch: (arg0: Partial<GlobalDataAction>) => void): Promise<void> => {
  const action: Partial<GlobalDataAction> = {
    type: globalTypes.SET_GLOBAL_FILTER_DATA,
    globalFiltersWithOnlyIds:data ,
  };
  dispatch(action);
};

export const setGlobalFilterStateData:any = (data) => async (dispatch: (arg0: Partial<GlobalDataAction>) => void): Promise<void> => {
  const action: Partial<GlobalDataAction> = {
    type: globalTypes.SET_GLOBAL_FILTER_STATE_DATA,
    globalFiltersWithObjects: data ,
  };
  dispatch(action);
};

export const setExpandedToggleIds:any = (data) => async (dispatch: (arg0: Partial<GlobalDataAction>) => void): Promise<void> => {
  const action: Partial<GlobalDataAction> = {
    type: globalTypes.SET_TOGGLE_IDS,
    toggleExpandedIds: data ,
  };
  dispatch(action);
};