import React, { memo, useMemo, useState, ChangeEvent } from 'react';
import RadioButton from '../../../globalUtils/RadioButton/RadioButton';
import CustomInput from '../../../globalUtils/CustomInput/CustomInput';
import { FormLabel } from '@mui/material'
import { CustomButtonGroup } from '../../../globalUtils/globalExports';
import { idTagTypeRadioButtons } from '../alarmConstants';

interface FormField {
  label: string
  placeholder: string
  handleChange: (event) => void
  value: string
  //   inputType: 'text' | 'select' | 'date' | 'multiselect'
  //   selectOptions?: any[]
}

interface RadioButtonsType {
  radioButtonId: string
  radioButtonLabel: string
}

interface AlarmPanelTabProps {
  // contentHeight: number
  description: React.ReactNode
  radioButtonsList: RadioButtonsType[]
  formFields: FormField[]
  onPerformBtnClick: () => void
  onCancelBtnClick: () => void
  isTagTypeEnabled: boolean
}

const AlarmPanelTabs = memo(
  function AlarmPanelTabs({
    // contentHeight,
    description,
    radioButtonsList,
    formFields,
    onPerformBtnClick,
    onCancelBtnClick,
    isTagTypeEnabled
  }: AlarmPanelTabProps) {

    const btnsList = useMemo(() => {
      return [
        {
          buttonText: 'Perform',
          buttonId: 'perform',
          btnClassName: 'primary__btn',
          handleClick: onPerformBtnClick,
          isDisabled: false,
          buttonVariant: 'filled'
        },
        {
          buttonText: 'Cancel',
          buttonId: 'cancel',
          btnClassName: 'secondary__btn',
          handleClick: onCancelBtnClick,
          isDisabled: false,
          buttonVariant: 'outlined'
        }
      ]
    }, [onCancelBtnClick, onPerformBtnClick])

    const [selectedConnectorValue, setSelectedConnectorValue] = useState<string>('connector1');
    const handleConnectorChange = (
      event: ChangeEvent<HTMLInputElement>,
      value: string
    ): void => {
      setSelectedConnectorValue(value)
    }

    //   const renderInput = (field: FormField) => {
    //     switch (field.inputType) {
    //       case 'select':
    //         return (
    //           <SelectDropDown
    //             selectValue={field.value}
    //             selectOptions={field.selectOptions || []}
    //             handleSelectOptionChange={field.handleChange}
    //             selectDropDownId={`select_${field.label}`}
    //             dropDownClassName={`select_${field.label}`}
    //             isMultiSelect={false}
    //           />
    //         );
    //       case 'date':
    //         return (
    //           <CustomDatePicker
    //             selectsRange={field.value}
    //             startDate={}
    //             endDate={}
    //             onChange={field.handleChange}
    //             datePickerId={`date_${field.label}`}
    //           />
    //         );
    //       case 'multiselect':
    //         return (
    //           <SelectDropDown
    //             selectValue={field.value}
    //             selectOptions={field.selectOptions || []}
    //             handleSelectOptionChange={field.handleChange}
    //             selectDropDownId={`multiselect_${field.label}`}
    //             dropDownClassName={`multiselect_${field.label}`}
    //             isMultiSelect={true}
    //           />
    //         );
    //       default:
    //         return (
    //           <CustomInput
    //             className={'alarm__firmware_management-input'}
    //             inputPlaceHolder={field.placeholder}
    //             handleInputChange={field.handleChange}
    //             inputValue={field.value}
    //             inputFieldId={`adding_station_${field.label}`}
    //             isRequired={false}
    //           />
    //         );
    //     }
    //   };

    return (
      <div className="alarm__panel__firmware_management__dashboard">
        <div className='alarm__firmware_management__dashboard-ratdio-buttons-description'>
          <p className='alarm__firmware_management__dashboard-ratdio-buttons-description-text'>{description}</p>
        </div>
        <div className="alarm__firmware_management__dashboard-radio-buttons-wrap">
          <RadioButton
            buttonsList={radioButtonsList}
            handleSelectedRadioBtnChange={handleConnectorChange}
            selectedRadioBtnValue={selectedConnectorValue}
            radionBtnGrpName={'charger-remote-commands-dashboard-radio-buttons'}
          />
        </div>
        {isTagTypeEnabled && (
          <div className='alarm__local_auth_list_management_radio-text'>
            <p style={{ marginRight: '10px' }}>Choose Id Tag Type</p>
            <RadioButton
              buttonsList={idTagTypeRadioButtons}
              radionBtnGrpName={'alarm__local_auth_list_management-dashboard-radio-buttons'}
            />
          </div>
        )}

        <div className="alarm__firmware_management_dashboard-content-wrapper" style={{ display: 'flex' }}>
          {formFields.map((field, index) => (
            <div key={index} className="alarm__firmware_management_dashboard-content">
              <FormLabel className={'rms-form-label'} required>
                {field.label}
              </FormLabel>
              {/* {renderInput(field)} */}
              <CustomInput
                className={'alarm__firmware_management-input'}
                inputPlaceHolder={field.placeholder}
                handleInputChange={field.handleChange}
                inputValue={field.value}
                inputFieldId={'adding_station'}
                isRequired={false}
              />
            </div>
          ))}
        </div>
        <div className="alarm__firmware_management_dashboard-footer-wrap">
          <CustomButtonGroup
            buttonsList={btnsList}
            buttonGroupClassName={'button__group__footer'}
          />
        </div>
      </div>
    )
  }
)

export default AlarmPanelTabs
