/* eslint-disable @typescript-eslint/no-explicit-any */
import cachedAPI from '../api-service/CachedAPIs';
import { referenceDataTypes } from '../reduxExports';
import hwaxios from '../api-service/HWAxios';

const url = process.env.REACT_APP_SERVER_BASE_URL

export const fetchRegionReferenceData: any =
  () =>
    async (
      dispatch: (arg0: Partial<ReferenceDataAction>) => void
    ): Promise<void> => {
      try {
        cachedAPI.fetchCacheFunction('/ref/region', 30 * 60 * 60, 'region-data').then((response) => {
          const reduxReponse = response?.data?.data?.map((data) => {
            return {
              id: data?.id,
              value: data?.name,
              label: data?.name,
              name: data?.name,
            };
          });

          const action: Partial<ReferenceDataAction> = {
            type: referenceDataTypes.FETCH_REGION_REFERENCE_DATA,
            regionReferenceData: reduxReponse,
          };
          dispatch(action);
        });
      } catch (error) {
        console.log(error);
        throw error;
      }
    };

export const fetchSoftwarVersionReferenceData: any =
  () =>
    async (
      dispatch: (arg0: Partial<ReferenceDataAction>) => void
    ): Promise<void> => {
      try {
        cachedAPI.fetchCacheFunction('/ref/software-version', 30 * 60 * 60, 'software-version-data').then((response) => {
          const reduxReponse = response?.data?.data?.map((data) => {
            return {
              id: data,
              value: data,
              label: data,
              name: data,
            };
          });

          const action: Partial<ReferenceDataAction> = {
            type: referenceDataTypes.FETCH_SOFTWARE_VERSION_REFERENCE_DATA,
            softwareVersionReferenceData: reduxReponse,
          };
          dispatch(action);
        });
      } catch (error) {
        console.log(error);
        throw error;
      }
    };

export const fetchChargerTypeReferenceData: any =
  () =>
    async (
      dispatch: (arg0: Partial<ReferenceDataAction>) => void
    ): Promise<void> => {

      try {
        cachedAPI.fetchCacheFunction('/ref/charger-type', 30 * 60 * 60, 'charger-type-data').then((response) => {
          const reduxReponse = response?.data?.data?.map((data) => {
            return {
              id: data?.id,
              value: data?.name,
              label: data?.name,
              name: data?.name,
            };
          });

          const action: Partial<ReferenceDataAction> = {
            type: referenceDataTypes.FETCH_CHARGER_TYPE_REFERENCE_DATA,
            chargerTypeReferenceData: reduxReponse,
          };
          dispatch(action);
        })


      } catch (error) {
        console.log(error);

        throw error;
      }
    };

export const fetchCustomerReferenceData: any =
  () =>
    async (
      dispatch: (arg0: Partial<ReferenceDataAction>) => void
    ): Promise<any> => {

      try {
        const response = hwaxios.get(url + '/ref/customer').then((response) => {
          const reduxReponse = response?.data?.data?.map((data) => {
            return {
              id: data?.customerId,
              value: data?.customerId,
              label: data?.customerName,
              name: data?.customerName,
            };
          });

          const action: Partial<ReferenceDataAction> = {
            type: referenceDataTypes.FETCH_CUSTOMER_REFERENCE_DATA,
            customerReferenceData: reduxReponse,
          };
          dispatch(action);
          
          return JSON.stringify(reduxReponse);
       
        })
      return response
      } catch (error) {
        console.log(error);

        throw error;
      }
    };

export const fetchChargerStatusReferenceData: any = () => async (dispatch: (arg0: Partial<ReferenceDataAction>) => void): Promise<string> => {
  try {
    const response = cachedAPI.fetchCacheFunction('/ref/charger-status', 30 * 60 * 60, 'charger-status-data').then((response) => {
      const reduxReponse = response?.data?.data?.map((data) => {
        return {
          id: data?.id,
          value: data?.id,
          label: data?.name,
          name: data?.name,
        };
      });

      const action: Partial<ReferenceDataAction> = {
        type: referenceDataTypes.FETCH_CHARGER_STATUS_REFERENCE_DATA,
        chargerStatusReferenceData: reduxReponse,
      };
      dispatch(action);
      return JSON.stringify(reduxReponse);
    })

    return response
  } catch (error) {
    console.log(error);
    throw error;
  }
};

export const fetchAlarmSeverityReferenceData: any =
  () =>
    async (
      dispatch: (arg0: Partial<ReferenceDataAction>) => void
    ): Promise<void> => {
      try {
        cachedAPI.fetchCacheFunction('/ref/alarm-severity', 30 * 60 * 60, ' alarm-severity-data').then((response) => {
          const reduxReponse = response?.data?.data?.map((data) => {
            return {
              id: data?.id,
              value: data?.id,
              label: data?.name,
              name: data?.name,
            };
          });

          const action: Partial<ReferenceDataAction> = {
            type: referenceDataTypes.FETCH_ALARM_SEVERITY_REFERENCE_DATA,
            alarmSeverityReferenceData: reduxReponse,
          };
          dispatch(action);
        });
      } catch (error) {
        console.log(error);

        throw error;
      }
    };

export const fetchChargerModelReferenceData: any =
  () =>
    async (
      dispatch: (arg0: Partial<ReferenceDataAction>) => void
    ): Promise<void> => {
      try {
        cachedAPI.fetchCacheFunction('/ref/charger-model', 30 * 60 * 60, 'charger-model-data').then((response) => {
          const reduxReponse = response?.data?.data?.map((data) => {
            return {
              id: data,
              value: data,
              label: data,
              name: data,
            };
          });

          const action: Partial<ReferenceDataAction> = {
            type: referenceDataTypes.FETCH_CHARGER_MODEL_REFERENCE_DATA,
            chargerModelReferenceData: reduxReponse,
          };
          dispatch(action);
        });
      } catch (error) {
        console.log(error);

        throw error;
      }
    };

export const fetchChargerStationReferenceData: any = () =>
  async (
    dispatch: (arg0: Partial<ReferenceDataAction>) => void
  ): Promise<void> => {
    try {
      cachedAPI.fetchCacheFunction('/ref/charging-station', 30 * 60 * 60, 'charging-station-data').then((response) => {
        const reduxReponse = response?.data?.data?.map((data) => {
          return {
            id: data?.id,
            value: data?.id,
            label: data?.name,
            name: data?.name,
          };
        });

        const action: Partial<ReferenceDataAction> = {
          type: referenceDataTypes.FETCH_CHARGER_STATION_REFERENCE_DATA,
          chargingStationReferenceData: reduxReponse,
        };
        dispatch(action);
      })
    } catch (error) {
      console.log(error);
      throw error;
    }
  };

export const fetchChargerLocationReferenceData: any = () => async (dispatch: (arg0: Partial<ReferenceDataAction>) => void): Promise<void> => {
  try {
    cachedAPI.fetchCacheFunction('/ref/location', 30 * 60 * 60, 'charging-location-data').then((response) => {
      const reduxReponse = response?.data?.data?.map((data) => {
        return {
          id: data,
          value: data,
          label: data,
          name: data,
        };
      });

      const action: Partial<ReferenceDataAction> = {
        type: referenceDataTypes.FETCH_CHARGER_LOCATION_REFERENCE_DATA,
        chargerLocationReferenceData: reduxReponse,
      };
      dispatch(action);
    })
  } catch (error) {
    console.log(error);
    throw error;
  }
};

export const fetchChargerHistoryTypeReferenceData: any = () => async (dispatch: (arg0: Partial<ReferenceDataAction>) => void): Promise<void> => {
  try {
    const response = await hwaxios.get(url + '/ref/history-type')

    let reduxReponse = response?.data?.data?.data?.historyTypes?.map((data) => {
      return {
        id: data?.id,
        value: data?.name,
        label: data?.name,
        name: data?.name,
        defaultOption: data?.defaultOption,
        statType: data?.statType?.map(statData => ({
          id: statData?.id,
          value: statData?.name,
          label: statData?.name,
          name: statData?.name,
          defaultOption: statData?.defaultOption,
        })),
        statInterval: data?.statInterval?.map(statData => ({
          id: statData?.id,
          value: statData?.name,
          label: statData?.name,
          name: statData?.name,
          defaultOption: statData?.defaultOption,
        })),
        historyInterval: data?.historyInterval?.map(statData => ({
          id: statData?.id,
          value: statData?.name,
          label: statData?.name,
          name: statData?.name,
          defaultOption: statData?.defaultOption,
        })),
      };
    });
    reduxReponse = reduxReponse?.length > 0 && reduxReponse?.sort((a, b) => (a.label).localeCompare(b.label))
    const action: Partial<ReferenceDataAction> = {
      type: referenceDataTypes.FETCH_HISTORY_TYPE_REFERENCE_DATA,
      chargerHistoryTypeReferenceData: reduxReponse,
    };
    dispatch(action);
    return reduxReponse
  } catch (error) {
    console.log(error);
    throw error;
  }
};

export const fetchAlarmTypeReferenceData: any =
  () =>
    async (
      dispatch: (arg0: Partial<ReferenceDataAction>) => void
    ): Promise<void> => {
      try {
        cachedAPI.fetchCacheFunction('/ref/alarm-type', 30 * 60 * 60, ' alarm-type-data').then((response) => {
          const reduxReponse = response?.data?.data?.map((data) => {
            return {
              id: data?.id,
              value: data?.id,
              label: data?.name,
              name: data?.name,
            };
          });
          const action: Partial<ReferenceDataAction> = {
            type: referenceDataTypes.FETCH_ALARM_TYPE_REFERENCE_DATA,
            alarmTypeReferenceData: reduxReponse,
          };
          dispatch(action);
        });
      } catch (error) {
        console.log(error);

        throw error;
      }
    };

export const fetchChargerVisibleIdReferenceData: any =
  () =>
    async (
      dispatch: (arg0: Partial<ReferenceDataAction>) => void
    ): Promise<void> => {
      try {
        hwaxios.get(url + '/ref/charger-visible-id').then((response) => {
          const reduxReponse = response?.data?.data?.map((data) => {
            return {
              id: data?.id,
              value: data?.id,
              label: data?.visibleId,
              name: data?.visibleId,
            };
          });
          const action: Partial<ReferenceDataAction> = {
            type: referenceDataTypes.FETCH_CHARGER_VISIBLE_ID_REFERENCE_DATA,
            chargerVisibleIdReferenceData: reduxReponse,
          };
          dispatch(action);
        });
      } catch (error) {
        console.log(error);
        throw error;
      }
    };


export const fetchUserRoleTypeReferenceData: any =
  (reqParams) =>
    async (
      dispatch: (arg0: Partial<ReferenceDataAction>) => void
    ): Promise<void> => {
      try {
        cachedAPI.fetchCacheFunction(`/ref/role?orgId=${reqParams?.orgId}`, 30 * 60 * 60, `user-roleType-data-${reqParams?.orgId}`).then((response) => {
          const reduxReponse = response?.data?.data?.map((item) => {
            return {
              id: item?.id,
              value: item?.id,
              label: item?.name,
              name: item?.name,
            };
          });

          const action: Partial<ReferenceDataAction> = {
            type: referenceDataTypes.FETCH_USER_ROLE_TYPE_REFERENCE_DATA,
            userRoleTypeReferenceData: reduxReponse,
          };
          dispatch(action);
        });
      } catch (error) {
        console.log(error);
        throw error;
      }
    };


export const fetchOrgReferenceData: any =
  () =>
    async (
      dispatch: (arg0: Partial<ReferenceDataAction>) => void
    ): Promise<void> => {
      try {
        hwaxios.get(url + '/ref/organization').then((response) => {
          const reduxReponse = response?.data?.data?.map((item) => {
            return {
              id: item?.id,
              value: item?.id,
              label: item?.name,
              name: item?.name,
            };
          });

          const action: Partial<ReferenceDataAction> = {
            type: referenceDataTypes.FETCH_ORG_REFERENCE_DATA,
            orgReferenceData: reduxReponse,
          };
          dispatch(action);
        });
      } catch (error) {
        console.log(error);
        throw error;
      }
    };

export const fetchParametersReferenceData: any =
  () =>
    async (
      dispatch: (arg0: Partial<ReferenceDataAction>) => void
    ): Promise<void> => {

      try {
        cachedAPI.fetchCacheFunction('/ref/params-master', 30 * 60 * 60, 'parameters-data').then((response) => {
          const reduxReponse = response?.data?.data?.map((data) => {
            return {
              id: data?.id,
              value: data?.id,
              label: data?.userName,
              name: data?.userName,
            };
          });

          const action: Partial<ReferenceDataAction> = {
            type: referenceDataTypes.FETCH_PARAMETERS_REFERENCE_DATA,
            parametersReferenceData: reduxReponse,
          };
          dispatch(action);
        })

      } catch (error) {
        console.log(error);

        throw error;
      }
    };

export const fetchUsersReferenceData: any =
  () =>
    async (
      dispatch: (arg0: Partial<ReferenceDataAction>) => void
    ): Promise<void> => {

      try {
        hwaxios.get(url + '/ref/users').then((response) => {
          const reduxReponse = response?.data?.data?.map((data) => {
            return {
              id: data?.id,
              value: data?.id,
              label: data?.userName,
              name: data?.userName,
            };
          });

          const action: Partial<ReferenceDataAction> = {
            type: referenceDataTypes.FETCH_USERS_REFERENCE_DATA,
            userReferenceData: reduxReponse,
          };
          dispatch(action);
        })

      } catch (error) {
        console.log(error);

        throw error;
      }
    };

export const fetchOrgTypeReferenceData: any =
  () =>
    async (
      dispatch: (arg0: Partial<ReferenceDataAction>) => void
    ): Promise<void> => {
      try {
        cachedAPI.fetchCacheFunction('/ref/org-types', 30 * 60 * 60, 'org-type-data').then((response) => {
          const reduxReponse = response?.data?.data?.map((item) => {
            return {
              id: item,
              value: item,
              label: item,
              name: item,
            };
          });
          const action: Partial<ReferenceDataAction> = {
            type: referenceDataTypes.FETCH_ORG_TYPE_REFERENCE_DATA,
            orgTypeReferenceData: reduxReponse,
          };
          dispatch(action);
        });
      } catch (error) {
        console.log(error);
        throw error;
      }
    };

export const fetchRolePermissionReferenceData: any = 
  () => 
  async (
    dispatch: (arg0: Partial<ReferenceDataAction>) => void
  ): Promise<void> => {
    try {
      cachedAPI.fetchCacheFunction('/role/permission/master', 30 * 60 * 60, 'role-permission-data').then((response) => {
        const reduxReponse = response?.data?.data;
        const action: Partial<ReferenceDataAction> = {
          type: referenceDataTypes.FETCH_ROLE_PERMISSION_DATA,
          rolePermissionReferenceData: reduxReponse
        }
        dispatch(action);
      });
    }
    catch (error) {
      console.log(error);
      throw error;
    }
  }