export const FETCH_CUSTOMER_REFERENCE_DATA = 'FETCH_CUSTOMER_REFERENCE_DATA';
export const FETCH_CHARGER_TYPE_REFERENCE_DATA = 'FETCH_CHARGER_TYPE_REFERENCE_DATA';
export const FETCH_REGION_REFERENCE_DATA = 'FETCH_REGION_REFERENCE_DATA';
export const FETCH_SOFTWARE_VERSION_REFERENCE_DATA = 'FETCH_SOFTWARE_VERSION_REFERENCE_DATA';
export const FETCH_CHARGER_STATUS_REFERENCE_DATA = 'FETCH_CHARGER_STATUS_REFERENCE_DATA';
export const FETCH_ALARM_SEVERITY_REFERENCE_DATA = 'FETCH_ALARM_SEVERITY_REFERENCE_DATA';
export const FETCH_CHARGER_MODEL_REFERENCE_DATA = 'FETCH_CHARGER_MODEL_REFERENCE_DATA';
export const FETCH_CHARGER_STATION_REFERENCE_DATA = 'FETCH_CHARGER_STATION_REFERENCE_DATA';
export const FETCH_HISTORY_TYPE_REFERENCE_DATA = 'FETCH_HISTORY_TYPE_REFERENCE_DATA';
export const FETCH_CHARGER_LOCATION_REFERENCE_DATA = 'FETCH_CHARGER_LOCATION_REFERENCE_DATA';
export const FETCH_ALARM_TYPE_REFERENCE_DATA = 'FETCH_ALARM_TYPE_REFERENCE_DATA'
export const FETCH_CHARGER_VISIBLE_ID_REFERENCE_DATA = 'FETCH_CHARGER_VISIBLE_ID_REFERENCE_DATA'
export const FETCH_USER_ROLE_TYPE_REFERENCE_DATA='FETCH_USER_ROLE_TYPE_REFERENCE_DATA'
export const FETCH_USER_USER_TYPE_REFERENCE_DATA='FETCH_USER_USER_TYPE_REFERENCE_DATA'
export const FETCH_PARAMETERS_REFERENCE_DATA = 'FETCH_PARAMETERS_REFERENCE_DATA';
export const FETCH_USERS_REFERENCE_DATA = 'FETCH_USERS_REFERENCE_DATA ';
export const FETCH_ORG_REFERENCE_DATA = 'FETCH_ORG_REFERENCE_DATA'
export const FETCH_ORG_TYPE_REFERENCE_DATA = 'FETCH_ORG_TYPE_REFERENCE_DATA'
export const FETCH_ROLE_PERMISSION_DATA = 'FETCH_ROLE_PERMISSION_DATA '