import {Skeleton} from '@mui/material';
import React, {FC} from 'react';
import ChargerIcon from '../../rmsAssets/charger.svg';
import './SkeletonDesign.css';

interface RectangularSkeletonProps {
  skeletonContainerClassName: string;
}

export const RectangularSkeleton: FC<RectangularSkeletonProps> = ({skeletonContainerClassName}) => {
  return (
    <div className={'rectangular__skeleton__wrapper ' + skeletonContainerClassName}>
      <Skeleton
        className='rectangular__skeleton'
        sx={{
          bgcolor: 'var(--skeleton-bg-color)',
          '&::after': {
            bgcolor: 'var(--skeleton-bg-color)',
          },
        }}
        animation='wave'
        variant='rectangular'
        width={'100%'}
        height={'100%'}
      />
    </div>
  );
};

export const CircularSkeleton: FC<RectangularSkeletonProps> = ({skeletonContainerClassName}) => {
  return (
    <div
      className={'circular__skeleton__wrapper ' + skeletonContainerClassName}
    >
      <Skeleton
        className='circular__skeleton'
        sx={{
          bgcolor: 'var(--skeleton-bg-color)',
          '&::after': {
            bgcolor: 'var(--skeleton-bg-color)',
          },
        }}
        animation='wave'
        variant='circular'
        width={'30px'}
        height={'30px'}
      />
    </div>
  );
};

export const DashboardHeaderSkeleton: FC = () => {
  return (
    <>
      <RectangularSkeleton skeletonContainerClassName='dashboard__header__skeleton'/>
      <RectangularSkeleton skeletonContainerClassName='dashboard__header__skeleton'/>
      <RectangularSkeleton skeletonContainerClassName='dashboard__header__skeleton'/>
      <RectangularSkeleton skeletonContainerClassName='dashboard__header__skeleton'/>
      <RectangularSkeleton skeletonContainerClassName='dashboard__header__skeleton'/>
    </>
  );
};

export const AlarmCardSkeleton: FC = () => {
  return (
    <div className='alarm__card__skeleton__wrap'>
      <RectangularSkeleton skeletonContainerClassName='alarm__card__skeleton'/>
      <RectangularSkeleton skeletonContainerClassName='alarm__card__skeleton'/>
      <RectangularSkeleton skeletonContainerClassName='alarm__card__skeleton'/>
      <RectangularSkeleton skeletonContainerClassName='alarm__card__skeleton'/>
    </div>
  );
};

export const AlarmCardTableSkeleton: FC = () => {
  return (
    <>
      <RectangularSkeleton skeletonContainerClassName='alarm__card__skeleton'/>
      <RectangularSkeleton skeletonContainerClassName='alarm__card__skeleton'/>
      <RectangularSkeleton skeletonContainerClassName='alarm__card__skeleton'/>
      <RectangularSkeleton skeletonContainerClassName='alarm__card__skeleton'/>
    </>
  );
};

export const AlarmTableSkeleton: FC = () => {
  return (
    <div className='alarm__table__skeleton__container'>
      <div className='alarm__table__skeleton__header'>
        <RectangularSkeleton skeletonContainerClassName='alarm__table__header'/>
        <RectangularSkeleton skeletonContainerClassName='alarm__table__header'/>
        <RectangularSkeleton skeletonContainerClassName='alarm__table__header'/>
      </div>

      <div className='alarm__table__skeleton__content'>
        <div className='alarm__table__skeleton__row'>
          <RectangularSkeleton skeletonContainerClassName='alarm__table__cell-circle'/>
          <RectangularSkeleton skeletonContainerClassName='alarm__table__cell'/>
          <RectangularSkeleton skeletonContainerClassName='alarm__table__cell'/>
          <RectangularSkeleton skeletonContainerClassName='alarm__table__cell'/>
          <RectangularSkeleton skeletonContainerClassName='alarm__table__cell-circle'/>
        </div>

        <div className='alarm__table__skeleton__row'>
          <RectangularSkeleton skeletonContainerClassName='alarm__table__cell-circle'/>
          <RectangularSkeleton skeletonContainerClassName='alarm__table__cell'/>
          <RectangularSkeleton skeletonContainerClassName='alarm__table__cell'/>
          <RectangularSkeleton skeletonContainerClassName='alarm__table__cell'/>
          <RectangularSkeleton skeletonContainerClassName='alarm__table__cell-circle'/>
        </div>
        <div className='alarm__table__skeleton__row'>
          <RectangularSkeleton skeletonContainerClassName='alarm__table__cell-circle'/>
          <RectangularSkeleton skeletonContainerClassName='alarm__table__cell'/>
          <RectangularSkeleton skeletonContainerClassName='alarm__table__cell'/>
          <RectangularSkeleton skeletonContainerClassName='alarm__table__cell'/>
          <RectangularSkeleton skeletonContainerClassName='alarm__table__cell-circle'/>
        </div>
        <div className='alarm__table__skeleton__row'>
          <RectangularSkeleton skeletonContainerClassName='alarm__table__cell-circle'/>
          <RectangularSkeleton skeletonContainerClassName='alarm__table__cell'/>
          <RectangularSkeleton skeletonContainerClassName='alarm__table__cell'/>
          <RectangularSkeleton skeletonContainerClassName='alarm__table__cell'/>
          <RectangularSkeleton skeletonContainerClassName='alarm__table__cell-circle'/>
        </div>
      </div>
    </div>
  );
};

export const ChargerPanelHeaderSkeleton: FC = () => {
  return (
    <div className='charger__panel__skeleton__header__wrap'>
      <RectangularSkeleton skeletonContainerClassName='charger__panel__skeleton__header'/>
    </div>
  )
}


export const ChargerPanelHeaderCardSkeleton: FC = () => {
  return (
    <div className='charger__panel__header__wrap__skeleton__card'>
      <RectangularSkeleton skeletonContainerClassName='charger__panel__header__card'/>
      <RectangularSkeleton skeletonContainerClassName='charger__panel__header__card'/>
      <RectangularSkeleton skeletonContainerClassName='charger__panel__header__card'/>
      <RectangularSkeleton skeletonContainerClassName='charger__panel__header__card'/>
      <RectangularSkeleton skeletonContainerClassName='charger__panel__header__card'/>
    </div>
  )
}

export const ChargerStatusSkeleton: FC = () => {
  return (
    <div className='charger__status__skeleton'>
      <div className='charger__status__skeleton-top'>
        <div className='charger__status__connector'
             style={{backgroundColor: 'var(--charger-status-charging-dark-color)'}}>
          <div className='charger__status__connector-top'>
            <RectangularSkeleton skeletonContainerClassName='charger__status__skeleton'/>
          </div>
          <div className='charger__status__connector-bottom'>
            <RectangularSkeleton skeletonContainerClassName='charger__status__skeleton'/>
          </div>
        </div>
        <div className='charger__status__charger__wrap-skeleton'>
          <div className='charger__status__charger__connectivity'>
            4G
          </div>
          <div className='charger__status__charger__icon__wrap'>
            <img className='charger__icon' src={ChargerIcon}/>
          </div>
        </div>


        <div className='charger__status__connector' style={{backgroundColor: 'var(--charger-status-idle-dark-color)'}}>
          <div className='charger__status__connector-top'>
            <RectangularSkeleton skeletonContainerClassName='charger__status__skeleton'/>
          </div>
          <div className='charger__status__connector-bottom'>
            <RectangularSkeleton skeletonContainerClassName='charger__status__skeleton'/>
          </div>
        </div>

      </div>

      <div className='charger__status__skeleton-bottom'>
        <div className='charger__status__skeleton-table charging'>

          <div className='charger__status__skeleton-row'>
            <RectangularSkeleton skeletonContainerClassName='charger__status__skeleton-cell'/>

          </div>
          <div className='charger__status__skeleton-row'>
            <RectangularSkeleton skeletonContainerClassName='charger__status__skeleton-cell'/>

          </div>
          <div className='charger__status__skeleton-row'>
            <RectangularSkeleton skeletonContainerClassName='charger__status__skeleton-cell'/>

          </div>
          <div className='charger__status__skeleton-row'>
            <RectangularSkeleton skeletonContainerClassName='charger__status__skeleton-cell'/>

          </div>
          <div className='charger__status__skeleton-row'>
            <RectangularSkeleton skeletonContainerClassName='charger__status__skeleton-cell'/>

          </div>
          <div className='charger__status__skeleton-row'>
            <RectangularSkeleton skeletonContainerClassName='charger__status__skeleton-cell'/>

          </div>
          <div className='charger__status__skeleton-row'>
            <RectangularSkeleton skeletonContainerClassName='charger__status__skeleton-cell'/>

          </div>
        </div>

        <div className='charger__status__skeleton-table idle'>

          <div className='charger__status__skeleton-row'>
            <RectangularSkeleton skeletonContainerClassName='charger__status__skeleton-cell'/>

          </div>
          <div className='charger__status__skeleton-row'>
            <RectangularSkeleton skeletonContainerClassName='charger__status__skeleton-cell'/>

          </div>
          <div className='charger__status__skeleton-row'>
            <RectangularSkeleton skeletonContainerClassName='charger__status__skeleton-cell'/>

          </div>
          <div className='charger__status__skeleton-row'>
            <RectangularSkeleton skeletonContainerClassName='charger__status__skeleton-cell'/>

          </div>
          <div className='charger__status__skeleton-row'>
            <RectangularSkeleton skeletonContainerClassName='charger__status__skeleton-cell'/>

          </div>
          <div className='charger__status__skeleton-row'>
            <RectangularSkeleton skeletonContainerClassName='charger__status__skeleton-cell'/>

          </div>
          <div className='charger__status__skeleton-row'>
            <RectangularSkeleton skeletonContainerClassName='charger__status__skeleton-cell'/>

          </div>
        </div>
      </div>


    </div>
  )
}

export const ChargerHistoryGraphSkeleton: FC = () => {
  return (
    <div className='charger__history__graph__skeleton'>
      <div className='charger__history__graph__skeleton-header'>
        <RectangularSkeleton skeletonContainerClassName='charger__status__skeleton-cell'/>
      </div>

      <div className='charger__history__graph__skeleton-graph'>
        <RectangularSkeleton skeletonContainerClassName='charger__status__skeleton-cell'/>
      </div>
    </div>
  )

}

export const ChargerHistoryLogsSkeleton: FC = () => {
  return (
    <div className='charger__history__logs__skeleton__wrap'>
      <div className='charger__history__logs__skeleton'>
        <div className='charger__history__logs__connector'>
          <RectangularSkeleton skeletonContainerClassName='charger__status__skeleton-cell'/>
        </div>

        <div className='charger__history__logs__connector-graph'>
          <RectangularSkeleton skeletonContainerClassName='charger__status__skeleton-cell'/>

        </div>
      </div>
      <div className='charger__history__logs__skeleton'>
        <div className='charger__history__logs__connector'>
          <RectangularSkeleton skeletonContainerClassName='charger__status__skeleton-cell'/>
        </div>

        <div className='charger__history__logs__connector-graph'>
          <RectangularSkeleton skeletonContainerClassName='charger__status__skeleton-cell'/>
        </div>
      </div>
    </div>
  )
}

export const ChargerDetailSubHeaderSkeleton: FC = () => {
  return (
    <div className='charger__sub__header__details__skeleton__wrap'>
      <RectangularSkeleton skeletonContainerClassName='charger__sub__header__details-card'/>
      <RectangularSkeleton skeletonContainerClassName='charger__sub__header__details-card'/>
      <RectangularSkeleton skeletonContainerClassName='charger__sub__header__details-card'/>
    </div>
  )
}

export const SubHeaderSkeleton: FC = () => {
  return (
    <div className='charger__sub__header__details__skeleton__wrap_full'>
      <RectangularSkeleton skeletonContainerClassName='charger__sub__header__details-card'/>
    </div>
  )
}

export const ChargerStatusParametersSkeleton: FC = () => {
  return (
    <div className='charger__status__parameters__skeleton__wrap'>
      <RectangularSkeleton skeletonContainerClassName='charger__status__parameters__skeleton'/>
      <RectangularSkeleton skeletonContainerClassName='charger__status__parameters__skeleton'/>
      <RectangularSkeleton skeletonContainerClassName='charger__status__parameters__skeleton'/>
      <RectangularSkeleton skeletonContainerClassName='charger__status__parameters__skeleton'/>
      <RectangularSkeleton skeletonContainerClassName='charger__status__parameters__skeleton'/>
    </div>
  )
}


const SkeletonComponents = {
  RectangularSkeleton,
  DashboardHeaderSkeleton,
  AlarmCardSkeleton,
  AlarmTableSkeleton,
  CircularSkeleton,
  ChargerPanelHeaderSkeleton,
  ChargerPanelHeaderCardSkeleton,
  ChargerStatusSkeleton,
  ChargerHistoryGraphSkeleton,
  ChargerHistoryLogsSkeleton,
  ChargerDetailSubHeaderSkeleton,
  ChargerStatusParametersSkeleton,
  AlarmCardTableSkeleton,
  SubHeaderSkeleton
};

export default SkeletonComponents;
